@import '../../../../styles/constants.scss';

.reports-section {
  background-color: $sectionBackground;
  border-radius: $borderRadius;
  box-shadow: $sectionBoxShadow;

  > * {
    padding: 15px 24px;
  }

  &__header {
    justify-content: space-between;
  }
  &__actions {
    justify-content: flex-start;
    font-weight: bolder;
  }

  .reports-headers {
    display: flex;
    align-items: center;
    font-size: $fontSize21;
    border-bottom: 1px solid $colorFlowBorders;
    color: $textColor;
    .round-rectangle-button__content {
      display: flex;
      align-items: center;
    }
  }
}

.action-button {
  display: flex;
  align-items: center;
  &--disabled {
    opacity: 0.5;
  }
  svg {
    margin-right: 10px;
  }
}

.delete-report {
  &-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $textColor;
    font-size: $fontSize21;
    width: 100%;
  }

  &-modal {
    .modal__content {
      max-width: 520px;
    }
  }
}

.reports-edit-section {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 80px;

  &__column {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;

    :nth-child(3).general-label {
      margin-top: 15px;
    }
  }
}

.borders-top-bottom {
  border-bottom: 1px solid $colorFlowBorders;
  border-top: 1px solid $colorFlowBorders;
}

.dark-text {
  color: $textColor;
}
