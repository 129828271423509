@import '../../styles/constants';

.suggestions {
  position: relative;

  &--hide {
    visibility: hidden;
  }

  input, &__list {
    width: 100%;
    box-sizing: border-box;
  }

  &__list {
    top: 100%;
    background: $white;
    border: 1px solid $colorBorder;
    max-height: 330px;
    overflow: auto;
    width: auto;
  }

  &__el {
    cursor: pointer;
    padding: 20px;
    &:focus {
      background: $sectionBackground;
    }

    &:hover {
      background: $sectionBackground;
    }
  }
}
