// Nunito Extra Light (200)
@font-face {
  font-family: 'Nunito';
  src: url(../assets/fonts/Nunito/Nunito-ExtraLight.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito';
  src: url(../assets/fonts/Nunito/Nunito-ExtraLightItalic.ttf)
    format('truetype');
  font-weight: 200;
  font-style: italic;
}

// Nunito Light (300)
@font-face {
  font-family: 'Nunito';
  src: url(../assets/fonts/Nunito/Nunito-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito';
  src: url(../assets/fonts/Nunito/Nunito-LightItalic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

// Nunito Regular (400)
@font-face {
  font-family: 'Nunito';
  src: url(../assets/fonts/Nunito/Nunito-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito';
  src: url(../assets/fonts/Nunito/Nunito-Italic.ttf) format('truetype');
  font-weight: normal;
  font-style: italic;
}

// Nunito Medium (500)
@font-face {
  font-family: 'Nunito';
  src: url(../assets/fonts/Nunito/Nunito-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito';
  src: url(../assets/fonts/Nunito/Nunito-MediumItalic.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}

// Nunito SemiBold (600)
@font-face {
  font-family: 'Nunito';
  src: url(../assets/fonts/Nunito/Nunito-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito';
  src: url(../assets/fonts/Nunito/Nunito-SemiBoldItalic.ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
}

// Nunito Bold (700)
@font-face {
  font-family: 'Nunito';
  src: url(../assets/fonts/Nunito/Nunito-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito';
  src: url(../assets/fonts/Nunito/Nunito-BoldItalic.ttf) format('truetype');
  font-weight: bold;
  font-style: italic;
}

// Nunito Extra Bold (800)
@font-face {
  font-family: 'Nunito';
  src: url(../assets/fonts/Nunito/Nunito-ExtraBold.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito';
  src: url(../assets/fonts/Nunito/Nunito-ExtraBoldItalic.ttf) format('truetype');
  font-weight: 800;
  font-style: italic;
}

// Nunito Black (900)
@font-face {
  font-family: 'Nunito';
  src: url(../assets/fonts/Nunito/Nunito-Black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito';
  src: url(../assets/fonts/Nunito/Nunito-BlackItalic.ttf) format('truetype');
  font-weight: 900;
  font-style: italic;
}

// Sriracha Regular
@font-face {
  font-family: 'Sriracha';
  src: url(../assets/fonts/Sriracha/Sriracha-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

// USAGE
body {
  font-family: 'Open Sans', sans-serif;
}
