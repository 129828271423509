@import '../../styles/constants';

$modalWidth: 520px;
$modalHeight: 332px;

.edit-lock-session-ending {
  width: $modalWidth;
  position: fixed;
  top: calc(50% - #{$modalHeight} / 2);
  left: calc(50% - #{$modalWidth} / 2);
  background-color: $white;
  padding: 33px 32px 20px;
  z-index: $zIndexOverHighestOverlay;
  display: flex;
  flex-direction: column;
  border-radius: $borderRadius;
  box-shadow: $sectionBoxShadow;

  &__close {
    position: absolute;
    cursor: pointer;
    top: 15px;
    right: 15px;
    width: 14px;
    height: 14px;

    &-wrapper {
      position: relative;
    }
  }

  &__header {
    font-size: $fontSize28;
    margin: 0 0 25px;
    color: $inputColor;
  }

  &__copy {
    font-size: $fontSize17;
    margin: 0 0 24px;
    color: $inputColor;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
  }

  &__extend {
    margin-right: 19px;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(21, 35, 36, 0.7);
    z-index: $zIndexHighest;
  }

  &__timer {
    font-weight: bold;
  }
}
