@import '../../styles/constants.scss';

.spacer {
  background: $colorSeparators;
  height: 1px;
  width: 100%;

  margin: 40px 0 34px 0;

  &--small {
    margin: 20px 0;
  }

  &--light {
    background: $colorFlowBorders;
  }
}
