@import '../../styles/constants.scss';

.menu {
  display: flex;
  &__item {
    @include linkStyles();
    text-transform: capitalize;
    margin: 0 10px;
    &--selected {
      color: #003d93;
    }
  }
}

.object {
  margin-left: 50px;
  &__field {
    display: flex;
    align-items: center;
    &__name {
      display: inline-block;
    }
    .checkbox {
      margin-top: 0;
    }
  }
}

.fields {
  &__button,
  &__input {
    display: inline-block;
    margin: 5px 20px;
    min-height: 20px;
    min-width: 60px;
  }
}
