@import '../../styles/constants';

.notification-groups {
  &-edit {
    .form__section__body {
      padding-bottom: 20px;
    }

    .section-header {
      font-size: $fontSize28;
      font-weight: 300;
      margin-top: 0;
    }

    .info {
      color: $hoveredMenu;
      margin-top: 11px;
    }

    .align-to-right {
      position: absolute;
      right: 34px;
      top: 28px;
      color: $colorButton;
      cursor: pointer;
    }
  }

  &__ordering {
    position: relative;
    background: url(../../assets/icon_arrow_blue.svg);
    background-size: 8px 5px;
    cursor: pointer;
    width: 8px;
    height: 5px;

    &-options {
      position: absolute;
      top: 15px;
      right: -7px;
      display: none;
      z-index: $zIndexOverlay;
      width: 30px;
      border-radius: $borderRadius;

      &--show {
        display: flex;
        flex-direction: column;
        background: $sectionBackground;
        box-shadow: $sectionBoxShadow;
      }

      &--upwards {
        top: unset;
        bottom: 15px;
      }
    }

    &-item {
      margin: 10px auto;
    }
  }

  .table-drag-drop {
    margin-right: 20px;
  }

  .modal:not(.leave-confirm__modal) {
    .modal__buttons {
      padding-right: 0;
      justify-content: flex-end;
      height: auto;
    }
  }

  &__modal {
    .modal__content {
      width: $modalWidth * 2;

      .table-wrapper {
        height: $maxHeight;
      }
    }
  }
}
