@import '../../styles/constants';

.input-search {
  width: 100%;
  display: flex;
  position: relative;

  &--color {
    &-blue {
      svg.input-search__look-glass path {
        fill: $colorButton;
      }
    }

    &-grey {
      svg.input-search__look-glass path {
        fill: $grey;
      }
    }

    &-white {
      svg.input-search__look-glass path {
        fill: $white;
      }
    }
  }

  &--has-icon {
    .wrapper .input-wrapper .input-text {
      padding: 12px 34px 12px 16px;
    }
  }

  &--small {
    .input-wrapper {
      input {
        padding: 7px 8px;
        font-size: $fontSize15;
      }
    }
  }

  &--has-icon-left {
    .wrapper .input-wrapper .input-text {
      padding-left: 40px;
    }
  }

  &--has-icon-left & {
    &__look-glass {
      left: 11px;
      z-index: $zIndexDefault;
    }
  }

  &__list {
    position: absolute;
    z-index: 1;
    border: 1px solid $colorBorder;
    box-sizing: border-box;
    width: 100%;
    border-top: 0;
    top: 100%;
    background: $white;
    overflow: auto;
  }

  &__options {
    position: absolute;
    z-index: $zIndexDefault;
    border: 1px solid $colorBorder;
    box-sizing: border-box;
    width: 100%;
    border-top: 0;
    background: $white;
    overflow: auto;
    top: 100%;
  }

  &__text {
    padding: 10px;
    white-space: nowrap;
    cursor: pointer;
  }

  &__el {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    white-space: nowrap;
    cursor: pointer;
    height: 48px;

    &:hover {
      background: $inputBackground;
    }

    &--selected {
      background: rgba($inputBackground, 0.5);
    }
  }

  &__sec {
    font-size: 0.875rem;
    font-weight: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__look-glass {
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
    z-index: $zIndexDefault;
  }

  &__loader {
    width: 35px;
    height: 35px;
    position: absolute;
    right: 0;
    top: 6px;
    & > svg {
      width: 100%;
      height: 100%;
    }
  }
}
