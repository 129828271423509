@import '../../styles/constants';

.leave-confirm {
  &__modal {
    & .modal__content {
      width: 520px;
      font-size: $fontSize17;
    }

    &__heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-size: $fontSize21;

      .warning-icon {
        width: 24px;
        height: 24px;
      }
    }

    & .modal__buttons {
      justify-content: flex-end;
    }
  }
}
