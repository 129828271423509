@import '../../styles/constants';

$leftMenuExtraWidth: $menuWidth + $paddingGeneric;

.sticky-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: $zIndexOverlay;
  background: $sectionBackground;
  padding: 16px $paddingRightBody 16px 0;
  width: calc(100% - #{$leftMenuClosedWidth + $leftMenuExtraWidth});
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: flex-end;

  .button + .button {
    margin-left: 16px;
  }

  &--expanded-left-menu {
    transition: width $lefMenuTransitionTime linear;
    width: calc(100% - #{$leftMenuOpenWidth + $leftMenuExtraWidth});
  }

  &__content {
    flex-basis: 50%;
    margin-right: auto;
    margin-left: $paddingGeneric;
  }
}
