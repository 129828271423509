@import '../../styles/constants.scss';

.collapsible-section {
  display: flex;
  flex-direction: column-reverse;
  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 1.75rem;
      letter-spacing: $letterSpacing05;
      margin-bottom: 25px;
      font-weight: normal;
      color: $textColor;
    }
  }

  &__extras {
    margin-left: auto;
    margin-right: 22px;
  }

  &__button {
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  &__body {
    display: none;
  }

  &__body:empty + &__heading {
    display: none;
  }

  &--has-extras &__body:empty + &__heading {
    display: flex;

    .up-down-arrow {
      display: none;
    }
  }

  &--open > &__body {
    display: block;
  }

  &--open & {
    &__button {
      img {
        transform: rotate(180deg);
      }
    }
  }

  &--small & {
    &__heading h3 {
      font-size: $fontSize15;
      color: #00c1d5;
      font-weight: bold;
      margin-top: 2px;
      margin-bottom: 2px;
    }
  }
}
