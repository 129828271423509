@import '../../styles/constants';

.modal {
  width: 100vw;
  height: 0;
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  &--opened {
    height: auto;
    min-height: 100vh;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease;
    z-index: $zIndexOverOverlay;
  }

  &__overflow {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    mix-blend-mode: multiply;
    background: rgba(21, 35, 36, 0.7);
  }

  &__heading {
    justify-self: flex-start;
    font-size: 1.75rem;
    height: calc(100% - 10px);
    display: flex;
    gap: 1rem;
    word-break: break-word;
    padding: 15px 24px;
    flex-basis: 0;
    flex-grow: 1;

    &--sticky {
      position: sticky;
      top: 0;
      background-color: $white;
      z-index: $zIndexFixedElements;
    }
  }

  &__icon {
    margin-left: auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto;
    background: $white;
    z-index: 3;
    box-sizing: border-box;
    border-radius: 4px;
    min-width: 0;
    white-space: normal;
    &--dark {
      background-color: $black;
      color: $white;
    }
  }

  &__children {
    &__padded {
      padding: 0 24px;
    }

    flex-basis: 100%;
    flex-shrink: 1;
    &--content-separator {
      padding: 15px 30px;
      border-bottom: 1px solid $colorFlowBorders;
      border-top: 1px solid $colorFlowBorders;
      font-size: $fontSize17;
      &--black {
        border-bottom: 1px solid #000;
        border-top: 1px solid #000;
      }
    }

    p {
      margin: 0;
    }

    p + p {
      margin-top: 8px;
    }
  }

  &__buttons {
    display: flex;
    padding: 15px 24px;
    flex-basis: 0;
    margin-top: auto;
    height: 60px;
    flex-grow: 1;

    > .button {
      min-width: 100px;

      & + .button {
        margin-left: 16px;
      }
    }

    &--align-right {
      justify-content: flex-end;
    }
  }

  .three-q-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: $zIndexOverOverlay;
  }
}
