@import '../../styles/constants.scss';

$headerInputColor: rgba(77, 77, 77, 0.05);

.dropdown-with-subsections {
  .select {
    &__button {
      padding: 10px;
      .button-content {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        text-transform: uppercase;
        font-size: $fontSize11;
        color: $white;
      }
      .select__options {
        min-width: 480px;
      }
    }
    &--dark {
      background-color: $backgroundDark;
      border: 1px solid $backgroundDark;
      .select__separator {
        height: 0.5px;
      }
      .select__options {
        color: $white;
        background-color: $backgroundDark;
        div[role='option'] {
          padding: 10px;
          color: $lightTextColor;
          background-color: $backgroundDark;
          &:hover {
            background: $colorButtonHover;
          }
        }
      }
    }
    &.dropdown-with-subsections &__options {
      max-height: $multiSelectDropdownHeight;
    }

    & > div[role='option']:first-of-type {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 15px);
      min-width: 30px;
    }

    &__no-checkbox {
      color: $menuBackgroundLight;
    }

    &__item {
      padding: 13px 20px;
      line-height: 1;
    }

    &__subdivision {
      div[role='option'] {
        &.disabled {
          cursor: not-allowed;
          background-color: $headerInputColor;
          .checkbox__label {
            cursor: not-allowed;
          }
          &:hover {
            background-color: $headerInputColor;
          }
        }
      }

      &__main {
        .checkbox__label .label-wrapper {
          font-weight: $fontBold;
        }
        font-size: $fontSize17;
        background-color: $headerInputColor;
        color: $textColor;
      }

      &__item[role='option'] {
        padding-left: 46px;
      }

      & &__item[role='option'] {
        padding-left: 46px;
      }

      & .deactivated .checkbox--checked .label-wrapper {
        &::after {
          margin-left: auto;
          padding-left: 8px;
          content: 'deactivated';
          color: $red;
        }
      }
    }

    &__description {
      margin-left: 30px;
    }
  }
  .dropdown--pointer {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.fake-disabled {
  .select {
    &::after {
      background-image: none;
    }
    .check {
      opacity: 0.5;
    }
    color: $inputColor;
  }
}

.select__button:after {
  content: unset;
}
.select__search--bottom input {
  padding-left: 25px;
}
