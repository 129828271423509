@import '../../../../styles/constants.scss';

.bu-copy-confirmation {
  .modal {
    &__content {
      width: 1000px;
      max-height: 924px;
    }

    &__heading {
      font-size: $fontSize21;
    }

    &__children {
      overflow: scroll;
      p {
        font-size: $fontSize17;
        margin-bottom: 15px;
        letter-spacing: 0.051px;
      }

      .label-wrapper__label__text {
        color: $black;
        font-size: $fontSize17;
      }

      .drop-down {
        .select__options {
          max-height: 236px;
        }
      }

      &__new-bu-options {
        display: flex;
        flex-direction: row;
        gap: 8px;
      }

      .input-group {
        &__elements {
          height: 42px;
        }
        &__element {
          label:not(.drop-down) {
            padding: 0;
          }

          .input-wrapper--error input:not(.select__search),
          .input-wrapper--error .select {
            border-width: 0 !important;
          }
        }
        .wrapper {
          padding: 0;
        }

        .input-wrapper {
          .select__search {
            padding: 0 20px 0 46px;
          }
        }

        &__label {
          font-weight: $fontWeight700;
          margin-bottom: 8px;
        }
      }

      .input-group + .input-group {
        margin-top: 6px;
      }

      .line-divider {
        margin: 15px -30px;
      }

      &__split-from {
        display: flex;
        flex-direction: row;
        gap: 24px;

        &__drop-down {
          width: 456px;
          flex: unset;
        }

        &__nr-of-bus {
          display: flex;
          align-items: center;
        }

        .input-group {
          margin-top: auto;
          &__elements {
            height: 48px;
          }
          &__element {
            height: 100%;
            box-sizing: border-box;

            & > .input-group-arrows {
              padding: 4px;
              svg {
                width: 18px;
                height: 18px;
              }
            }
          }
        }
      }

      .information-block {
        margin-top: 8px;
        width: fit-content;
      }
    }

    &__buttons {
      padding: 10px 24px;
      .button {
        height: 34px;
        min-width: unset;
        &__value {
          color: $textColor;
          padding: 9px 12px;
          font-size: $fontSize11;
          font-weight: $fontWeight700;
          text-transform: uppercase;
        }

        .confirm-button {
          display: flex;
          flex-direction: row;
          gap: 5px;
          align-items: center;

          svg {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }

  .new-bu-rows-container {
    max-height: 300px;
  }

  &__error-container {
    display: inline-flex;
    gap: 8px;
  }

  &__unassigned-accounts-modal {
    .modal {
      &__content {
        width: 520px;
        max-height: 561px;
      }

      &__children {
        display: flex;
        flex-direction: column;
        gap: 10px;

        p {
          margin-bottom: 0;
        }

        .item-wd__accounts {
          max-width: 460px;
          margin: 0;
          padding: 8px;
          align-items: unset;
          border-bottom: 1px solid $colorBorderSecondary;
          &__names {
            span {
              margin-left: 8px;
              color: $textColor;
              font-size: $fontSize15;
              text-transform: unset;
              line-height: 18px;
              &:last-child {
                color: $textColor;
                font-size: $fontSize10;
              }
            }
          }
          &:last-of-type {
            border-bottom: unset;
          }
        }

        .input-group__elements {
          height: 52px;
          & > :last-child {
            border-radius: 0;
          }
        }

        .unassigned-accounts-list {
          display: flex;
          flex-direction: column;
          overflow: auto;

          max-height: 280px;
          border: 1px solid $colorBorderSecondary;
        }
      }
    }
  }
}
