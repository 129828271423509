@import '../../styles/constants';

.hover-container {
  display: flex;
  transition: 0.2s width;
  align-items: center;
  position: relative;
  // Cover Shown
  &__cover {
    opacity: 1;
    transition: 0.2s opacity;
    white-space: nowrap;
  }

  &__content {
    transition: 0.2s all;
    opacity: 0;
    overflow: hidden;
    white-space: nowrap;
    position: absolute;
  }

  // Content Shown
  &--open & {
    &__cover {
      opacity: 0;
      width: 100%;
    }

    &__content {
      opacity: 1;
    }
  }
}
