@import '/src/styles/constants.scss';

.up-down-arrow {
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transform: none;
  width: 24px;
  height: 24px;

  & > svg {
    transition: 0.2s all;
  }

  &--expanded {
    & > svg {
      transform: rotateX(180deg);
    }
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.expandable-row-arrow {
  margin-right: 20px;
  align-self: center;
}
