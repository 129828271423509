.kpi-heading {
  &__extra {
    flex: 1 1 auto;
    justify-content: flex-end;
    flex-wrap: wrap;
    min-width: 0;

    .kpi-section__company-filter {
      width: calc(50% + 8px + 32px);
    }
  }
}
