@import '../../styles/constants';

.warning-icon {
    display: inline-block;
    width: 22px;
    height: 19px;
    pointer-events: none;
    .warning-icon--clickable__svg {
        cursor: pointer;
        pointer-events: all;
        &:hover, &:focus {
            path {
                fill: #ffbb00
            }
        }
    }
}