@import '../../styles/constants';

.header {
  align-items: center;
  background-color: $inputColor;
  color: $white;
  font-size: 24px;
  height: $headerHeight;
  padding: 0 0 0 17px;

  &:focus {
    outline: none;
  }

  .three-q-logo {
    cursor: pointer;
    height: 30px;
    max-height: 40px;
  }

  &__logo {
    cursor: pointer;
  }

  &__menu {
    height: 100%;
    margin-left: auto;
  }
  &__section {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 68px;
    &:hover,
    &:active {
      background: $colorButton;
    }
  }
  &__plus {
    background: $colorButton;
    cursor: pointer;
    width: $paddingRightBody;
  }

  &__notification {
    position: relative;

    &__number {
      background: $red;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      position: absolute;
      right: $fontSize11;
      top: 13px;
    }
  }

  &__client {
    background: $colorButton;
    border-radius: 20px;
    cursor: pointer;
    height: 40px;
    margin: 8px 16px;
    min-width: 80px;
    padding: 0 16px;
    width: auto;

    span {
      color: $white;
      font-weight: $fontBold;
      font-size: $fontSize15;
      letter-spacing: $letterSpacing03;
      margin-left: 10px;
    }

    &.not-loaded {
      pointer-events: none;
    }
  }

  &__emulate {
    display: inline-block;
    position: relative;

    &__wrapper {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 16px;
      &--light-blue {
        background-color: $colorButton;
      }
    }

    &__icon {
      display: flex;
      align-items: center;

      &--with-border {
        svg {
          outline-offset: 2px;
          outline: 2px solid $colorButton;
          border-radius: 50%;
        }
      }
    }

    &__info {
      font-size: $fontSize17;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px;
      cursor: pointer;
      > span {
        font-size: 0.75rem;
      }
    }

    &__stop {
      position: absolute;
      display: inline-flex;
      align-items: center;
      box-shadow: 0px 2px 10px 0px rgba(21, 35, 36, 0.15);

      cursor: pointer;

      padding: 16px;

      background-color: $white;

      color: $black;
      font-size: $fontSize17;
      white-space: nowrap;

      z-index: $zIndexOverlay;
      &__icon {
        display: flex;
        align-content: center;
        padding-right: 8px;
        svg {
          path {
            fill: $colorButton;
          }
        }
      }
    }
  }

  &__opened-menu {
    background: $sectionBackground;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.25);
    border-radius: 0 0 4px 4px;
    position: absolute;
    right: 0;
    top: 56px;
    width: 344px;
    z-index: $zIndexOverlay;

    &.large {
      width: 554px;
    }

    &__list {
      height: calc(100vh - 224px);
      overflow-y: scroll;
    }

    &__title {
      align-items: center;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
      color: $inputColor;
      display: flex;
      font-size: 28px;
      font-weight: $fontBold;
      height: 78px;
      letter-spacing: $letterSpacing03;
      padding: 0 $paddingGeneric;
    }

    &__read-all {
      color: $colorButton;
      cursor: pointer;
      font-weight: $fontBold;
      font-size: $fontSize17;
      letter-spacing: $letterSpacing02;
      margin: 10px 0 0 auto;
    }

    &__item {
      align-items: center;
      color: $inputColor;
      cursor: pointer;
      display: flex;
      font-size: $fontSize17;

      letter-spacing: $letterSpacing02;
      height: 68px;
      padding: 0 $paddingGeneric;

      &:hover {
        background: $hoveredMenuItem;
      }

      &__chevron {
        margin-left: auto;
        &--open {
          transform: rotate(180deg);
        }
      }
      &--selected {
        background: $colorButton;
        color: $white;
        &:hover {
          background: $colorButtonHover;
        }
      }

      &--hidden {
        display: none;
      }

      span {
        font-weight: $fontBold;
      }
    }
    .input-search {
      margin: 0 $paddingGeneric $paddingGeneric $paddingGeneric;
      width: auto;
    }
  }

  .notifications {
    height: calc(100vh - 56px);
    overflow: hidden;

    &-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__title {
      font-size: $fontSize38;
      font-weight: 300;
      margin: 0;
      color: #4d4d4d;
      padding: 30px 32px;
    }

    &__filters {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__filter {
      padding: 10px;
      background-color: #7a7c7c;
      fill: white;
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin: 0 4px;
      border-radius: $borderRadius;

      &:last-of-type {
        margin-right: 8px;
      }

      &--alert {
        margin-right: 1px;
        border-radius: 4px 0 0 4px;
      }

      &--brief {
        margin-left: 0;
        border-radius: 0 4px 4px 0;
      }

      &--active {
        background-color: #00c1d5;
      }

      &--disabled {
        cursor: pointer;
      }
    }

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      text-transform: uppercase;
      color: $colorButton;
      font-size: $fontSize11;
      font-weight: bold;
      padding: 8px 32px;
      border-top: 1px solid $notificationSeparator;

      &__item {
        cursor: pointer;

        &--disabled {
          @include disabled;
          opacity: 1;
          color: $colorDisabled;
        }
      }
    }

    &__list {
      overflow: scroll;
      height: calc(100% - 150px);
      position: relative;

      &--loading {
        opacity: 0.75;
      }

      .three-q-loader {
        top: 50%;
      }
    }

    .notification-item {
      border-top: 1px solid $notificationSeparator;
      position: relative;

      &__status {
        position: absolute;
        top: 16px;
        left: 16px;
      }

      &__clear {
        position: absolute;
        top: 16px;
        right: 10px;
        cursor: pointer;
      }

      &__info {
        padding: 15px 50px;

        &__message {
          font-size: $fontSize15;
          font-weight: bold;
          color: $alertResultsColor;
          margin: 0 0 4px;
        }

        &__trigger,
        &__brief,
        &__comment {
          font-size: $fontSize15;
          color: $alertResultsColor;
          margin: 0 0 10px;

          &-data {
            color: $red;
            font-weight: bold;
          }
        }

        &__brief {
          color: #00c1d5;
          font-weight: 600;
        }

        &__comment {
          font-style: italic;
          margin-bottom: 5px;
          @include textEllipsis;
        }

        &__footer {
          display: flex;
          flex-direction: row;

          &__right {
            display: flex;
            flex-direction: row;
            margin-left: auto;
          }

          &__tag {
            color: $white;
            text-transform: uppercase;
            padding: 1px 10px 0;
            border-radius: 10px;
            background-color: $red;
            font-size: $fontSize11;
            font-weight: bold;

            &--disabled {
              background-color: $cardDisabled;
            }

            &--company {
              color: #4d4d4d;
              background-color: #e0e0e0;
              margin: 0 10px 0 5px;
              @include textEllipsis;
            }

            &--inverted {
              background-color: $white;
              color: $red;
              border: 1px solid $red;
            }
          }

          &__time {
            font-size: $fontSize11;
            color: $menuBackgroundLight;
            white-space: nowrap;
          }

          &__details {
            text-transform: uppercase;
            color: $colorButton;
            font-size: $fontSize11;
            font-weight: bold;
            cursor: pointer;
            text-decoration: none;
            margin-left: 16px;

            &--disabled {
              color: $cardDisabled;
              cursor: default;
            }
          }
        }

        .fake-link {
          text-transform: none;
          font-size: $fontSize15;
          margin-top: 6px;
        }
      }

      &--disabled {
        background-color: $colorBorder;
      }
    }

    .three-q-loader {
      height: unset;
    }
  }
}
.qontrolUID {
  display: none;
}
