@import '../../styles/constants.scss';

$borderSize: 2px;
$size: 40px;

.color-swatch {
  width: $size;
  height: $size;
  border-radius: 4px;
  background-size: $size + $borderSize * 2 $size + $borderSize * 2;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  margin: auto;

  &__container {
    width: $size + $borderSize * 2;
    height: $size + $borderSize * 2;
    border-radius: 4px;
    display: flex;
    align-items: center;
    background-color: transparent;

    &--active {
      background-color: $colorButton;
      transition: .3s ease-out;
    }

    &--hoverable {
      cursor: pointer;
      .color-swatch {
        cursor: pointer;
      }
    }
  }
}
