@import '../../styles/constants';

.tag-area {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  overflow-y: scroll;
  max-height: 160px;
}
.divider {
  background: $colorFlowBorders;
  height: 1px;
  margin: 15px 0;
}
