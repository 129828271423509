.cursor {
  &--default {
    cursor: default;
  }
  &--pointer {
    cursor: pointer;
  }
  &--disabled {
    cursor: not-allowed;
  }
}
