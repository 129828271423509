@import '../../styles/constants.scss';

.fake-link {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  color: $colorButton;

  &:hover {
    color: $colorButton;
  }

  &--disabled {
    @include disabled;
    pointer-events: initial;
    cursor: not-allowed;
  }

  &--no-transform {
    text-transform: none;
  }
}

.legal-page {
  // Legal title
  h1 {
    font-size: $fontSize38;
    color: $inputColor;
  }

  .legal-content {
    // Section title
    h1 {
      font-size: $fontSize28;
      color: $inputPlaceholderColor;
    }

    background: $sectionBackground;
    border-radius: $borderRadius;
    box-shadow: $sectionBoxShadow;
    padding: 30px;
    position: relative;
  }
}

.modal-view {
  // Legal title
  h1 {
    font-size: $fontSize28;
    color: $inputColor;
  }

  .legal-content {
    // Section title
    h1 {
      font-size: $fontSize20;
      color: $inputPlaceholderColor;
    }
  }
}

.modal-content {
  max-width: $modalWidth * 2;
  overflow: auto;
  padding: 0 15px;
}

.legal-modal {
  .modal {
    position: fixed;
    left: 0;
    top: 0;
  }

  .modal__content {
    padding: 10px;
    width: 800px;
  }

  .flow-modal-content__close-icon {
    right: 15px;
    top: 20px;
  }
}

.modal-view {
  overflow: auto;
  max-height: $modalHeight;
  margin: 30px 5px 5px;
}

.text {
  font-weight: bold;
  font-size: $fontSize15;
  line-height: 1rem;
  letter-spacing: 0.07em;
  margin: 0 5px;
  cursor: pointer;

  &--simple {
    cursor: default;
  }
}

.text-link {
  text-decoration: none;
  color: #00c1d5;

  &:hover {
    color: #00c1d5;
  }
}

.text-link.disabled {
  color: $grey;
  opacity: 0.7;
}

.legal-filters.label {
  font-weight: bold;
  font-size: $fontSize11;
  line-height: 1rem;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  margin-right: auto;
}

.error--with-container {
  background: $sectionBackground;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: $borderRadius;
  box-shadow: $sectionBoxShadow;
}

.legal-view-list {
  display: flex;
  justify-content: center;
  margin-top: 22px;
}

.legal-users-table-wrapper {
  position: relative;

  .filters-toggle {
    right: calc(-56px + -13px);
    top: 48px;
  }
}

.legal-item-page {
  &__view {
    color: $textColor;
    h1 {
      font-size: $fontSize38;
      font-weight: 300;
      margin: 20px 0;
    }

    .legal-content {
      background: $white;
      filter: $dropShadowFilter;
      border-radius: $borderRadius;
      padding: 10px 32px;

      h1 {
        font-size: $fontSize28;
        font-weight: normal;
        margin: 20px 0;
      }

      p {
        font-size: $fontSize17;
      }

      &:empty {
        display: none;
      }
    }
  }
}
