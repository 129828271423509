@import '../../styles/constants';

.dropdown-multi-color {
  background: $white;
  color: $colorButton;
  border: 1px solid;
  border-radius: $borderRadius;
  font-weight: bold;
  font-size: $fontSize15;
  position: relative;
  cursor: pointer;

  &__wrapper {
    &:active,
    &:focus {
      outline: none;
    }
  }

  &--disabled {
    cursor: default;
  }

  &__face {
    position: relative;
    padding: 10px 45px 10px 10px;

    &__text {
      margin: 0;
    }
  }

  &__options {
    position: absolute;
    border-radius: $borderRadius;
    background: $white;
    top: 45px;
    right: 0;
    box-shadow: $sectionBoxShadow;
    min-width: 100%;

    &--up {
      top: unset;
      bottom: calc(100% + 5px);
    }
  }

  &__option {
    padding: 10px;
    cursor: pointer;
  }

  &__icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    &__wrapper {
      position: relative;
    }

    &__elem {
      width: 8px;
      height: 2px;
      position: absolute;
      right: 12px;

      &:first-child {
        transform: rotate(45deg) translate(-3px, 3px);
      }

      &:nth-child(2) {
        transform: rotate(-45deg);
      }
    }

    &--upwards {
      transform: rotate(180deg) translateX(36px);
    }
  }
}
