.padding {
  &-0 {
    padding-left: 0;
    padding-right: 0;
    left: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &-3 {
    padding: 3px;
  }

  &-5 {
    padding: 5px;
  }

  &-10 {
    padding: 10px;
  }

  &-15 {
    padding: 15px;
  }

  &-20 {
    padding: 20px;
  }

  &-bottom {
    &-0 {
      padding-bottom: 0;
    }

    &-5 {
      padding-bottom: 5px;
    }

    &-10 {
      padding-bottom: 10px;
    }

    &-16 {
      padding-bottom: 16px;
    }

    &-20 {
      padding-bottom: 20px;
    }

    &-30 {
      padding-bottom: 30px;
    }
  }

  &-top {
    &-0 {
      padding-top: 0;
    }

    &-5 {
      padding-top: 5px;
    }

    &-10 {
      padding-top: 10px;
    }

    &-16 {
      padding-top: 16px;
    }

    &-20 {
      padding-top: 20px;
    }

    &-30 {
      padding-top: 30px;
    }
  }

  &-right {
    &-0 {
      padding-right: 0;
    }

    &-5 {
      padding-right: 5px;
    }

    &-10 {
      padding-right: 10px;
    }

    &-16 {
      padding-right: 16px;
    }

    &-20 {
      padding-right: 20px;
    }

    &-30 {
      padding-right: 30px;
    }
  }

  &-left {
    &-0 {
      padding-left: 0;
    }

    &-5 {
      padding-left: 5px;
    }

    &-10 {
      padding-left: 10px;
    }

    &-16 {
      padding-left: 16px;
    }

    &-20 {
      padding-left: 20px;
    }

    &-30 {
      padding-left: 30px;
    }
  }

  &-y {
    &-5 {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    &-10 {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &-15 {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    &-20 {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  &-x {
    &-10 {
      padding-left: 10px;
      padding-right: 10px;
    }

    &-15 {
      padding-left: 15px;
      padding-right: 15px;
    }

    &-20 {
      padding-left: 20px;
      padding-right: 20px;
    }

    &-30 {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
