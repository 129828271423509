.alert-templates {
  .expression-wrapper {
    align-items: flex-start;

    p {
      margin-top: 0;
    }

    > div {
      width: 100%;
    }
  }

  .checkbox__label {
    align-items: center;
    margin-top: 22px;
  }
}
