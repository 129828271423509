@import '../../styles/constants.scss';

.level-of-attention {
  background: $sectionBackground;
  box-shadow: $sectionBoxShadow;
  border-radius: $borderRadius;
  padding: 20px 32px 1px;
  margin-bottom: 15px;

  &__item {
    margin-bottom: 20px;

    &-header {
      display: flex;
      flex-direction: row;

      &-icon {
        margin: 0 10px -7px 0;
      }

      &-text {
        margin-bottom: 0;
      }
    }

    .flow__separator {
      width: 47%;
      margin: 7px 0 15px;

      &--red {
        background: $red;
      }

      &--yellow {
        background: $colorWarning;
      }

      &--green {
        background: $colorConfirm;
      }
    }

    &-info {
      margin-bottom: 26px;
    }

    &__action {
      display: flex;
      flex-direction: row;

      &-slider {
        &-group {
          width: 80%;
        }

        &-value {
          margin: -4px 0 0;
          text-align: right;
          width: 20%;
        }
      }
    }
  }

  &__slider {
    width: 100%;

    &-thumb {
      border-radius: 50%;
      width: 14px;
      height: 14px;
      margin-top: -4px;
      cursor: pointer;
      outline: none;
      // To override the z-index set by the library
      z-index: $zIndexDefault !important;

      &-red {
        background: $red;
      }

      &-yellow {
        background: $colorWarning;
      }

      &-green {
        background: $colorConfirm;
      }
    }

    &-track {
      height: 4px;
      background: $colorFlowBorders;

      &-red {
        &-1 {
          background: $red;
        }
      }

      &-yellow {
        &-1 {
          background: $colorWarning;
        }
      }

      &-green {
        &-1 {
          background: $colorConfirm;
        }
      }
    }

    &-helper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 7px 12px;
      width: 100%;
    }
  }
}
