@import '../../styles/constants';

.alert-categories {
  &__modal {
    .modal__heading {
      margin-bottom: 10px;
      font-size: 21px;
      border-bottom: 1px solid $colorFlowBorders;
    }

    .modal__content {
      width: $modalWidth;
    }

    .input-name {
      margin-bottom: 22px;
    }
    .modal__buttons {
      margin-top: 10px;
      justify-content: right;
      border-top: 1px solid $colorFlowBorders;
    }
  }
}
