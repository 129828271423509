@import '../../styles/constants';

$hideSliding: 56px;
.left-menu-container {
  background: $menuBackground;
  height: calc(100vh - 56px);
  max-width: $leftMenuOpenWidth;
  transition: width $lefMenuTransitionTime linear;
  width: $leftMenuClosedWidth;
  z-index: $zIndexOverOverlay;

  &__logout {
    position: absolute;
    bottom: 0;
    display: flex;
    overflow: hidden;
    height: $hideSliding;
    max-width: $leftMenuOpenWidth;
    z-index: $zIndexLeftMenu;

    &__overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(21, 35, 36, 0.7);
      display: none;
      z-index: -1;
    }

    &__button {
      position: relative;
      width: $hideSliding;
      background-color: $menuBackground;

      img {
        width: 22px;
        height: 22px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &--open &__button {
      background-color: $colorButton;
    }

    &--open &__popup {
      width: $leftMenuOpenWidth - $hideSliding;
    }

    &--open &__overlay {
      display: block;
    }

    &__popup {
      cursor: default;
      white-space: nowrap;
      display: flex;
      background: $white;
      width: 0;
      overflow: hidden;
      transition: 0.1s ease-in-out;
      justify-content: space-around;
      align-items: center;
      font-size: $fontSize17;
    }
  }

  &--opened {
    transition: width $lefMenuTransitionTime linear;
    width: $leftMenuOpenWidth;
  }

  &--hide-sliding {
    width: $hideSliding;
  }

  &--animations-disabled {
    transition: none;
  }

  &__fixed-menu {
    height: auto;
    min-width: $menuWidth;
    margin: auto 0 $hideSliding 0;
  }

  &__fixed-tabs {
    cursor: pointer;
    height: $hideSliding;

    & > span {
      background: $white;
      border-radius: 26px;
      color: $black;
      font-weight: 600;
      font-size: 0.875rem;
      height: 26px;
      width: 26px;
      text-align: center;
    }

    &--selected {
      background: $menuBackgroundLight;
    }

    &--hide-no-access {
      visibility: hidden;
    }

    &--disabled {
      cursor: not-allowed;
    }
  }
  &__fixed-tabs:nth-child(4) {
    margin-top: auto;
  }

  &__sliding-menu {
    overflow-y: auto;
    overflow-x: hidden;
    background: $menuBackgroundLight;
    height: 100%;
    width: 100%;
    max-width: 200px;
    min-width: $menuWidth;

    &--hidden {
      display: none;
    }
  }

  &__switch {
    background: $white;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    left: 45px;
    position: absolute;
    top: 50%;
    transform: translate(0, -66%);
    width: 20px;

    &--rotate img {
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }
    &--hidden {
      display: none;
    }
  }

  .left-menu-legal-item {
    align-items: center;
    display: flex;
    color: #fafafa;
    cursor: pointer;
    font-size: 1.0625rem;
    padding: 5px;

    &--active {
      color: $colorButton;
    }

    &--text {
      cursor: default;
    }
  }

  &__tabs {
    align-items: center;
    display: flex;
    color: $lightTextColor;
    cursor: pointer;
    font-size: $fontSize17;
    height: $headerHeight;
    min-height: 40px;

    &--selected {
      background: $hoveredMenu;
    }

    &--column {
      flex-direction: column;
    }

    .tab-label {
      opacity: 0;
      transition: visibility 0s linear 0ms, opacity 0ms;
      visibility: hidden;
      width: 0;

      &--sub-item {
        font-size: $fontSize15;
      }
    }

    .tab-image {
      opacity: 1;
      transition: visibility 0s linear 250ms, opacity 250ms;
      visibility: visible;
      $iconWidth: 22px;
      min-width: $iconWidth;
      max-width: $iconWidth;
      min-height: $iconWidth;
      max-height: $iconWidth;
    }

    &--show {
      min-height: unset;
      .tab-label {
        opacity: 1;
        transition: visibility 0s linear 250ms, opacity 250ms;
        visibility: visible;
        width: 184px;
      }
      .tab-image {
        opacity: 0;
        transition: visibility 0s linear 0ms, opacity 0ms;
        visibility: hidden;
        width: 0;
      }
    }

    &__item {
      display: flex;
      width: 100%;
      flex-direction: row;
      min-height: $headerHeight;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      box-sizing: border-box;

      &:hover {
        background: $hoveredMenu;
      }
    }
  }
}

.legal-items {
  margin-top: auto;
}

.collapse_icon {
  width: 22px;

  &--open {
    transform: rotate(180deg);
  }
}
