.margin {
  &-auto {
    margin: auto;
  }

  &-0 {
    margin: 0;
  }

  &-10 {
    margin: 10px;
  }

  &-15 {
    margin: 15px;
  }

  &-bottom {
    &-auto {
      margin-bottom: auto;
    }

    &-0 {
      margin-bottom: 0;
    }

    &-5 {
      margin-bottom: 5px;
    }

    &-8 {
      margin-bottom: 8px;
    }

    &-10 {
      margin-bottom: 10px;
    }

    &-16 {
      margin-bottom: 16px;
    }

    &-20 {
      margin-bottom: 20px;
    }

    &-22 {
      margin-bottom: 22px;
    }

    &-30 {
      margin-bottom: 30px;
    }
  }

  &-top {
    &-auto {
      margin-top: auto;
    }

    &-0 {
      margin-top: 0;
    }

    &-5 {
      margin-top: 5px;
    }

    &-8 {
      margin-top: 8px;
    }

    &-10 {
      margin-top: 10px;
    }

    &-15 {
      margin-top: 15px;
    }

    &-16 {
      margin-top: 16px;
    }

    &-20 {
      margin-top: 20px;
    }

    &-22 {
      margin-top: 22px;
    }

    &-30 {
      margin-top: 30px;
    }

    &-32 {
      margin-top: 32px;
    }
  }

  &-right {
    &-auto {
      margin-right: auto;
    }

    &-0 {
      margin-right: 0;
    }

    &-5 {
      margin-right: 5px;
    }

    &-8 {
      margin-right: 8px;
    }

    &-10 {
      margin-right: 10px;
    }

    &-16 {
      margin-right: 16px;
    }

    &-22 {
      margin-right: 22px;
    }

    &-30 {
      margin-right: 30px;
    }
  }

  &-left {
    &-auto {
      margin-left: auto;
    }

    &-0 {
      margin-left: 0;
    }

    &-5 {
      margin-left: 5px;
    }

    &-8 {
      margin-left: 8px;
    }

    &-10 {
      margin-left: 10px;
    }

    &-16 {
      margin-left: 16px;
    }

    &-22 {
      margin-left: 22px;
    }

    &-30 {
      margin-left: 30px;
    }
  }

  &-y {
    &-auto {
      margin-top: auto;
      margin-bottom: auto;
    }

    &-15 {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    &-20 {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
