@import './constants.scss';

.fill-white {
  fill: $white;
}

.fill-green {
  fill: $colorSuccess;
}

.fill-light-blue {
  fill: $colorButton;
}

.fill-blue {
  fill: $publishingStatus;
}

.fill-grey {
  fill: $colorSecondaryText;
}

.fill-red {
  fill: $red;
}

.fill-orange {
  fill: $colorWarning;
}

.fill-black {
  fill: $black;
}
