@import '../../styles/constants';
.field-dropdown {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: $fontSize15;
  font-weight: 400;
  line-height: 16px;
  text-align: left;

  &__field {
    padding: 11px 16px;
    border: 1px solid $hoveredMenu;
    border-radius: 4px 0 0 4px;
    margin-right: -1px;
    background: $white;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 90px;
    min-width: 30px;

    &--second {
      border-radius: 0;
    }
    &--reversed {
      border-radius: 0 4px 4px 0;
    }
    &--disabled {
      color: $inputDisabledColor;
    }
  }

  &__duplicate {
    border: 1px solid $hoveredMenu;
    display: flex;
    padding: 4px;
    border-radius: 4px 0 0 4px;
    margin-right: -1px;
  }

  &__dropdown {
    width: calc(100% - #{$iconRemoveWidth});
    .select {
      min-width: unset;
    }
  }

  &__description {
    color: $colorSecondaryText;
    margin-left: 4px;
  }

  &__extra {
    .city-radius {
      .select {
        max-width: 150px;
        min-width: 150px;
      }
    }
  }
  .select {
    background-color: $white;
    font-size: $fontSize15;
    padding: 12px 16px;
    background-position: calc(100% - 20px) calc(1em + 2px),
      calc(100% - 16px) calc(1em + 2px), calc(100% - 6px) 0.5em;
    border: 1px solid $hoveredMenu;
    border-radius: 0;
    height: 40px;
    min-height: unset;
    outline: none;

    > div[role='option'] {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding-right: 13px;
    }

    &__options {
      background: $white;

      > div[role='option'] {
        font-size: $fontSize15;
        padding: 12px 8px;
        border-top: none;
        border-bottom: none;
        &.unselectable {
          padding-top: 0;
          padding-bottom: 16px;
        }
      }
    }
  }

  &__remove {
    background: $white;
    padding: 8px;
    cursor: pointer;
    border: 1px solid $hoveredMenu;
    margin-left: -1px;
    display: flex;
    border-radius: 0 4px 4px 0;
  }
}
