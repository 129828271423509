@import '../../styles/constants.scss';

.panel-with-highlights {
  margin-bottom: 16px;
}
.text-panel {
  height: 168px;
  border: 1px solid var(--text-forms-disabled-text, $inputDisabledColor);
  border-radius: 4px;
  padding: 10px 16px;
  font-size: $fontSize13;
  color: $textColor;
}

.hovered--expression {
  font-weight: 800;
}
