@import './constants.scss';

.sticky-buttons {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: $zIndexOverOverlay;

  background: $sectionBackground;
  padding: 16px $paddingRightBody 16px 0;
  width: 100%;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: flex-end;

  .button + .button {
    margin-left: 16px;
  }
}

.input-wrapper {
  width: 100%;
  flex: 1 1;
  display: inline-flex;
  align-items: center;
  position: relative;

  &--error {
    input:not(.select__search),
    textarea,
    .select {
      border: 1px solid $red !important;
    }
  }
}

.error-wrapper {
  position: absolute;
  right: 5px;
  bottom: -20px;
  align-items: center;
  display: flex;
  text-align: right;
  color: $red;
  font-size: 0.875rem;
  margin-top: 20px;
}

.label-wrapper {
  display: flex;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
  flex-shrink: 0;
}

.form {
  &--loading {
    input,
    textarea,
    .select {
      @include disabled();
    }
    .switch-container__switch-toggle {
      @include disabled();
    }
    button {
      @include disabled();
    }
  }

  &__button {
    margin-top: 20px;
  }

  hr {
    border-top: 1px solid $colorSeparators;
    border-bottom: 0;
    margin: 20px 0;
  }

  &__row {
    display: flex;
    position: relative;
    width: 100%;
    margin-top: 22px;
    min-height: 64px;
    &:first-child {
      margin-top: 0;
    }
  }

  &__half {
    width: 47%;
    max-width: 47%;
    margin-right: 6%;

    &--small-gap {
      width: calc(50% - 15px);
      max-width: calc(50% - 15px);
      margin-right: 30px;
    }
    &:nth-child(2n + 2) {
      margin-right: 0;
    }
  }

  &__full {
    width: 97%;
    max-width: 97%;
    margin-right: 6%;
    &:nth-child(2n + 2) {
      margin-right: 0;
    }
  }

  &__section {
    padding: 0 0 20px;
    border-bottom: 1px solid $colorFlowBorders;
    box-sizing: border-box;

    &:last-child {
      border-bottom: 0;
    }

    &:first-of-type {
      padding-top: 0;
    }

    &__header {
      display: flex;
      font-size: 1.75rem;
      letter-spacing: $letterSpacing05;
      margin-bottom: 25px;
      font-weight: 300;
      align-items: center;
    }

    &__body {
      background: $sectionBackground;
      border-radius: $borderRadius;
      box-shadow: $sectionBoxShadow;
      padding: 30px;
      position: relative;

      &--split {
        padding: 30px 0;
      }

      &.half-width,
      .half-width {
        max-width: calc(50% - 16px);
        min-width: 100px;
        margin-right: $paddingGeneric * 0.25;
      }
      &.spacing-bottom {
        margin-bottom: 20px;
      }

      &__over-form-button {
        position: absolute;
        right: 0;
        top: -70px;
        .space-right {
          margin-right: 16px;
        }
      }

      &__half-width-section {
        width: calc(50% - 32px);
        margin-right: 32px;
        &.right-side {
          margin: 0 0 0 32px;
        }
      }

      &--error {
        box-shadow: 0 2px 4px $red;
        margin-bottom: 8px;
      }

      .input-half-width {
        width: 50%;
        margin-right: 4px;
        &.right-half-input {
          align-self: flex-start;
          margin: 0 0 0 4px;
        }
      }
      & + & {
        margin-top: $paddingGeneric;
      }

      & + .align-row {
        margin-top: $paddingGeneric;
      }

      & + .spacer {
        margin-top: 0;
      }

      &.disabled {
        pointer-events: none;
      }

      &:empty {
        display: none;
      }

      &__explanation {
        color: $hoveredMenu;
        font-size: $fontSize14;
      }
    }
  }
}

// Placeholder for content editable
[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  position: absolute;
  color: $inputColor;
  background-color: transparent;
}

.count {
  font-weight: $fontBold;
  color: $inputColor;
  font-size: 0.625rem;
  line-height: 1rem;
  text-align: right;
  letter-spacing: 0.07em;
  text-transform: uppercase;
}

.hint {
  text-transform: none;
  font-weight: normal;
  font-size: $fontSize14;
  color: $hoveredMenu;
}

.input-full-width {
  width: 100%;
}
