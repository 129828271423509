@import '../../styles/constants';

$filtersWidth: 520px;

.filters-toggle {
  cursor: pointer;
  position: absolute;
  right: 13px;
  top: 25px;
  background: $white;
  width: 56px;
  height: 56px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $borderRadius;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);

  & > svg {
    border: 2px solid #00c1d5;
    padding: 5px;
    border-radius: 50%;
  }

  &--applied {
    background: #00c1d5;

    & > svg {
      border-color: white;

      path {
        fill: white !important;
      }
    }
  }
}

.filters {
  position: fixed;
  top: $headerHeight;
  bottom: 0;
  right: -$filtersWidth;
  width: $filtersWidth;
  background: $sectionBackground;
  box-sizing: border-box;
  transition: right 0.4s ease;
  z-index: $zIndexOverOverlay;
  border-radius: $borderRadius 0 0 $borderRadius;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.25);
  overflow: auto;
  display: flex;
  flex-direction: column;

  & &__section {
    margin-top: 16px;

    &__label {
      font-weight: $fontBold;
      font-size: $fontSize11;
      line-height: 1rem;
      text-transform: uppercase;
      color: $textColor;
      letter-spacing: 0.07em;
    }

    &__body {
      margin-top: 6px;

      .input-wrapper {
        margin-top: unset;
      }
    }

    & + & {
      margin-top: 20px;
    }
  }

  .input-wrapper {
    margin-top: 22px;

    &:first-child {
      margin-top: 0;
    }
    input[type='number'] {
      &::placeholder {
        color: $inputPlaceholderColor;
      }
    }
  }

  label.filter-label + .input-wrapper {
    margin-top: 0;
  }

  label.filter-label {
    font-weight: $fontBold;
    font-size: $fontSize11;
    line-height: 1rem;
    text-transform: uppercase;
    color: $inputPlaceholderColor;
    letter-spacing: 0.07em;
  }

  &--opened {
    right: 0;
  }

  &__heading-box {
    display: flex;
    background: white;
    padding-top: 10px;
    border-bottom: 1px solid #dbdbdb;


    .heading {
      margin-top: 0;
      margin-left: 32px;
      margin-bottom: 10px;
      font-size: $fontSize21;
    }

    &__buttons {
      display: flex;
      margin: 0 0 auto auto;
      .filters-button {
        display: flex;
        align-items: center;
      }
      .button {
        &:first-child {
          margin-right: 17px;
        }

        &:last-child {
          margin-right: 32px;
        }
      }
    }
  }
  &--container {
    padding: 15px $paddingGeneric $paddingGeneric;
    overflow: auto;
  }
}
