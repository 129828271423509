@import '../../styles/constants';

.label-wrap {
  flex-basis: 100%;
  color: $inputPlaceholderColor;

  &__label {
    font-weight: $fontBold;
    font-size: $fontSize11;
    line-height: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.07em;
  }

  &__body {
    font-size: $fontSize17;
    .input-wrapper {
      margin-top: unset;
    }
  }

  &--black {
    color: $textColor;
  }

  &--green {
    color: $colorConfirm;
  }

  &--grey &__body {
    color: $colorSecondaryText;
    font-style: italic;
  }

  &--black &__label {
    font-weight: bold;
  }

  &--green &__label {
    color: $textColor;
  }
}

.align-row {
  & > .label-wrap + .label-wrap {
    margin-left: 16px;
  }
}
