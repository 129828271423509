@import '../../styles/constants';

.item-row {
  width: 100%;
  line-height: 1.3125rem;
  display: flex;
  border: 1px solid $hoveredMenu;
  font-size: $fontSize15;
  box-sizing: border-box;
  margin-bottom: 10px;
  border-radius: $borderRadius;
  background-color: $white;

  &.has-progress-bar {
    height: 40px;
  }

  &__progress-bar {
    width: 150px;
    min-width: 150px;
    padding: 10px;
    border-left: 1px solid $hoveredMenu;

    &__bar {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      background-color: $colorAltBackground;
      position: relative;

      &__completed-bar {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: $colorConfirm;
        max-width: 100%;
      }
    }
  }

  &__info-icon {
    display: inline-flex;
    justify-self: flex-end;
    align-items: center;

    .icon-container {
      margin-right: 8px;
    }
  }

  &__details {
    display: flex;
    padding: 11px 8px;
    flex-basis: 100%;
    flex-shrink: 1;
    justify-content: space-between;
    &__copy {
      display: flex;
      flex-direction: column;
    }
    &__text {
      overflow: hidden;
      white-space: pre-wrap;
      text-overflow: ellipsis;
      word-wrap: break-word;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      align-content: center;
      word-break: break-word;
    }

    &__description {
      color: $colorSecondaryText;
      font-size: $fontSize14;
    }
  }

  &__action {
    cursor: pointer;
    border-left: 1px solid $hoveredMenu;
    width: 32px;
    min-width: 32px;

    &--preview {
      background: url('../../assets/icon_eye_blue.svg') no-repeat 50%;
    }
    &--add {
      background: url('../../assets/icon_plus_blue.svg') no-repeat 50%;
    }
    &--edit {
      background: url('../../assets/icon_edit.svg') no-repeat 50%;
    }
    &--delete {
      background: url('../../assets/icon_minus.svg') no-repeat 50%;
    }
    &--delete-opaque {
      background: url('../../assets/icon_minus.svg') no-repeat 50%;
      opacity: 0.5;
    }
    &--cancel {
      background: url('../../assets/icon_clear_red.svg') no-repeat 50%;
    }
    &--revert {
      background: url('../../assets/icon_undo.svg') no-repeat 50%;
    }
    &--error {
      border-left: 1px solid $red;
      background: url('../../assets/icon_warning_round_red.svg') no-repeat 50%;
    }
    &--disabled {
      opacity: 0.5;
      cursor: default;
    }
    &--pin {
      display: flex;
      min-width: 64px;
      align-items: center;
      justify-content: center;
      &--text {
        font-weight: 700;
        font-size: $fontSize11;
        letter-spacing: 1px;
        color: $colorButton;
        margin-left: 5px;
      }
    }
  }

  &__info {
    border-right: 1px solid $hoveredMenu;

    &--preffix {
      display: flex;
      padding: 20px;
      align-items: center;
      svg {
        height: 25px;
        width: 25px;
      }
    }

    &--thumbnail {
      display: flex;
      padding: 4px 10px;
      position: relative;
      width: 52px;

      img {
        display: block;
        margin: auto;
        width: 100%;
      }

      .video-icon {
        position: absolute;
        width: 20px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &--edited {
    background-color: $colorInfo;
  }
  &--fake-disabled {
    opacity: 0.5;
  }
  &--dark {
    background: #343635;
  }
  &--error {
    border: 1px solid $red;
    .item-row__details__text {
      color: $red;
    }

    .item-row__progress-bar {
      border-left: 1px solid $red;
    }
    .item-row__progress-bar__bar__completed-bar {
      background: #e8eeef;
    }
  }
}
