@import '../../styles/constants';

.accounts {
  .table-actions {
    width: 150px;
  }

  &__header {
    display: flex;
    align-items: center;
    font-size: 17px;
    border-bottom: 1px solid $colorFlowBorders;
    margin: -15px -24px 15px -24px;
    padding: 15px 24px;
    svg {
      width: 18px;
      height: 18px;
      padding-right: 5px;
    }
    .checkbox-select-all {
      margin-left: auto;
    }
  }

  &__section {
    .input-group__element {
      align-items: center;
    }
  }

  &__bulk_edit {
    display: flex;
    gap: 25px;
    &__status {
      max-width: 124px;
    }
    .drop-down {
      > label {
        font-size: 11px;
        text-transform: uppercase;
      }
    }
    &__BUAssotiation {
      &:after {
        color: $colorButtonGrey;
        padding-left: 5px;
        content: ' Optional';
      }
    }
    &__check {
      background-color: map-get(
        map-get($map: $buttonColorMap, $key: confirm),
        $key: base
      );
    }
  }

  &__link {
    font-weight: bold;
  }

  &__create-edit {
    .fixed-height {
      height: 40px;
      margin-left: auto;
    }

    &__icon {
      border: 1px solid #bccbcc;
      border-radius: $borderRadius;
      display: flex;
      justify-content: center;
      > svg {
        width: 91px;
        height: 91px;
        padding: 13px 12px;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
    &__name {
      font-size: $fontSize17;
      color: $textColor;
      margin-top: 5px;
    }

    &__id {
      font-size: $fontSize14;
      color: $colorSecondaryText;
      margin-top: 4px;
    }

    &__unlink {
      margin-top: 16px;
      align-self: flex-end;
      text-transform: uppercase;

      .button__value {
        padding: 8px 12px;
        font-size: 11px;
        color: $textColor;
      }

      &__value {
        display: flex;
      }

      svg {
        padding-right: 5px;
        path {
          fill: $colorRedVibrant;
        }
      }
    }

    &__dropdowns {
      width: 224px;
      margin-top: 5px;
    }

    .account__type {
      display: inline-flex;
      align-self: flex-end;
    }

    .form__section {
      border-bottom: none;
      padding-bottom: 40px;

      & + .form__section {
        border-top: 1px solid $colorBorderSecondary;
        padding-top: 40px;
      }
    }

    &__selection-header {
      display: flex;

      &-icon {
        width: 24px;
        height: 24px;
        margin: 5px 10px 0 0;
      }

      &-text {
        margin-bottom: 20px;
        font-size: $fontSize21;
        font-weight: 400;
      }
    }

    &__toggle {
      justify-content: space-between;
      padding-left: 5px;
      display: flex;
      width: 100%;
    }
    &__users-dropdown {
      .select__options {
        min-width: 240px;
      }
    }
    .section__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 24px;
    }
  }

  &__link-bu {
    padding: 0;

    &__heading {
      font-size: $fontSize21;
      border-bottom: 1px solid $colorFlowBorders;
      padding: 15px 24px;
      line-height: 1.3;
    }
    &__body {
      padding: 20px 24px 15px;
    }
  }
  &__account-section {
    .select__options {
      margin: 10px 0;
      border-radius: 0;
    }
  }
}

.mcc-id {
  margin-bottom: 10px;
  .checkbox__label {
    width: auto;
  }
}

.sub-accounts {
  .table-actions {
    width: 100px;
  }
  .table__actions {
    justify-content: center;
  }
}
.sub-accounts-table {
  .button.align-right {
    margin-left: auto;
  }

  .button.fixed-height {
    height: 40px;
  }
}

.alert-templates-table {
  .table__cell {
    padding: 6px;
  }
}

.perform-qa__button {
  padding: 8px 12px;
}
