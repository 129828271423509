@import '../../styles/constants';

.refresh-notification {
  position: fixed;
  top: calc(8px + #{$headerHeight});
  right: 8px;
  padding: 8px 16px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: $fontSize11;
  z-index: $zIndexOverOverlay;
  display: flex;
  flex-direction: row;
  border-radius: $borderRadius;
  box-shadow: $sectionBoxShadow;
  border: 1px solid $red;
  background-color: $red;

  &__text {
    margin: 0;
    color: $white;
    transform: translateY(4px);

    &--timer {
      margin-left: 4px;
    }
  }

  &__action {
    display: flex;
    flex-direction: row;
    color: $red;
    cursor: pointer;
  }

  &__icon {
    margin: 0 1px 0 5px;
  }

  &__overlay {
    position: absolute;
    top: #{$headerHeight};
    left: #{$menuWidth};
  }
}
