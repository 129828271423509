@import '../../../../../styles/constants.scss';

.single-client-alert-confirmation {
  .modal {
    &__content {
      width: 520px;
    }

    &__children {
      max-height: 70vh;

      .alerts-container {
        max-height: 38vh;
        overflow: auto;
      }

      .alert-item {
        border: 1px solid black;
        border-bottom-width: 0px;
        padding: 10px 16px;

        &:last-of-type {
          border-bottom-width: 1px;
        }
      }
    }

    &__heading {
      font-size: $fontSize21;
    }

    &__children {
      p {
        font-size: $fontSize17;
        margin-bottom: 15px;
        letter-spacing: 0.051px;
      }

      .label-wrapper__label__text {
        color: $black;
        font-size: $fontSize17;
      }

      &__buttons {
        padding: 10px 24px;
        .button {
          height: 34px;
          min-width: unset;
          &__value {
            color: $textColor;
            padding: 9px 12px;
            font-size: $fontSize11;
            font-weight: $fontWeight700;
            text-transform: uppercase;
          }

          .confirm-button {
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;

            svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
  }
}
