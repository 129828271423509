@import '../../styles/constants';

.icon-container {
  display: flex;
  align-items: center;
  &__content {
    transition: all 0.3s;
    align-items: center;
    display: inline-flex;
    gap: 8px;
  }

  &--clickable {
    cursor: pointer;

    .icon-container__content {
      &:hover,
      &:focus {
        filter: brightness(0.8);
      }
    }
  }

  &--disabled svg {
    opacity: 0.5;
  }
}
