@import '../../styles/constants';

.global-settings {
  &__tabs {
    display: flex;
    justify-content: stretch;
    overflow: auto;
  }

  &__tab {
    border-bottom: 2px solid $colorBorderSecondary;
    padding: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $fontSize17;
    font-weight: $fontBold;
    color: $textColor;
    cursor: pointer;
    min-width: fit-content;
    text-emphasis: none;
    text-decoration: none;

    &:hover {
      text-emphasis: none;
      text-decoration: none;
      color: $textColor;
    }

    &.active {
      border-bottom: 2px solid $colorButton;
    }

    &.active:hover {
      color: $colorButton;
    }

    > svg {
      margin-right: 8px;
    }
  }

  &__content {
    margin-top: 32px;
  }

  &__half-width {
    width: 50%;
    min-width: 240px;
    max-width: 600px;
  }

  .collapsible-section {
    border-top: 1px solid $colorFlowBorders;
  }
}

.security,
.final-review {
  h1 {
    font-size: $fontSize38;
    color: $inputColor;
    line-height: 1rem;
    font-weight: 300;
    letter-spacing: 0.3px;
    margin: 35px 0;
  }

  h3 {
    & + p {
      margin-top: 1px;
    }
  }

  .form__row {
    min-height: unset;
    margin-top: 10px;
  }
  .status-label {
    position: absolute;
    right: 0;
    top: 20px;
    padding: 8px 16px;
    max-width: 50%;
    box-shadow: $sectionBoxShadow;
    border-radius: $borderRadius;
    text-align: right;
    line-height: 1rem;
    font-size: 0.6875rem; //11px
    color: $white;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    font-weight: $fontBold;
    background: $alertResultsColor;

    &--publishing {
      background: $publishingStatus;
    }
    &--published {
      background: $publishedStatus;
    }
    &--build-failed {
      background: $red;
    }
  }
}

.security {
  p {
    margin: 0;
    font-size: $fontSize14;
    color: $inputColor;
  }
}

.slack-colors {
  &__colors {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }

  &__color-card {
    display: flex;
    padding: 19px 24px;
    font-size: $fontSize21;
    box-sizing: border-box;
    background-color: $sectionBackground;
    border-radius: $borderRadius;
    box-shadow: $flowBoxShadow;
    align-items: center;
    justify-content: space-between;
    color: $textColor;

    &__name {
      flex-basis: 60%;
      flex-shrink: 1;
    }

    &__edit {
      min-width: 18px;
      flex-basis: 18px;
      cursor: pointer;

      &:hover {
        path {
          fill: darken($colorButton, 5);
        }
      }

      &-section {
        position: relative;
      }
    }

    &__color {
      margin-left: 11px;
    }

    &__color-bubble {
      width: 32px;
      height: 32px;
      min-width: 32px;
      max-width: 32px;
      min-height: 32px;
      max-height: 32px;
      border-radius: 50%;
      margin-left: 26px;
    }
  }
}

.daily-entity-cleanup {
  &__description {
    margin: 15px 0;
    font-size: $fontSize14;
    color: $inputColor;
  }

  &__table {
    min-width: 240px;
    border: 1px solid $colorBorderSecondary;
    border-radius: $borderRadius;
    &__row {
      display: flex;
      flex-grow: 2;
      align-items: center;
      justify-content: space-between;
      height: 48px;
      padding-left: 16px;
      padding-top: 4px;
      padding-bottom: 4px;
      font-size: $fontSize15;

      .switch-container__label {
        font-size: $fontSize15;
      }

      &:nth-child(2n) {
        background-color: #f7f7f7;
      }

      & > .icon-container {
        margin: 0 20px;
      }
    }

    &__days {
      display: flex;
      align-items: center;
      justify-self: flex-end;
      span {
        margin: 0 16px;
        white-space: nowrap;
        font-size: $fontSize15;
      }
    }
  }
}

.global-reports {
  min-width: 600px;
  &__title {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__description {
    font-size: $fontSize14;
  }

  .input-group {
    .switch-container {
      margin: auto 0;

      &__label {
        font-size: $fontSize12;
        text-transform: uppercase;
        font-weight: $fontBold;
      }
      width: 100%;
    }
  }
}

.history {
  &__details-modal {
    .modal {
      &__content {
        width: 80%;
        max-height: 70%;
      }

      &__children {
        overflow: scroll;
      }
    }

    &-heading {
      width: 100%;
      justify-content: space-between;
    }

    &-close {
      margin: 12px 10px 0 0;
      cursor: pointer;

      path {
        fill: #00c1d5;
      }
    }

    &__detail {
      border: 1px solid $colorBorderSecondary;
      border-radius: $borderRadius;
      padding: 15px;
      word-break: break-word;
      max-height: 200px;
      overflow: auto;

      &:last-child {
        margin-bottom: 15px;
      }

      &-label {
        margin-top: 15px;
      }

      &-divider {
        margin-top: 20px;
      }
    }
  }

  &-csv {
    transform: rotate(180deg);
  }

  &-info-icon {
    cursor: pointer;
  }

  .entity-selector__extra-actions {
    justify-content: flex-start;
    margin-left: 5px;
  }
}
