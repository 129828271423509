@import '../../styles/constants.scss';

@mixin disabledButtonToggle {
  cursor: default;
  color: $colorSecondaryText;
  outline-color: $colorBorderSecondary;
}

.button-toggle {
  border-radius: $borderRadius;
  border: none;
  background: $white;
  padding: 8px 12px;
  outline: 1px solid $colorFlowBorders;
  font-size: $fontSize11;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  box-sizing: content-box;
  transition: 0.2s all;
  justify-content: space-around;

  cursor: pointer;

  .tooltip {
    text-transform: none;
  }

  &__label {
    display: flex;
    align-items: center;
    gap: 5px;
    color: $textColor;
    font-weight: $fontBold;

    &__icon {
      width: 18px;
      height: 18px;
      background-color: $colorButton;
      &--last {
        margin-left: auto;
      }
    }
  }

  &--no-click {
    color: $colorSecondaryText;
  }

  &--disabled {
    @include disabledButtonToggle;
  }

  &--disabled & {
    &__label {
      color: $inputDisabledColor;

      &__icon {
        background-color: $colorPrimaryDisabled;
      }
    }
  }

  &--tall {
    align-self: stretch;
  }

  &:not(.button-toggle--no-click) & {
    &:hover {
      background-color: $blueHover;
      outline-color: $colorButton;
    }
  }

  &--disabled:hover,
  &--disabled:focus,
  &--disabled:active {
    @include disabledButtonToggle;
    outline-color: $colorPrimaryDisabled;
  }

  &--selected {
    background: $blueHover;
    outline: 2px solid $colorButton;
    outline-offset: -1px;
  }

  &--selected#{&}--disabled {
    @include disabledButtonToggle;
    outline-color: $colorPrimaryDisabled;
  }

  &--inner-padding {
    padding: 0;
    .button-toggle__label {
      padding: 8px 12px;
    }
  }
}
