@import '../../styles/constants.scss';

.dropdown-preview-selected {
  position: relative;
  cursor: pointer;
  width: 100%;

  &.select:not(.select--opened) {
    &::after {
      content: none;
    }
  }

  &.select.select--opened {
    &::after {
      content: '';
    }
  }

  &__custom {
    border: 1px solid $colorButton;
    width: 100px;
    max-height: 48px;
    padding: 0;
    &--closed {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      padding: 5px;
    }

    .dropdown-filters-icon {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid $colorButton;
    }

    .dropdown-search {
      padding: 4px 24px 0 4px;
      outline: none;
      width: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;

      &::after {
        background-image: linear-gradient(45deg, transparent 50%, $white 50%),
          linear-gradient(135deg, $white 50%, transparent 50%);
      }
    }
  }

  .dropdown-preview-selected-list {
    width: 100%;
    position: absolute;
    top: calc(100% + 5px);
    border-radius: 4px;
    background-color: $sectionBackground;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.15);
    max-height: 610px;
    overflow: auto;
    cursor: pointer;
    z-index: $zIndexOverlay;

    .checkbox__label {
      flex-direction: row-reverse;
      .label-wrapper {
        // allow longer labels fit in two rows
        width: calc(100% - 19px);
        text-transform: capitalize;
        font-weight: normal;
      }
    }

    &__select-all {
      margin: 10px 0;
      padding: 0 10px;
    }

    &--up {
      top: unset;
      bottom: calc(100% + 5px);
    }
    &__item {
      padding: 25px;
      align-items: center;
      cursor: pointer;
      &:hover {
        background: $inputHover;
      }
    }
  }

  &--dark {
    background: $colorCustomGrey;
    border-radius: 4px;
    color: $white;
    border: 1px solid $colorCustomGrey;
    &::after {
      background-image: linear-gradient(45deg, transparent 50%, $white 50%),
        linear-gradient(135deg, $white 50%, transparent 50%);
    }

    .input-wrapper .label-wrapper {
      color: $white;
    }

    .checkbox--checked .check .image-icon {
      background-color: $white;
    }

    .dropdown-preview-selected-list {
      background: $colorCustomGrey;
      color: $white;

      &__item {
        padding: 10px;
        &:hover {
          background: $colorItemSelected;
        }
      }
    }
  }

  &.dropdown-preview-selected__custom.dropdown-preview-selected--dark {
    border: 1px solid $colorButton;
  }

  &.dropdown-preview-selected__custom--closed.dropdown-preview-selected--dark {
    &::after {
      background-image: none;
    }
  }

  &.dropdown {
    &--conversion {
      .dropdown-preview-selected-list {
        width: 640px;
      }
    }
    &--no-padding {
      .dropdown-preview-selected-list {
        & > div {
          padding-left: 0;
          padding-right: 0;
        }
        &__item {
          padding: 8px 0;
        }
      }
    }
    &--to-right {
      .dropdown-preview-selected-list {
        right: 0;
      }
    }
  }
}
