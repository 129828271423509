@import './constants.scss';

.my-account {
  &__avatar {
    color: $inputColor;
    margin-bottom: 30px;

    &__image {
      margin-right: 16px;
      position: relative;
      max-height: $avatarSize;
    }

    &__name {
      font-size: 2.375rem;
      letter-spacing: $letterSpacing03;
      margin-top: 9px;
    }

    &__email {
      font-size: $fontSize17;
      letter-spacing: $letterSpacing05;
    }

    .avatar {
      border-radius: 50%;
      height: $avatarSize;
      object-fit: cover;
      width: $avatarSize;
      filter: opacity(1);
      transition: filter 300ms;

      &--loading {
        filter: opacity(0);
      }
    }
  }

  &__add-image {
    background: $white;
    border-radius: 50%;
    bottom: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    height: 24px;
    position: absolute;
    right: 3px;
    width: 24px;
  }

  .my-account-user {
    &__fields,
    &__dropdowns {
      width: 50%;
    }

    &__fields {
      margin-right: 32px;
      .input-wrapper {
        margin-bottom: 22px;
      }
      .wrapper:last-child .input-wrapper {
        margin-bottom: 0;
      }
    }

    &__dropdowns {
      margin-left: 32px;
      .select {
        margin-bottom: 22px;
        outline-color: white;
      }
    }

    &__dropdown {
      outline-color: white;

      .drop-down-search {
        position: absolute;
        top: 0;
        width: calc(100% - 34px);
        z-index: $zIndexOverOverlay;
      }

      .input-search__list {
        background: $colorBorder;
        border: 1px solid $inputColor;
        border-radius: 4px;
        cursor: pointer;
        max-height: 41.5vh;
        overflow: auto;
        top: 53px;
        width: calc(100% + 34px);

        &.closed {
          border: 1px solid $white;
        }
        .input-search__el {
          padding: 13px 23px;
        }
        .input-search__el:hover {
          background: $inputHover;
        }
        .input-search__el--selected {
          background: inherit;
        }
      }
    }

    &__password-expiry {
      color: $inputColor;
      margin-bottom: 27px;
      text-align: left;

      .general-label {
        color: #515353;
        margin-bottom: 0;
      }

      span:nth-child(2) {
        font-size: $fontSize17;
        letter-spacing: $letterSpacing01;
        margin-top: 10px;
      }

      .align-column:first-child {
        margin-right: 50px;
      }
    }

    &__passwords {
      min-height: 308px;
      width: $modalWidth;

      &__hint {
        color: $colorSecondaryText;
        font-size: $fontSize14;
        margin: -25px 0 10px;
      }

      .input-wrapper {
        margin-top: 22px;
      }

      .wrapper:first-child .input-wrapper {
        margin-top: 0;
      }

      .error-wrapper {
        position: relative;
        top: 8px;
      }

      .confirm-buttons {
        margin-top: 30px;
        margin-bottom: 20px;
      }
      .heading-secondary {
        margin-top: 20px;
      }
    }

    &__auth-buttons {
      height: 40px;
      margin-left: auto;

      .button {
        margin-left: 16px;
      }
    }

    &__set-up-auth,
    &__set-up-sms {
      .input-wrapper {
        margin: 7px 0 26px 0;
        max-width: 224px;
      }
      .error {
        margin: -15px 0 10px 0;
      }

      .button {
        max-width: 302px;
      }
    }

    &__set-up-sms {
      .button {
        max-width: 302px;
      }
    }

    &__cursive-text {
      color: $inputColor;
      font-weight: $fontBold;
      font-size: $fontSize17;
      letter-spacing: $letterSpacing02;
      margin-bottom: 5px;

      .resend-code {
        color: $colorButton;
        cursor: pointer;
        font-weight: $fontBold;
        font-size: $fontSize17;
        line-height: 16px;
        letter-spacing: $letterSpacing02;
        margin-left: auto;
      }
    }

    &__option-text {
      color: $inputColor;
      font-weight: $fontBold;
      font-size: 11px;
      letter-spacing: $letterSpacing07;
      margin-bottom: 6px;
      text-transform: uppercase;
    }

    &__secret-text {
      color: $inputColor;
      font-weight: 300;
      font-size: 28px;
      letter-spacing: $letterSpacing03;
      margin-bottom: 30px;
    }
  }

  &__spacer {
    background: $colorSeparators;
    height: 1px;
    margin: 40px 0 34px 0;
    width: 100%;
  }

  .wrapper__bottom {
    .error {
      text-align: unset;
    }
  }

  .standard-container {
    margin-bottom: 15px;
  }

  .form__section__body {
    position: relative;
  }
}
