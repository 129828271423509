@import '../../styles/constants.scss';

.loading__overlay {
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
  z-index: $zIndexDefault;
  pointer-events: none;
  background-color: rgba($white, 0.5);
}
