@import '../../styles/constants';

.alerts {
  &__toggles {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .switch-container {
    width: 25%;
    justify-content: space-between;
    padding: 0 13px;
    border-right: 1px solid $colorSeparators;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border-right: 0;
    }
  }

  code {
    margin-top: 20px;
    display: block;
  }

  &__positive-delta {
    color: $colorGreenMint;
  }

  &__negative-delta {
    color: $red;
  }

  &__onoff {
    display: flex;
    align-items: center;
    .switch-container {
      border: 0;
      width: 100%;
      padding-right: 5px;
    }
  }

  &__use-alert {
    margin-top: 25px;
    display: flex;
    align-items: center;
    span {
      font-weight: bold;
      margin-right: 108px;
    }

    .switch-container {
      width: auto;
      border: 0;
    }
  }

  &--view {
    input,
    .select,
    button {
      pointer-events: none;
      background: $inputDisabledBackground !important;
      cursor: default;
    }
  }

  .select__options {
    max-height: 180px;
  }

  &__recurrence {
    align-items: center;
    .recurrence-wrapper {
      flex-grow: 1;
    }
  }

  &__every {
    font-weight: $fontBold;
    margin-right: 50px;
  }

  .select-deselect-item {
    align-items: center;
    background: $grey;
    border-radius: 20px;
    color: $white;
    display: flex;
    height: 40px;
    margin: 0 8px 10px 0;
    padding: 0 12px;
    img {
      margin-left: 10px;
    }
  }

  .close-selected {
    cursor: pointer;
    margin: 0 0 0 auto;
  }

  .accounts-section-dropdowns {
    width: 100%;
    .drop-down.input-wrapper {
      max-width: 47%;
    }

    .general-label:first-of-type {
      width: 53%;
    }
  }

  &__property {
    display: flex;
    border: 1px solid $hoveredMenu;
    border-radius: $borderRadius;
    margin-bottom: 10px;
    width: 100%;

    > label {
      width: 210px;
      min-width: 210px;
      border-right: 1px solid $hoveredMenu;
      border-radius: 4px 0 0 4px;
    }

    &__value {
      border-left: 1px solid $hoveredMenu;
      border-right: 1px solid $hoveredMenu;
      width: 100%;
      display: flex;
      position: relative;

      &:first-child:not(:last-child) {
        border-left: 0;
        border-right: 1px solid $hoveredMenu;
      }

      &:last-child:not(:first-child) {
        border-right: 0;
        border-left: 1px solid $hoveredMenu;
      }

      &--reverse {
        min-width: 138px;
        width: 100%;
        &:first-child:not(:last-child) {
          border-right: 0;
          border-left: 1px solid $hoveredMenu;
        }

        &:last-child:not(:first-child) {
          border-left: 0;
          border-right: 1px solid $hoveredMenu;
        }
      }

      .wrapper {
        display: flex;
        flex-grow: 1;
      }

      &__dropdown {
        position: absolute;
        top: 100%;
        right: -1px;
        background: $white;
        border: 1px solid $hoveredMenu;
        padding: 10px;
        width: 75px;
        z-index: $zIndexFixedElements;

        &-item {
          cursor: pointer;

          &:not(:last-child) {
            margin-bottom: 5px;
          }
        }
        &--faked .select--disabled {
          &::after {
            background-image: none;
          }
        }
      }

      &--child {
        border-right: 0;
      }

      &__time {
        display: flex;
        width: 100%;

        .time__dropdown {
          width: 85px;
        }

        input[type='text'] {
          min-width: 60px;
        }
      }
    }

    &__condition {
      background: $white;
      border: 1px solid $hoveredMenu;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      width: 90px;

      &-option {
        text-transform: capitalize;
        padding: 8px 12px;
        font-size: $fontSize11;
        font-weight: 700;
        cursor: pointer;

        &:not(:last-child) {
          border-right: 1px solid $hoveredMenu;
        }

        &--selected {
          color: $colorButton;
        }
      }
    }

    &__labels {
      display: flex;
      > div {
        min-width: 211px;
        &:nth-child(2) {
          width: 286px;
        }
      }
    }

    > label,
    &__labels > div {
      &:nth-child(2) {
        width: 285px;
        min-width: 285px;
      }
      &:nth-child(3) {
        width: 176px;
        min-width: 176px;
      }
    }

    &__icon {
      width: $alertSettingsPropertiesIconWitdh;
      border-left: 1px solid $hoveredMenu;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      flex-shrink: 0;
      box-sizing: border-box;
      &--disabled {
        @include disabled();
      }
      position: relative;
    }

    .select {
      border: 0;
    }

    .select,
    input[type='text'],
    input[type='number'] {
      background-color: $white;
      border: 0;
    }

    input::placeholder {
      color: $inputDisabledBackground;
    }

    .input-wrapper {
      flex: unset;
    }

    .input-wrapper--disabled {
      @include disabled();
    }

    .date-time {
      input {
        font-size: $fontSize14;
        border: 0;
        padding: 0 5px;
        border-radius: 0;
        border-right: 1px solid $hoveredMenu;
      }
      > div:nth-child(2) input {
        padding-left: 14px;
      }
      .select {
        font-size: $fontSize14;
      }
    }
    .time__dropdown {
      min-width: 70px;
      margin: 0;
    }

    &__error {
      margin-top: -4px;
      margin-bottom: 3px;
      font-size: 0.8125rem;
    }

    &--has-error {
      border-color: $red;
    }

    &--plus-dropdown-open {
      border-radius: 4px 4px 0 4px;
    }

    &-group {
      display: flex;
      flex-direction: row;
      width: 100%;

      &--reverse {
        flex-direction: row-reverse;
        width: 478px;
        .select--searchInOptions {
          width: 252px;
        }
      }
    }

    &-operation {
      width: 225px;
      min-width: 225px;
    }
  }

  &__loader {
    width: 50%;
    margin-top: 20px;
    justify-content: flex-start;
    img {
      width: 50px;
      height: 50px;
    }
  }

  .select {
    .checkbox__label {
      width: 100%;
    }
  }

  &__heading__buttons {
    &__active-shortcut {
      display: flex;
      flex-direction: row;

      &-text {
        margin: 0;
      }

      &-icon {
        margin: auto 10px auto auto;
        background: $red;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }
  }
  .general-label {
    color: $textColor;
    font-weight: $fontWeight700;
  }

  &__granularities {
    &-header {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;

      &-text {
        font-size: $fontSize21;
        margin: 0;
      }

      &-icon {
        height: 24px;
        width: 24px;
        margin-right: 10px;
      }
    }
  }

  .form__section__body {
    &--no-padding {
      padding: 0;
    }
  }
  &__tooltip-label {
   display: flex;
   align-items: flex-start;
    .label-wrapper {
      margin-right: 5px;
      margin-top: 0;
      height: 16px;
    }
  }
}

.form__section__body {
  padding-top: 20px;
}

.traq-volume-sort-calculation {
  &-wrapper {
    padding-top: $paddingGeneric;
  }
}

.traq-percentage-sort-calculation {
  &-wrapper {
    padding-top: $paddingGeneric;
  }

  .template {
    display: flex;

    &__text {
      background: $white;
      border: 1px solid $hoveredMenu;
      border-radius: $borderRadius;
    }
  }
}

.property-settings-type {
  margin-bottom: 16px;
}

.dropdown-row {
  position: relative;
  &__loader {
    position: absolute;
    left: calc(100% + 30px);
    top: 50%;
    transform: translateY(-50%);
    img {
      height: 60px;
      width: 60px;
    }
  }
}
