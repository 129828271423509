@import '../../styles/constants.scss';

.searchable-section {
  background-color: $sectionBackground;
  border-radius: $borderRadius;
  box-shadow: $sectionBoxShadow;
  padding: 0 32px;

  & + & {
    margin-top: 20px;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 24px;

    &__actions {
      display: inline-flex;
      gap: 8px;
    }

    h3 {
      font-size: $fontSize21;
      letter-spacing: $letterSpacing05;
      color: $inputColor;
      margin: 0;
      font-weight: normal;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;

    &__info {
      flex-basis: calc(25%);

      &__title {
        font-size: $fontSize17;
        font-weight: $fontBold;
      }

      &__select-all {
        color: $colorButton;
        cursor: pointer;
        &:hover {
          color: darken($colorButton, 10%);
        }
      }
    }

    &__body {
      display: flex;
      flex-wrap: wrap;
      flex-basis: calc(75% - 16px);
      flex-grow: 1;
    }

    &__info + &__body {
      .searchable-section__item {
        flex-basis: calc(100% / 3 - 16px);
        max-width: calc(100% / 3 - 16px);
      }
    }

    &__wrapper {
      & + & {
        padding-top: 16px;
        border-top: 1px solid $colorFlowBorders;
      }
    }
  }

  &__item {
    flex-basis: calc(25% - 16px);
    max-width: calc(25% - 16px);

    margin: 5px 8px;

    & .checkbox {
      &__label {
        display: flex;
      }
    }

    &__label {
      @include textEllipsis;

      .account-icon {
        margin-right: 8px;
        vertical-align: sub;
      }
    }

    &__show-more {
      color: $colorButton;
      cursor: pointer;
      padding-top: 16px;
      &:hover {
        color: darken($colorButton, 10%);
      }
    }
  }
}
