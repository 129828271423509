@import '../../styles/constants.scss';

.publisher-selection {
  display: flex;
  flex-direction: column;

  &__publishers {
    display: flex;

    &__element {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $sectionBackground;
      box-sizing: border-box;
      cursor: pointer;
      height: 50px;
      width: 50px;
      user-select: none;
      border-top: 1px solid $colorBorderSecondary;
      border-bottom: 1px solid $colorBorderSecondary;
      overflow: hidden;

      &:first-child {
        border-left: 1px solid $colorBorderSecondary;
        border-right: 1px solid $colorBorderSecondary;
        border-radius: $borderRadius;
        margin-right: 16px;
      }

      &:nth-child(2) {
        border-bottom-left-radius: $borderRadius;
        border-top-left-radius: $borderRadius;
      }

      &:not(:first-child) {
        border-left: 1px solid $colorBorderSecondary;
        border-bottom: 1px solid $colorBorderSecondary;
        border-top: 1px solid $colorBorderSecondary;
      }

      &:nth-last-child(2) {
        border-right: 1px solid $colorBorderSecondary;
        border-bottom-right-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
      }

      &.selected {
        border-left: 1px solid $colorButton;
        border-bottom: 1px solid $colorButton;
        border-top: 1px solid $colorButton;

        &:nth-child(1) {
          border-right: 1px solid $colorButton;
        }

        + .publisher-selection__publishers__element {
          border-left: 1px solid $colorButton;

          &:nth-child(2) {
            border-left: 1px solid $colorBorderSecondary;
          }
        }

        &:nth-last-child(2) {
          border-right: 1px solid $colorButton;
        }
      }

      & > svg {
        width: 25px;
      }

      &--global {
        margin-right: 20px;
      }

      &--disabled {
        color: $inputDisabledColor;
        opacity: 0.5;
      }
    }

    &__all-action {
      height: 50px;
      display: flex;
      align-items: center;
      margin-left: 16px;

      .checkbox {
        background-color: $colorButton;
        border-radius: $borderRadius;
        padding: 6px 16px;
        cursor: pointer;
      }

      .label-content-wrapper .label-wrapper {
        color: $white;
        font-size: $fontSize13;
        letter-spacing: $letterSpacing07;
      }

      .checkbox .check {
        border: 2px solid $white;
      }
    }
  }

  .tooltip {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
    padding: 10px 15px;
  }
}
