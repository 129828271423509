@import '../../styles/constants';

.recurrence {
  display: flex;
  align-items: center;
  font-size: $inputFontSize;
  .label-wrapper {
    font-weight: 600;
    letter-spacing: $letterSpacing02;
  }

  .drop-down {
    max-width: unset;
    flex-grow: 1;
    &:last-of-type {
      margin-left: 30px;
    }

    .label-wrapper {
      display: none;
    }
  }

  &__at {
    margin-left: 30px;
    margin-right: 10px;
    display: block;
  }

  &__time {
    width: 58px;
  }

  &__amount {
    max-width: 220px;
    position: relative;
    .wrapper__bottom {
      position: absolute;
    }
    .input-wrapper input[type='number'] {
      border-bottom: none;
      &::placeholder {
        color: $inputDisabledColor;
      }

      &.error-placeholder::placeholder {
        color: $red;
      }
    }
  }

  &__general {
    font-size: 0.875rem;
    position: absolute;
    right: 30px;
    bottom: 11px;
  }

  &__ago {
    margin-left: 20px;
    font-weight: $fontBold;
  }

  &__type {
    margin-right: 40px;
  }

  & &__frequency {
    max-width: 224px;
  }

  &--group {
    .input-group {
      &__elements {
        height: 48px;
      }
      &__element > div {
        padding: 12px 16px;
      }

      .input-wrapper {
        .select {
          padding: 16px 12px;
        }
        input {
          padding: 0;
          min-height: 24px;
          height: 24px;
        }
      }
    }
  }
}
