$spacing: 16px;
$editorContainerHeight: 500px;
$toolbarHeight: 42px;

.quill-toolbar__divider {
  user-select: none;
  filter: opacity(0.5);
  margin: 0 calc(#{$spacing} / 2);
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0;

  + .ql-formats {
    margin-right: $spacing;
  }
}

.editor-container {
  height: $editorContainerHeight;
}

.quill {
  height: calc(100% - #{$toolbarHeight});
}

.editor-container.error {
  margin-top: 0;

  .ql-container.ql-snow, .ql-toolbar.ql-snow  {
    border-color: red;
  }
}

.ql-snow .ql-tooltip {
  transform: translate(50px, 10px);
}

.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  margin: revert;
  padding: revert;
}
