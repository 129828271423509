@import '../../../styles/constants';

.active-alert-triggers {
  position: relative;

  .heading {
    margin-bottom: 25px;
  }

  .columns {
    display: flex;
    flex-direction: row;

    .column {
      width: calc(33% - 24px);

      &:not(:last-child) {
        border-right: 1px solid $colorFlowBorders;
        padding-right: 20px;
      }

      &:not(:first-child) {
        padding-left: 20px;
      }

      &--active .column__header,
      &--active .alert-trigger-card__header {
        background-color: $red;
      }

      &--acknowledged .column__header,
      &--acknowledged .alert-trigger-card__header {
        background-color: $colorButton;
      }

      &--completed .column__header,
      &--completed .alert-trigger-card__header {
        background-color: $colorConfirm;
      }

      &__header {
        padding: 8px 16px;
        margin-bottom: 10px;
        background-color: $hoveredMenu;
        border-radius: $borderRadius;
        text-transform: uppercase;
        font-weight: bold;
        font-size: $fontSize11;
        color: $white;
      }

      &--drop {
        border: 2px dashed $hoveredMenu;
        border-radius: $borderRadius;

        &:not(:last-child) {
          border: 2px dashed $hoveredMenu;
        }
      }

      .no-data {
        font-size: $fontSize17;
        font-style: italic;
      }

      .three-q-loader {
        height: unset;
      }
    }
  }
  .loader-footer-bottom {
    position: sticky;
    bottom: 0;
  }
  .alert-trigger-card {
    border-radius: $borderRadius;
    background-color: $sectionBackground;
    box-shadow: $sectionBoxShadow;

    &--disabled {
      .alert-trigger-card {
        &__header {
          background-color: $cardDisabled;
        }
        &__body {
          background-color: $colorBorder;
        }
      }
    }

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 6px 10px 6px 16px;
      border-radius: $borderRadius $borderRadius 0 0;
      cursor: pointer;
      text-decoration: none;

      .title {
        margin: auto auto auto 0;
        color: $white;
        font-size: $fontSize11;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-transform: uppercase;
        text-overflow: ellipsis;
        letter-spacing: $letterSpacing05;
        width: 100%;
      }
    }

    &__body {
      padding: 18px 16px 16px;

      &__info-row {
        display: flex;
        border-bottom: 1px solid #eaeaea;
        width: 100%;
        padding: 10px 0;

        &.description {
          padding-top: 0;
        }

        &.last {
          border-bottom: none;
          margin-bottom: 5px;
        }

        &__label {
          text-transform: uppercase;
          letter-spacing: $letterSpacing05;
          font-size: $fontSize11;
          color: $alertResultsColor;
          min-width: 110px;
          margin: 0 15px auto 0;
          white-space: nowrap;

          &.description {
            font-weight: bold;
            color: $alertResultsColor;
            white-space: pre-wrap;
          }
        }

        &__detail {
          color: $alertResultsColor;
          font-weight: $fontBold;
          font-size: $fontSize14;
          margin: auto 0 auto auto;
          text-align: right;
        }
      }
    }

    &__status {
      p {
        text-transform: uppercase;
        font-size: $fontSize11;
      }

      .dropdown-multi-color {
        &__face {
          padding: 8px 35px 8px 12px;
        }

        &__icon {
          right: 3px;
        }
      }
    }
  }

  .label {
    font-size: $fontSize11;
    text-transform: uppercase;
    font-weight: bold;
    color: $inputPlaceholderColor;
  }

  .filters {
    .label {
      color: $textColor;
    }
  }
  .alert-trigger-card + .alert-trigger-card {
    margin-top: 20px;
  }

  .load-more {
    margin: 0 auto;
    padding: 12px 37px;

    &__wrapper {
      position: absolute;
      right: calc(-#{$paddingRightBody});
      bottom: -80px;
      padding-right: $paddingRightBody;
      padding-left: 56px;
      width: 100%;
      height: 50px;

      &--hidden {
        display: none;
      }
    }
  }

  .filters {
    .label {
      margin-bottom: 6px;
    }

    &-date {
      display: flex;
      flex-direction: row;

      &__col {
        flex: 1 1 0;

        &:last-child {
          margin-left: 8px;
        }
      }
    }

    .drop-down {
      margin: 0 0 20px 0;
    }

    .wrapper {
      margin-bottom: 20px;
    }

    &-toggle {
      right: -75px;
      top: -20px;
    }
  }
}
