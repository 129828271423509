@import '../../styles/constants';

.list-select {
  background: $sectionBackground;
  box-shadow: $sectionBoxShadow;
  border-radius: $borderRadius;
  padding: 20px 32px 30px;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-toggle {
    height: 8px;
    width: 12px;
    cursor: pointer;
    margin: 10px;

    &--invert {
      transform: rotate(180deg);
    }
  }

  &__body {
    display: flex;
    flex-direction: row;

    &--hide {
      display: none;
    }
  }

  &__side {
    width: calc(50% - 32px);

    &-group {
      border-radius: $borderRadius;
      border: 1px solid $colorFlowBorders;
    }
  }

  &__search {
    display: flex;
    flex-direction: row;
    padding: 6px 16px;
    border-bottom: 1px solid $colorFlowBorders;

    .wrapper {
      width: 100%;
    }
  }

  &__list {
    height: 300px;
    overflow: auto;
    list-style-type: none;
    margin: 0;
    padding: 7px 16px;

    &-item {
      cursor: pointer;
      height: 30px;

      &:hover {
        background: $cardDisabled;
      }

      &--staged {
        background: $colorButton;
      }
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-button {
      cursor: pointer;
      background: $colorButton;
      height: 40px;
      width: 40px;
      border-radius: $borderRadius;
      margin: 5px 12px;

      &--disabled {
        background: $cardDisabled;
      }
    }

    &-icon {
      width: 25px;
      margin: 9px auto;
      display: block;

      &--reversed {
        transform: rotate(180deg);
      }
    }
  }
}
