@import '../../styles/constants';

.bu {
  &__account {
    &__name {
      .table__icons {
        padding-right: 10px;
      }
    }
  }
  &__assign {
    &__button {
      margin-left: 16px;
      div {
        align-items: center;
        justify-content: space-between;
      }
    }
    &__icon {
      transform: rotate(180deg);
    }
  }
  &__assign-modal {
    .modal__content {
      width: 540px;
      max-width: 80vw;
      margin-bottom: 100px;
    }
    .modal__heading {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 21px;
    }
    .kpi-list {
      padding-top: 8px;
      max-height: 50vh;
      overflow: auto;
    }
    .select__options {
      max-height: 410px;
    }
    .checkbox__label {
      gap: 2px;
    }
    .check,
    .checkbox__icon {
      align-self: baseline;
    }
  }
}
