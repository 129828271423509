@import '../../styles/constants';

.dropdown-tree {
  background: $white;
  color: $textColor;
  border: 1px solid $colorButton;
  border-radius: $borderRadius;
  font-weight: bold;
  font-size: $fontSize15;
  position: relative;
  cursor: pointer;

  &__wrapper {
    &:active,
    &:focus {
      outline: none;
    }
  }

  &--disabled {
    cursor: default;
    border-color: $colorPrimaryDisabled;
  }

  &__label {
    position: relative;
    padding: 2px 5px 2px 5px;
    display: flex;
    min-height: 20px;
  }

  &__icon,
  &__option__icon {
    width: 12px;
    object-fit: contain;
    margin-left: auto;

    &--up {
      transform: rotate(180deg);
    }
  }

  &__text {
    margin: 0 10px 0 0;
  }

  &__options {
    position: absolute;
    border-radius: $borderRadius;
    background: $white;
    top: 45px;
    right: 0;
    box-shadow: $sectionBoxShadow;
    min-width: 100%;
    max-height: $dropdownHeight;
    overflow: auto;

    &--up {
      top: unset;
      bottom: calc(100% + 5px);
    }
  }

  &__option {
    cursor: pointer;

    .dropdown-tree__option {
      padding-left: 20px;
      position: relative;
      &:before {
        height: 100%;
        width: 10px;
        content: '';
        position: absolute;
        border-left: 1px solid #d9d9d9;
        border-bottom: 1px solid #d9d9d9;
        top: -50%;
        left: 10px;
      }

      &:nth-child(2):before {
        height: 50%;
        top: 0;
      }
    }

    &__label {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      &:hover {
        background: $inputHover;
      }
    }

    &__icon {
      width: 12px;
      object-fit: contain;
      margin-left: auto;
      transform: rotate(-90deg);
      &--down {
        transform: rotate(0deg);
      }
    }

    &--opened {
      > .dropdown-tree__option__label {
        background: $blueHover;
      }
    }
  }
}
