@import '../../../styles/constants';

.archived-alert-triggers {
  .label {
    font-size: $fontSize11;
    text-transform: uppercase;
    font-weight: bold;
    color: $inputPlaceholderColor;
  }
  .filters {
    .heading {
      margin-bottom: 25px;
    }

    .label {
      margin-bottom: 6px;
      color: $textColor;
    }

    &-date {
      display: flex;
      flex-direction: row;

      &__col {
        flex: 1 1 0;

        &:last-child {
          margin-left: 8px;
        }
      }
    }

    .drop-down {
      margin: 0 0 20px 0;
    }

    .wrapper {
      margin-bottom: 20px;
    }
  }
}
