@import '../../styles/constants';

.account-component {
  position: relative;

  &-label {
    font-size: $fontSize21;
    margin-bottom: 15px;
  }

  &__content {
    flex-wrap: wrap;
    gap: 10px 16px;
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #fefefe;
    box-sizing: border-box;
    cursor: pointer;
    height: 57px;
    /* [a quarter of the space] - [right margin] (the last element does not have a right margin) */
    width: calc(25% - 13px);
    user-select: none;
    position: relative;
    border: 1px solid #bccbcc;
    border-radius: $borderRadius;
    overflow: hidden;

    &:nth-child(4n) {
      margin-right: 0;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      margin-top: 0;
    }

    &__name {
      color: #4d4d4d;
      font-weight: $fontBold;
      font-size: $fontSize15;
      border-left: 1px solid #bccbcc;
      height: 101%;
      border-radius: 0 3px 3px 0;
      flex-basis: 100%;
      display: flex;
    }

    &__text {
      margin: auto;
      text-align: center;
    }

    &-icon {
      height: 32px;
      width: 32px;
      padding: 12px;
      background: #fefefe;
      border-radius: $borderRadius 0 0 $borderRadius;
      flex-basis: 40px;
    }

    &--selected {
      border: 2px solid $colorButton;

      .account-component__item__name {
        background: $blueHover;
        border-left: 1px solid $colorButton;
        padding-left: 1px;
      }

      .account-component__item-icon {
        padding-left: 11px;
      }
    }

    &--disabled {
      pointer-events: none;

      .account-component__item__name {
        color: #a1a1a1;
      }

      .account-component__item-icon {
        opacity: 0.5;
      }
    }

    &--big {
      flex-direction: column;
      height: 100%;
      width: 136px;
      cursor: default;

      .account-component__item-icon {
        width: 48px;
        height: 48px;
        flex-basis: 60px;
        padding: 26px 43px 12px;
        border: none;
      }

      .account-component__item__name {
        text-transform: uppercase;
        flex-basis: unset;
        font-size: $fontSize11;
        border: none;
        height: 30px;
      }

      .account-component__item__text {
        margin: 0;
      }
    }

    &__loader {
      position: absolute;
      right: 4px;
      top: 4px;
      width: 40px;
      height: 40px;
      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
