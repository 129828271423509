@import '../../styles/constants.scss';

@function get-btn-color($variant, $shade: base) {
  @if map-has-key($buttonColorMap, $key: $variant) {
    $variantMap: map-get($buttonColorMap, $variant);
    @if map-has-key($map: $variantMap, $key: $shade) {
      $color: map-get($variantMap, $shade);
      @return #{$color};
    } @else {
      @error "ERROR: Specified color shade does not exist in the variant map";
    }
  } @else {
    @error "ERROR: Specified color variant does not exist in the button colors map";
  }
}

@mixin btn-color($variant, $secondary: false) {
  @if map-has-key($buttonColorMap, $variant) {
    $variantMap: map-get($buttonColorMap, $variant);
    @if ($secondary == false) {
      color: map-get($variantMap, contrast);
      background-color: map-get($variantMap, base);

      svg path {
        fill: map-get($variantMap, contrast);
      }

      &:hover,
      &:focus {
        color: map-get($variantMap, contrast);
        background-color: map-get($variantMap, hover);
        border: 1px solid map-get($variantMap, hover);

        svg path {
          fill: map-get($variantMap, contrast);
        }
      }

      &:active,
      &--active {
        color: map-get($variantMap, contrast);
        background-color: map-get($variantMap, active);
        border: 1px solid map-get($variantMap, active);

        svg path {
          fill: map-get($variantMap, contrast);
        }
      }

      &[disabled] {
        color: map-get($variantMap, contrast);
        background-color: map-get($variantMap, disabled);
        border: 1px solid map-get($variantMap, disabled);

        svg:not(.icon-overload-disabled) path {
          fill: map-get($variantMap, contrast);
        }
      }
    } @else {
      color: map-get($variantMap, base);
      background-color: $white;

      svg path {
        fill: map-get($variantMap, base);
      }

      &:hover,
      &:focus {
        color: map-get($variantMap, hover);
        background-color: $white;
        border: 1px solid map-get($variantMap, hover);

        svg path {
          fill: map-get($variantMap, hover);
        }
      }

      &:active,
      &--active {
        color: map-get($variantMap, active);
        background-color: $white;
        border: 1px solid map-get($variantMap, active);

        svg path {
          fill: map-get($variantMap, active);
        }
      }

      &[disabled] {
        color: map-get($variantMap, disabled);
        background-color: $white;
        border: 1px solid map-get($variantMap, disabled);

        svg:not(.icon-overload-disabled) path {
          fill: map-get($variantMap, disabled);
        }
      }
    }

    border: 1px solid map-get($variantMap, base);
    transition: all 300ms;
  } @else {
    @error "ERROR: Specified color variant does not exist in the button colors map";
  }
}

.button {
  padding: 0;
  @include btn-color(primary);
  border-radius: $borderRadius;
  box-sizing: border-box;
  color: $lightTextColor;
  cursor: pointer;
  outline: none;
  transition: border 0.5s, color 0.5s, background 0.5s;
  -webkit-appearance: none;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
  font-family: inherit;
  font-size: 0.9375rem;
  line-height: 1rem;

  &__value {
    font-weight: bold;
    padding: 10px 20px;

    &--compact {
      padding: 8px 12px;
      color: currentColor;
      font-size: $fontSize11;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      display: block;
    }
  }

  &:focus,
  &:active,
  &:visited,
  &:hover {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    outline: none;
  }

  &[disabled] {
    @include disabled(not-allowed);
    // Needed for tooltip to work
    pointer-events: auto;
    opacity: 1;
  }

  &.full-width {
    width: 100%;
  }

  &--round {
    border-radius: 20px;
  }
  &--round &__value {
    padding: 4px 10px;
    color: $textColor;
  }

  &--big {
    max-height: 48px;
  }

  &--small {
    max-height: 30px;
  }

  &--big & {
    &__value {
      padding: 16px 14px;
      font-size: 1.125rem;
    }
  }

  &--green {
    @include btn-color(confirm);
  }

  &--red {
    @include btn-color(attention);
  }

  &--orange {
    @include btn-color(warning);
  }

  &--liliac {
    @include btn-color(simulation);
  }

  &--gray {
    @include btn-color(cancel);
    color: $textColor;

    &:hover {
      color: $textColor !important;
    }

    &:active {
      color: $textColor;
    }

    &[disabled] {
      color: $inputDisabledColor;
    }
  }

  &--secondary {
    @include btn-color(primary, true);

    &-red {
      @include btn-color(attention, true);
    }

    &-gray {
      @include btn-color(cancel, true);
      color: $textColor;

      &:hover {
        color: $textColor;
      }

      &:active {
        color: $textColor;
      }

      &[disabled] {
        color: $inputDisabledColor;
      }
    }

    &-green {
      @include btn-color(confirm, true);
    }

    &-orange {
      @include btn-color(warning, true);
    }

    &-liliac {
      @include btn-color(simulation, true);
    }
  }

  &--loading & {
    &__value {
      padding-right: 5px;
    }
  }

  &.align-right {
    margin-left: auto;
  }

  &--icon {
    width: 40px;
    height: 40px;

    & + & {
      margin-left: 4px;
    }
  }

  &--icon &__value {
    padding: 0;
    height: 20px;
    width: 20px;

    img,
    svg {
      width: 20px;
      height: 20px;
    }
  }

  &--dark {
    background-color: $backgroundDark;
    color: $white;

    &[disabled] {
      background-color: $backgroundDark;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: rgba($white, 5%);
    }

    .button__value--compact {
      color: $white;
    }
  }

  &--compact[class*='secondary'] {
    color: $black;

    &:hover,
    &:active,
    &:focus {
      color: $black;
    }

    &[disabled] {
      color: $inputDisabledColor;

      &:hover,
      &:active,
      &:focus {
        color: $inputDisabledColor;
      }
    }
  }

  &--dark & &--compact[class*='secondary'] {
    color: $black;

    &:hover,
    &:active,
    &:focus {
      color: $black;
    }
  }
}
