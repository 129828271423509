@import '../../styles/constants.scss';

@mixin dropdownIcon {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 0;
  svg {
    width: 100%;
    height: 100%;
  }
}

.select {
  display: flex;
  align-items: center;
  background-color: $inputBackground;
  border: 1px solid $colorBorder;
  border-radius: $borderRadius;
  box-sizing: border-box;
  color: $inputColor;
  font-size: $inputFontSize;
  font-family: inherit;
  min-height: 48px;
  position: relative;
  padding: 12px 26px 12px 15px;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  word-break: break-word;
  -webkit-appearance: none;

  .hidden {
    visibility: hidden;
    width: 0;
    height: 0;
    margin: 0;
  }

  .black-color {
    color: $black;
  }

  // Styling for the arrow
  &::after {
    content: '';
    position: absolute;
    right: 8px;
    width: 10px;
    height: 8px;
    background: $inputColor;
    mask: url('../../assets/icon_polygon_down.svg') no-repeat 50% 50%;
    background-repeat: no-repeat;
  }

  &--loading,
  &--hasWarning {
    &::after {
      content: none;
    }
  }

  &__loader,
  &__warning {
    @include dropdownIcon();
  }

  &__loader + &__warning {
    display: none;
  }

  &__search {
    margin: 0;
    border: 0;
    padding: 0 20px 0 0;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    background: inherit;
    line-height: inherit;
    color: $inputColor;
    font-size: $inputFontSize;
    width: 100%;
    box-sizing: border-box;
    &:focus {
      outline: none;
    }

    &--bottom {
      position: relative;
      height: 44px;
      display: flex;
      align-items: center;

      input {
        padding-left: 46px;
        font-size: $inputFontSize;
        font-family: inherit;
        text-indent: 25px;
        &::placeholder {
          color: $inputPlaceholderColor;
          font-size: inherit;
        }
      }

      .search-icon {
        position: absolute;
        left: 15px;
      }
    }
  }

  &__separator {
    height: 1px;
    margin: 0;
    width: 100%;
    background: $colorFlowBorders;

    &--with-deselect {
      margin: 0;
      width: auto;
    }
  }

  &__dropdown-section-label {
    color: #a1a1a1;
    font-size: 0.6875rem;
    letter-spacing: 0.07em;
    line-height: 1rem;
    text-transform: uppercase;
    padding: 0 20px;
  }

  &__group {
    position: sticky;
    top: 0;
    z-index: $zIndexDefault;
    display: flex;
    align-items: center;
    gap: 12px;
    border-top: 1px solid $colorFlowBorders;
    border-bottom: 1px solid $colorFlowBorders;
    background-color: $sectionBackgroundDarker;
  }

  &__options {
    display: none;
    width: 100%;
    position: absolute;
    top: calc(100% + 5px);
    left: -1px;
    border: 1px solid transparent;
    border-radius: $borderRadius;
    background-color: $sectionBackground;
    box-shadow: $flowBoxShadow;
    max-height: $dropdownHeight;
    overflow: auto;
    overflow-anchor: none;
    cursor: pointer;
    &--open {
      display: block;
      overflow-y: auto;
      visibility: visible;
    }
    &--up {
      top: unset;
      bottom: calc(100% + 5px);
      visibility: visible;
    }
    div[role='option'] {
      padding: 8px 16px;
      line-height: 1;

      &.sorted-to-top {
        border-bottom: 1px solid $colorFlowBorders;
      }
      &.highlighted {
        background: $inputHover;
      }
      &.unselectable {
        opacity: 0.5;
      }
      &.hidden {
        padding: 0;
        &.unselect {
          display: none;
        }
      }
      &:hover {
        background: $inputHover;
      }
      &.hasLabel {
        padding-top: 30px;
        position: relative;
      }
      .checkbox .label-wrapper {
        font-size: 1rem;
        width: 100%;
      }
    }
  }

  &__no-results {
    padding: 8px 16px;
    opacity: 0.9;
    cursor: default;
  }

  &__section-label {
    position: absolute;
    top: 6px;
    font-size: $fontSize11;
    opacity: 0.7;
  }

  &__description {
    font-size: $fontSize14;
    color: $hoveredMenu;
    margin-top: 6px;
    white-space: pre-wrap;
  }

  &--opened .select__options {
    z-index: $zIndexOverlay;
  }

  &--disabled {
    color: $inputDisabledColor;

    &::after {
      filter: opacity(0.35);
    }
  }

  &--no-options {
    color: $inputDisabledColor;

    &::after {
      filter: opacity(0.35);
    }
  }

  .checkbox__label {
    flex-direction: row-reverse;
    .label-wrapper {
      // allow longer labels fit in two rows
      width: calc(100% - 19px);
      text-transform: capitalize;
      font-weight: normal;
    }
  }

  .dropdown--bold {
    font-weight: bold;
  }

  .dropdown--pointer {
    pointer-events: none;
    overflow: auto;
  }

  .dropdown--placeholder {
    color: $inputPlaceholderColor;

    &--error {
      color: $red;
    }
  }

  .dropdown--icon {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  input[type='checkbox'] {
    margin: 0 15px 0 0;
  }

  &__toggles {
    border-bottom: 1px solid $colorFlowBorders;

    .checkbox-select-all--blue.checkbox-select-all--none
      .checkbox-select-all__icon
      svg
      path {
      fill: $colorSecondaryText;
    }

    .checkbox-select-all {
      gap: 12px;

      &__icon {
        width: unset;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      &__label {
        @include checkBoxLabel;
      }
    }
  }

  &__tiles {
    display: flex;
    flex-wrap: wrap;
  }

  &__tile {
    background: $grey;
    margin: 5px 5px 5px 0;
    padding: 10px 15px;
    border-radius: 18px;
    color: $white;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1rem;
    display: flex;
    img {
      margin-left: 10px;
      cursor: pointer;
    }
  }
}

.drop-down {
  // Button-like dropdown, that doesn't select the element, just uses it as a means to click
  &--button {
    .dropdown--placeholder {
      color: $white;
    }
    .select {
      background-color: $colorButton;
      width: auto;
      padding-right: 40px;
      color: $white;
      &--disabled {
        background-color: #abe1e8;
      }
      &::after {
        background: $white;
      }

      .dropdown--placeholder {
        color: $white;
      }
    }
    .select__options {
      color: $inputColor;
    }
  }

  &--dark {
    .select {
      background: $colorCustomGrey;
      border-radius: 4px;
      color: $white;
      border: 1px solid $colorCustomGrey;

      &::after {
        background: $white;
      }
      &__options {
        color: $white;
        background: $reportsBackground;

        div[role='option'] {
          &.highlighted {
            background: $colorButton;
          }

          &:hover {
            background: $colorButtonHover;
          }
        }
      }
    }
  }

  &--empty {
    &.drop-down--white {
      .select {
        border: 1px solid $colorSecondaryText;

        &--disabled {
          border: 1px solid $colorFlowBorders;
          color: $inputDisabledColor;

          .dropdown--placeholder {
            color: $inputDisabledColor;
          }
        }
      }
    }
  }
  &--white {
    .select {
      background: $white;
      border-radius: 4px;
      color: $textColor;
      border: 1px solid $colorButton;
      font-size: $fontSize15;
      min-height: 42px;
      padding: 10px 8px;

      &::after {
        background: $colorSecondaryText;
      }
      .dropdown--placeholder {
        color: $textColor;
        opacity: 1;
      }
      &__toggles {
        border-bottom: 1px solid $colorFlowBorders;
      }
      &--opened {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        &-upwards {
          border-radius: $borderRadius;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
      &__options {
        box-shadow: none;
        color: $textColor;
        background: $white;
        border: 1px solid $colorSecondaryText;
        top: 100%;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        div[role='option'] {
          padding: 8px 8px;
        }

        .select__search--bottom {
          height: 34px;

          input {
            padding-left: 20px;
          }
        }

        &--up {
          bottom: 100%;
          top: unset;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        div[role='option'] {
          &.highlighted {
            background: $greyBackgroundColor;
          }

          &:hover {
            background: $blueHover;
          }
        }
        div[role='option'] .checkbox .label-wrapper {
          font-size: $fontSize15;
          width: 100%;
        }
      }

      &--disabled {
        border: 1px solid $colorFlowBorders;
        color: $inputDisabledColor;

        .dropdown--placeholder {
          color: $inputDisabledColor;
        }
      }
    }
  }

  &--sorted {
    .select {
      &__options {
        div[role='option'].selected + div[role='option']:not(.selected) {
          border-top: 1px solid $colorFlowBorders;
        }
      }
    }
  }

  &.fake-disabled {
    .select--disabled {
      &::after {
        background: transparent;
      }

      color: $inputColor;
    }
  }
  &.selected .select {
    border: 1px solid $colorButton;
  }

  &--conversion {
    .select__options {
      min-width: 640px;
      div[role='option'] {
        padding: 8px;
      }
    }
  }

  &--to-right {
    .select__options {
      right: -1px;
      left: auto;
    }
  }

  &--with-error-block {
    .error-wrapper {
      position: relative;
      border: 1px solid;
      border-radius: 3px;
      padding: 8px;
      bottom: 0;
      right: 0;
      width: fit-content;
      &:before {
        content: ' ';
        display: block;
        position: relative;
        background: url('../../assets/icon_warning_round_red.svg') no-repeat;
        width: 20px;
        height: 20px;
        padding-right: 5px;
        margin-right: 5px;
        border-right: 1px solid;
      }
    }
  }
}

.dark-tooltip {
  background: $colorCustomGrey;
  box-shadow: $flowBoxShadow;
  color: $white;
  font-size: $fontSize13;
  padding: 10px;
}

.white-space-normal {
  .text-ellipsis {
    white-space: normal;
  }
}
