@import '../../../styles/constants';

.alert-trigger-details {
  position: relative;

  .loading__overlay {
    height: calc(100vh - 56px);
  }

  .form__section {
    + .form__section {
      margin-top: 30px;
    }

    .form__section__header {
      margin-bottom: 0;
    }
  }

  &__status {
    position: absolute;
    top: 0;
    right: 0;
  }

  .information {
    display: flex;

    &__section {
      background: $sectionBackground;
      padding: 20px 32px;
      margin: 0 8px 0 0;
      width: calc(100% - 16px);
      border-radius: $borderRadius;
      box-shadow: $sectionBoxShadow;

      &:nth-child(2) {
        margin: 0 0 0 8px;
      }
    }
  }

  .label {
    font-size: $fontSize11;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    font-weight: bold;
  }

  .detail {
    font-size: $fontSize17;
    letter-spacing: 0.03em;
    font-weight: 400;
    margin: 4px 0 19px;
    color: $inputColor;
  }

  .th {
    font-size: $fontSize11;
    text-transform: uppercase;
    font-weight: bold;
    padding-left: 16px;
  }

  .td {
    padding: 16px;
  }

  .heading-secondary {
    margin-top: 20px;
  }

  &__entity-table {
    &-dropdown {
      z-index: $zIndexFixedElements;

      .dropdown-tree {
        &__label {
          padding: 0 12px;
        }
        &__text {
          font-size: $fontSize11;
          text-transform: uppercase;
        }
        &__option__label {
          padding: 0 10px;
        }
      }
    }
    .table__header {
      height: 32px;
    }

    .table__cell {
      .label-wrapper {
        margin-bottom: 0;
      }
    }

    &__details {
      padding: 8px 0;
    }
  }

  &__dropdown {
    .radio.blue .label-wrapper {
      margin: 5px 0;
    }
    .label-wrapper__label__text {
      display: flex;
      svg {
        margin-right: 10px;
      }
    }
  }
}
