@import '../../styles/constants';

.login-remember {
    display: flex;
    flex-direction: column;

    .align-row {
        margin-top: 0;
    }

    .three-q-loader {
        width: unset;
    }
}