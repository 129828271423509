@import '../../../../styles/constants.scss';

.budget-pacing {
  .budget-pacing-section {
    background: $sectionBackground;
    border-radius: $borderRadius;
    box-shadow: $sectionBoxShadow;
    margin-bottom: 20px;

    .dimension-input-groups {
      padding: 20px;
      border: 1px solid $colorFlowBorders;
      border-radius: 4px;
      margin-top: 15px;

      &__labels {
        display: grid;
        grid-template-columns: 300px minmax(0px, 1fr) 50px 45px;
      }

      .dimension-input-group .disabled {
        opacity: 0.5;
      }

      .dropdown-preview-selected-list__item {
        padding: 10px;
      }

      .dropdown-preview-selected {
        .tag-container {
          margin-bottom: 0;
          z-index: $zIndexDefault;
        }

        .checkbox--checked .check .image-icon {
          background-color: $white;
        }
      }
      .dropdown-with-subsections {
        .select__options--open {
          border-color: $hoveredMenu;
          border-radius: 0;
        }
        .select__subdivision__main {
          opacity: 1;
          display: flex;
          align-items: flex-start;
          border-top: 1px solid $colorFlowBorders;
          border-bottom: 1px solid $colorFlowBorders;
        }
        .select__subdivision__item {
          font-size: $fontSize15;
        }
      }
      .checkbox__label {
        display: flex;
        align-items: center;
        .checkbox__icon {
          display: flex;
          align-items: center;
        }
      }
      .tag-with-icon {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    .input-wrapper--error input:not(.select__search),
    .input-wrapper--error textarea,
    .input-wrapper--error .select {
      border: unset !important;
    }

    .budget-table-overflow {
      max-height: 520px;
    }

    &__title {
      font-size: $fontSize21;
      font-weight: 400;
    }

    .entity-selector__filters {
      display: grid;
      padding: 20px 30px;
      grid-template-columns: repeat(4, 1fr);
      border-bottom: none;
    }

    .entity-field:nth-child(1) {
      grid-area: 1 / 1 / 2 / 3;
    }

    .entity-field:nth-child(2) {
      grid-area: 1 / 3 / 2 / 4;
    }

    .entity-field:nth-child(3) {
      grid-area: 1 / 4 / 2 / 5;
    }

    .entity-field:nth-child(4) {
      grid-area: 2 / 1 / 3 / 2;
    }

    .entity-field:nth-child(5) {
      grid-area: 2 / 2 / 3 / 3;
    }

    .entity-field:nth-child(6) {
      grid-area: 2 / 3 / 2 / 5;
    }

    .entity-selector__actions {
      padding: 20px 30px;
    }

    .input-group__elements .error-border:first-child {
      border: 1px solid $red;
    }

    .input-group__element > div {
      padding: unset;
    }

    .budget-prefix {
      .wrapper__prefix input {
        padding-left: 27px;
      }
    }

    .budget-information-block {
      width: fit-content;
    }

    .budget-button-value {
      height: 33px;
      background: $white;
      border: 1px solid $colorButtonGrey;
      color: $textColor;
      padding: 1px;
      transition: none;

      .button__value {
        padding: 8px 12px;
      }

      .button--secondary svg path {
        fill: $white;
      }
    }

    .budget-actions {
      display: flex;
      align-items: center;

      &__buttons {
        margin-left: auto;
        display: flex;
        align-items: center;

        .button__value {
          padding: 8px 12px;
        }
      }

      .information-block {
        margin-top: 0;
        margin-right: 20px;
        width: auto;
      }
    }

    .button-selected {
      border: 1px solid $colorButton;
      padding: 0;

      &[disabled] {
        border: 1px solid $colorPrimaryDisabled;
        opacity: 0.7;
      }

      &--active {
        border: 2px solid $colorButton;
        background: $blueHover;
      }
    }

    .budget-button-label {
      font-size: $fontSize11;
      text-transform: uppercase;
      margin: auto 0 auto 10px;
      font-weight: $fontBold;
      letter-spacing: $letterSpacing05;
    }
  }

  .activity-log {
    &__selector {
      background: $sectionBackground;

      .pagination-wrapper {
        margin: 15px 20px;
        border-radius: $borderRadius;
      }
    }

    .entity-selector__actions {
      padding-left: 30px;

      .button {
        text-transform: uppercase;
        font-size: 13px;
      }
    }

    .table {
      .td {
        height: unset;
        min-height: 48px;
      }
    }

    .collapsible-section__heading {
      height: 47px;
      background: $sectionBackground;
      border-radius: $borderRadius;
      box-shadow: $sectionBoxShadow;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom: 1px solid $colorFlowBorders;
      padding: 10px 30px;

      h3 {
        font-size: $fontSize21;
      }
    }

    .collapsible-section__body {
      background: $sectionBackground;
    }

    .entity-selector__filters {
      padding-left: 30px;
      border-bottom: none;
    }

    .conversion-row {
      .item-wd__accounts {
        padding: 0;
      }
    }
  }

  .activity-log,
  .budget-pacing-section {
    .entity-selector__extra-actions {
      justify-content: flex-start;
    }

    .entity-selector__actions {
      .button {
        text-transform: uppercase;
        font-size: 13px;
      }
    }

    .clear-filters {
      background-color: $white;
      border: 1px solid $red;
      color: $red;

      .fill-red {
        path {
          fill: $red;
        }
      }
    }

    .dropdown-preview-selected-list__item {
      .checkbox {
        height: fit-content;
      }
    }

    .include-inactive-campaigns {
      display: flex;
      align-items: center;
      margin-left: 8px;

      &__info {
        svg path {
          fill: $colorButton;
        }
      }

      &__label {
        display: flex;
        gap: 5px;
        font-size: $fontSize15;
        margin-right: 8px;
      }

      .tooltip {
        padding: 0px;
        text-transform: none;

        .icon-container {
          padding-left: 10px;
        }

        .last-paragraph {
          margin-top: 0px;
        }
      }

      &__tooltip {
        max-width: 450px;
        display: flex;
        gap: 16px;
        font-size: $fontSize13;
        font-weight: $fontBold;

        svg path {
          fill: $colorWarning;
        }
      }

      &__modal {
        .modal__content {
          width: 510px;

          .first-paragraph,
          .last-paragraph {
            margin-top: 0px;
            margin-bottom: 0px;
          }
        }
      }

      .icon--warning {
        margin-right: 5px;
        margin-bottom: 3px;
      }
    }
  }

  .three-q-loader {
    position: relative;
  }

  .dropdown-preview-selected {
    &.select:not(.select--opened) {
      &::after {
        content: '';
      }
    }
    .dropdown-preview-selected-list__item {
      padding: 15px;
    }
    .dropdown-preview-selected-list__select-all {
      padding: 0 15px;
    }
  }

  &__bordered {
    border: 1px solid $colorBorderSecondary;
    border-radius: $borderRadius;
    display: flex;
    flex-wrap: wrap;

    .budget-pacing__inputs {
      flex-grow: 1;
      padding: 20px 24px;
    }

    .budget-pacing__remove {
      display: flex;
      align-items: center;
      padding: 8px;
      border-left: 1px solid $colorBorderSecondary;

      svg {
        cursor: pointer;
      }
    }
  }
}
.section-header {
  display: flex;
  gap: 10px;
  justify-content: center;
  .general-label {
    margin-bottom: 0;
    line-height: 1;
    font-size: $fontSize11;
  }
}
.round-button {
  border-radius: 50%;
  width: 10px;
  height: 10px;

  &--blue {
    background-color: $publishingStatus;
  }
  &--green {
    background-color: $publishedStatus;
  }
  &--purple {
    background-color: #bd9ee6;
  }
}
