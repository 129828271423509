@import '../../../../../../styles/constants.scss';

.excluded-campaigns-filter {
  &__row {
    display: flex;
    max-width: 100%;
    gap: 80px;

    & + & {
      margin-top: 15px;
    }

    & > * {
      flex-basis: calc(50% - 40px);
      max-width: calc(50% - 40px);

      flex-shrink: 0;
      flex-grow: 0;
    }
  }
}
