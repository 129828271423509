.date-time {
  display: flex;
  align-items: center;

  > div {
    display: inline-flex;
  }

  .input-wrapper .wrapper__prefix {
    height: 100%;
  }
}
