// Define re-usable constants and media queries here

// Fonts
$fontFamily: 'Nunito', sans-serif;
$fontFamilySriracha: 'Sriracha';
$fontSize: 16px;
$fontSize10: 0.625rem;
$fontSize11: 0.6875rem;
$fontSize12: 0.75rem;
$fontSize13: 0.813rem;
$fontSize14: 0.875rem;
$fontSize15: 0.9375rem;
$fontSize16: 1rem;
$fontSize17: 1.0625rem;
$fontSize18: 1.125rem;
$fontSize20: 1.25rem;
$fontSize21: 1.3125rem;
$fontSize28: 1.75rem;
$fontSize38: 2.375rem;
$fontSize40: 2.5rem;
$fontBold: bold;
$fontWeight700: 700;

// Text
$textDefaultColor: #222222;
$textColor: #4d4d4d;
$lightTextColor: #fafafa;

// Border Radius
$borderRadius: 4px;
$borderRadiusTag: 20px;

// Colors
$white: #fff;
$black: #353535;
$red: #ef6767;
$grey: #808080;
$blueHover: #ebfdff;
$colorSuccess: #198819;
$colorAlert: #5c3bc2;
$colorBorder: #f1f1f1;
$colorButton: #00c1d5;
$colorButtonHover: #0aaebf;
$colorButtonGrey: #bccbcc;
$colorDisabled: #bfbfbf;
$colorSeparators: #c4c4c4;
$colorBackground: #e5e5e5;
$colorFlowBorders: #dbdbdb;
$colorBorderSecondary: #bccbcc;
$colorConfirm: #6fcf97;
$notificationSeparator: #ececec;
$cardDisabled: #d1d1d1;
$colorAltBackground: #e0e0e0;
$colorWarning: #fea655;
$colorCancel: #bccbcc;
$colorCardBackground: #e5f8fa;
$alertResultsColor: #4d4d4d;
$colorSecondaryText: #7a7c7c;
$colorPrimaryDisabled: #abe1e8;
$colorInfo: #dffcff;
$greyBackgroundColor: #f7f7f7;
$colorLinkBlue: #2d2ac9;
$colorCustomGrey: #252525;
$colorRedVibrant: #e85e5e;
$colorRedPastel: #fce7e7;
$colorItemSelected: #136068;
$colorCheckboxUnselected: #a2a2a2;
$colorYellowPastel: #fff8d5;
$colorBackgroundsDividersOutlines: #eefbff;
$colorLightBackground: #eff6f8;
$colorGreenMint: #5ec88a;
$colorLiliac: #5115f7;
$colorLiliacDisabled: #b9a1fc;

// Dark mode
$backgroundDark: #1f1f1f;

// Inputs
$inputFontSize: 1rem;
$inputBackground: #f1f1f1;
$inputHover: #fbf9f9;
$inputDisabledBackground: #f1f1f1;
$inputDisabledBackgroundDark: #d1d1d1;
$inputColor: #515353;
$inputDisabledColor: #a1a1a1;
$inputPadding: 12px 15px;
$inputBoxShadow: inset 3px 3px 5px 0 rgba(54, 54, 67, 0.2),
  inset -3px -3px 5px 0 rgba(255, 255, 255, 0.8);
$inputPlaceholderColor: #a1a1a1;

// Letter-spacing
$letterSpacing01: 0.1px;
$letterSpacing02: 0.2px;
$letterSpacing03: 0.3px;
$letterSpacing05: 0.5px;
$letterSpacing07: 0.7px;

// Other
$sectionBackground: #fefefe;
$sectionBackgroundDarker: #f6f6f6;
$sectionBoxShadow: 0 2px 4px rgba(0, 0, 0, 0.15);
$flowBoxShadow: 0 2px 7px rgba(0, 0, 0, 0.15);
$headerHeight: 56px;
$stickyFooterHeight: 70px;
$footerHeight: 57px;
$modalWidth: 520px;
$modalHeight: 620px;
$maxHeight: 320px;
$menuWidth: 56px;
$menuBackground: #606060;
$menuBackgroundLight: #969898;
$hoveredMenu: #7a7c7c;
$hoveredMenuItem: #f7f7f7;
$checkboxWidth: 16px;
$checkboxBorderWidth: 2px;
$checkboxContentSpacing: 11px;
$iconRemoveWidth: 32px;
$leftMenuClosedWidth: 112px;
$leftMenuOpenWidth: 256px;
$lefMenuTransitionTime: 0.3s;
$alertSettingsPropertiesIconWitdh: 48px;
$dropdownHeight: 200px;
$multiSelectDropdownHeight: 250px;
$avatarSize: 80px;
$publishingStatus: #449beb;
$publishedStatus: #6ac573;
$reportsBackground: #393939;
$reportsDropZoneActive: #606060;
$reportBorder: #1e1e1e;
$qaFormBasicWidth: 258px;
$dropShadowFilter: drop-shadow(0px 2px 4px rgba(21, 35, 36, 0.15));

// Paddings
$paddingGeneric: 32px;
$paddingRightBody: 88px;
$paddingSection: 15px 24px;

// Z-index
$zIndexDefault: 1;
$zIndexFixedElements: 2;
$zIndexLeftMenu: 3;
$zIndexOverlay: 4;
$zIndexOverOverlay: 5;
$zIndexHighest: 6;
$zIndexOverHighestOverlay: calc(#{$zIndexHighest} + 1);

// Button

$buttonColorMap: (
  primary: (
    base: $colorButton,
    hover: #0facbc,
    active: #0094a3,
    disabled: #abe1e8,
    contrast: $white,
  ),
  confirm: (
    base: #5dc88a,
    hover: #3cb26e,
    active: #2e9f5e,
    disabled: #bce3cc,
    contrast: $white,
  ),
  attention: (
    base: #e85e5e,
    hover: #dc4e4e,
    active: #cd4242,
    disabled: #f2c0c0,
    contrast: $white,
  ),
  warning: (
    base: $colorWarning,
    hover: #f6953b,
    active: #e88021,
    disabled: #fad7b8,
    contrast: $white,
  ),
  cancel: (
    base: #e8eeef,
    hover: #dbe8ea,
    active: #d1e3e5,
    disabled: #eff6f8,
    contrast: $textColor,
  ),
  simulation: (
    base: $colorLiliac,
    hover: darken($colorLiliac, 15%),
    active: darken($colorLiliac, 15%),
    disabled: $colorLiliacDisabled,
    contrast: $white,
  ),
);

$height-values: (
  400: 400px,
);

// Mixins
@mixin headingStyles {
  align-items: center;
  color: $textColor;
  display: flex;
  font-size: 2.375rem;
  font-weight: 300;
  letter-spacing: $letterSpacing03;
  line-height: 2.375rem;
  margin-bottom: 35px;
}

@mixin linkStyles {
  color: #3291fb;
  cursor: pointer;
  &:hover {
    color: #1f7afb;
  }
}

@mixin disabled($cursorStyle: default, $opacity: 0.7) {
  opacity: $opacity;
  pointer-events: none;
  cursor: $cursorStyle;
}

@mixin smallLabel {
  font-weight: bold;
  font-size: 0.6875rem;
  line-height: 1rem;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  margin-right: auto;
}

@mixin checkBoxLabel {
  @include smallLabel;
  text-transform: capitalize;
  color: #4d4d4d;
  font-weight: 400;
  padding-top: 2px;
  font-size: $fontSize17;
  line-height: 20px;
}

@mixin smartWordBreak {
  -ms-word-break: break-all;
  -ms-word-wrap: break-all;
  -webkit-word-break: break-word;
  -webkit-word-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

@mixin errorMessage {
  margin-top: 0;
  text-align: right;
  color: $red;
  font-size: 0.875rem;
}

@mixin textEllipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin unsetTextEllipsis {
  white-space: unset;
  text-overflow: unset;
  overflow: unset;
}

// main content height (page height - padding - headerHeight)
$mainContentHeight: 200px;

@mixin selected-border($inset: true, $border-width: 2px, $color: #00c1d5) {
  @if $inset {
    box-shadow: inset 0 0 0 $border-width $color;
  } @else {
    box-shadow: 0 0 0 $border-width $color;
  }
}

@mixin general-label {
  color: $inputPlaceholderColor;
  font-weight: $fontBold;
  font-size: $fontSize11;
  letter-spacing: 0.07em;
  line-height: 1rem;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.custom-height {
  @each $value, $height in $height-values {
    &__#{$value} {
      max-height: map-get($height-values, $value);
    }
  }
}

@keyframes opacity-ease-in {
  from {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes opacity-ease-out {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

.highlight {
  animation: highlight-background 1s;
}

@keyframes highlight-background {
  0% {
    background-color: $white;
  }
  50% {
    background-color: rgba(0, 193, 213, 0.15);
  }
  100% {
    background-color: $white;
  }
}
