@import '../../styles/constants';

.calendar-container {
  position: relative;
  width: 100%;
  display: flex;

  &__input {
    flex-basis: 100%;
  }

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &--selected {
    > * {
      border: 1px solid #00c1d5;
    }

  }

  &--error {
    .input-wrapper .input-text {
      color: $red;
    }
  }

  .react-calendar {
    z-index: $zIndexOverOverlay;
    position: absolute;
    max-width: unset;

    &--upwards {
      bottom: 68px;
    }

    &--from-right {
      right: 0;
    }
  }
}
