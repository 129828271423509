.location_collapse_icon {
  margin: auto 9px auto 0;
  width: 20px;

  &--open {
    transform: rotate(180deg);
  }
}

.location-type {
  padding: 2px 5px;
}

.selected-location {
  margin-bottom: 10px;
  .field-dropdown__field {
    max-width: 60%;
  }
}

.location-tag {
  display: flex;
  margin-left: auto;
  > div {
    padding: 0 5px;
    text-transform: uppercase;
    cursor: pointer;
  }
}
