@import '../../../../../styles/constants.scss';

.kpi-list {
  .item-wd__accounts {
    max-width: unset;
    min-width: unset;
  }

  .item-wd__accounts__names span {
    font-weight: unset;
    text-transform: unset;
    font-size: $fontSize15;
    color: $textColor;

    &:last-child {
      font-size: $fontSize11;
      margin-top: 0.25rem;
      color: $inputPlaceholderColor;
    }
  }
  .item-wd__accounts--removable {
    border: 1px solid $colorBorderSecondary;
    border-bottom: 0;
    margin-right: 0;
    padding: 0;
    &:last-child {
      border-bottom: 1px solid $colorBorderSecondary;
    }
    .account-icon {
      align-self: baseline;
      padding: 8px;
    }
    .item-wd__accounts__names {
      padding: 8px 0;
      border-right: 1px solid $colorBorderSecondary;
    }
    .item-wd__accounts__remove-icon {
      padding: 8px;
      cursor:pointer;
      display: flex;
    }
  }
}
