@import '../../../styles/constants.scss';

.alert-threshold-modal {
  .font-bold {
    font-weight: 800;
  }
  .modal__content {
    width: 1200px;
    max-width: 100vw;
    height: 770px;
    max-height: 100vh;
    .modal__buttons {
      .button--secondary-green {
        font-size: $fontSize13;
        color: $textColor;
        .button__value {
          display: flex;
          align-items: center;
          svg {
            margin-right: 5px;
          }
        }
      }
      .button--gray {
        font-size: $fontSize13;
      }
    }
  }
  .modal__children {
    min-height: 200px;
    max-height: 770px;
    overflow: scroll;
  }
  .modal__heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: $fontSize21;
    color: $textColor;

    &__view-mode {
      display: flex;
      gap: 8px;
    }
  }
  .table__cell {
    font-size: $fontSize12;
  }
  .table__header {
    font-size: $fontSize11;
    height: 32px;

    &__content {
      padding: 0;
    }
  }

  .client-threshold {
    width: fit-content;
    .input-text {
      height: 32px;
      width: 96px;
      border-radius: 4px;
      text-align: right;
      padding-right: 5px;
      font-size: $fontSize13;
    }
  }
  .input-text:focus-visible {
    outline: none;
    border-color: $colorButton;
  }
  .general-label {
    svg {
      width: 15px;
      height: 15px;
      margin-left: 5px;
    }
  }

  .thresholds-table {
    .table__header:first-child,
    .table__cell:first-child {
      padding-right: 60px;
    }
    .table__header:not(:first-child):not(:last-child),
    .table__cell:not(:first-child):not(:last-child) {
      padding: 0 40px 0 0;
    }
  }
  .frequency-table {
    position: relative;

    &-wrapper {
      flex-grow: 1;

      .general-label {
        margin: 5px 0 10px 0;
      }
    }

    .loading__overlay {
      border: 2px solid $colorLiliac;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: initial;
      background-color: rgba(255, 255, 255, 0.9);

      &--loading {
        border: 2px solid $colorBorderSecondary;
      }
    }

    .table__head {
      z-index: unset;
    }

    &__overlay {
      &-text {
        display: flex;
        flex-direction: row;
        font-size: $fontSize15;
        font-weight: $fontBold;

        &--pointer {
          flex-direction: row;
          cursor: pointer;
          height: 40px;
          align-items: center;
        }

        &--lower {
          flex-direction: column;
          margin-top: 30px;
        }

        &-first-row {
          display: flex;
          flex-direction: row;

          svg {
            height: 50px;
            width: 50px;
          }

          p {
            margin-top: 15px;
          }
        }

        &-second-row {
          font-weight: 600;
          font-size: $fontSize13;
          margin: 10px 0 0 20px;
          color: #4d4d4d;
        }

        svg {
          margin: 2px 10px 0 0;
        }
      }
    }

    &-diff {
      &--positive {
        color: #5dc88a;
      }

      &--negative {
        color: #e85e5e;
      }
    }
  }

  .daily-performance-ref-table {
    position: relative;

    &__roadmap {
      .loading__overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .roadmap__title {
          padding: 16px 24px;
          border: 1px solid $cardDisabled;
          border-radius: 4px;
          font-weight: 800;
          font-size: $fontSize21;
          background: $white;
        }
      }
    }

    &-wrapper {
      margin-left: 20px;
      flex-grow: 6;
      position: relative;
    }

    &-info {
      height: fit-content;
      justify-content: space-between;
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      .general-label {
        margin: 0;
      }
    }

    &-dropdown {
      .dropdown-tree {
        height: 24px;
        width: 240px;
        display: flex;
        align-items: center;

        &__label {
          min-height: unset;
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 4px 12px 4px 8px;
        }
        &__options {
          z-index: $zIndexFixedElements;
        }
        &__text {
          font-size: $fontSize12;
        }
      }
    }
  }

  .cancel-modal {
    .modal__content {
      width: 520px;
      max-height: 243px;
      .modal__children {
        max-height: unset;
        min-height: unset;
        overflow: unset;
      }
    }
  }
  .save-button-text {
    margin-top: 3px;
  }

  .tooltip {
    padding: 12px 16px;
    text-transform: none;
    font-size: $fontSize13;
    font-weight: normal;
  }

  .sample-data {
    margin-top: 15px;
    .text-panel {
      height: fit-content;
    }
  }

  &--scroll-disabled {
    .modal__children {
      overflow: hidden;
    }
  }

  .simplified-view {
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 100%;

    &__panel {
      display: flex;
      flex-direction: column;
      width: calc(50% - 12px);
    }

    &__table {
      box-sizing: border-box;
      height: 168px;
      width: 100%;
      border: 1px solid $colorBorderSecondary;
      font-size: $fontSize12;
      color: $textColor;
      overflow-y: scroll;

      &__row {
        display: flex;
        flex-direction: column;
        padding: 10px 40px 10px 10px;

        &--highlighted {
          font-weight: 800;
          background: #f4fcfc;
        }

        &__subexpression {
          display: flex;
          flex-direction: row;
          gap: 40px;
          justify-content: space-between;
          align-items: center;

          &__expression {
            height: fit-content;
            max-width: calc((100% - 40px) * 0.7);
            word-break: break-word;
            white-space: pre-wrap;
          }

          &__sample-data {
            width: 100%;
            height: fit-content;
            max-width: calc((100% - 40px) * 0.3);
            display: inline-block;
            text-align: center;
          }
        }

        &:nth-child(even):not(.simplified-view__table__row--highlighted) {
          background: #f7f7f7;
        }
      }
    }
  }

  .alerts-bulk-recommendations {
    .modal__content {
      max-width: 520px;
      height: fit-content;
    }

    .label-wrapper__label__text {
      font-size: $fontSize17;
    }
  }
}
