@import '../../styles/constants.scss';
.time {
  &__dropdown {
    margin-left: 8px;
    min-width: 88px;
  }

  &--error {
    .input-wrapper .input-text {
      color: $red;
    }
  }
}
