@import '../../styles/constants.scss';

$iconSize: 20px;

.confirm-input {
  position: relative;
  width: 100%;
  display: flex;
  box-sizing: border-box; //   padding-right: $iconSize * 2;

  &--error & {
    &__field {
      border: 1px solid $red;
    }
  }
  &__field {
    width: 100%;
    border: 1px solid $menuBackgroundLight;
    color: $inputPlaceholderColor;
    font-size: $fontSize15;
    line-height: $fontSize18;
    margin: 0;
    padding: 11px $iconSize * 2 11px 9px;
    border-radius: $borderRadius;
  }

  &__buttons {
    position: absolute;
    display: inline-flex;
    gap: 4px;
    top: 50%;
    transform: translateY(-50%);
    right: $iconSize * 0.5;
    user-select: none;

    &__button {
      cursor: pointer;

      width: $iconSize;
      height: 100%;

      &--disabled {
        opacity: 0.3;
        pointer-events: none;
      }
    }
  }
}
