$gap: 40px;

.sc-apply-to {
  &__row {
    gap: 15px $gap;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
  }

  &__field {
    min-width: calc(50% - $gap / 2);
    flex-grow: 0;
    flex-shrink: 1;
  }
}
