@import '../../styles/constants';

.account-icon {
  width: 20px;
  height: 20px;
}

div[role='option'] .account-dropdown {
  align-items: center;

  .checkbox__label {
    align-items: center;
  }

  .checkbox .label-wrapper {
    margin-top: -2px;
    font-size: $fontSize17;
  }
  .campaign-details {
    margin-left: 10px;
    max-width: calc(100% - 32px);
  }
  .campaign-title {
    font-size: $fontSize17;
    line-height: 140%;
    padding-bottom: 3px;
    white-space: normal;
  }
  .campaign-subtitle {
    font-size: $fontSize14;
    color: $colorSecondaryText;
    line-height: 140%;
  }

  .hide-checkbox {
    .check {
      display: none;
    }

    .label-content-wrapper {
      padding-left: 0;
    }

    .checkbox__label {
      flex-direction: row;
    }
  }
}

.item-wd {
  &__accounts {
    align-items: center;
    margin: 0 16px 0 auto;
    max-width: 400px;
    min-width: 180px;
    padding: 18px 0;

    img {
      margin-right: 8px;
    }
    span {
      @include smartWordBreak;
    }
    &__names {
      width: calc(100% - 23px);

      span {
        display: block;
        font-size: $fontSize11;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 1rem;
        letter-spacing: 0.07em;
        &:last-child {
          color: $inputPlaceholderColor;
        }
        margin-left: 10px;
      }
    }
  }
}
