@import '../../styles/constants';

.send-notifications {
  width: 100%;

  &__duplicate {
    border: 1px solid $red;
  }

  &__label {
    font-weight: 600;
    font-size: $inputFontSize;
    width: 240px;
  }

  &__to {
    width: auto;
    margin: 0 22px;
  }

  &__entities {
    max-width: 40%;
  }

  &__row {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    height: auto;
    background: $white;
    margin-bottom: 20px;
    box-sizing: border-box;

    &__plus,
    &__minus {
      width: 100%;
      background-position: center;
      background-repeat: no-repeat;
    }

    &__plus {
      background-image: url('../../assets/icon_plus_blue.svg');
      background-size: 18px 18px;
    }

    &__minus {
      background-image: url('../../assets/icon_minus.svg');
      background-size: 24px 24px;
    }

    > :nth-child(5) {
      margin-left: 20px;
    }
  }

  &__buttons {
    position: absolute;
    top: 0;
    right: -65px;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;

    .button {
      width: 50px;
      height: 40px;
      margin-bottom: 10px;
      font-size: 1.5rem;
      &:last-child {
        margin-bottom: 0;
      }
      &.minus {
        background: $red;
        border-color: $red;
      }
      &__value {
        font-weight: normal;
        margin-top: -2px;
      }
    }
  }

  &__warning {
    input,
    .select {
      border-color: $red;
    }
  }

  .input-group__element span {
    margin-left: -16px;
  }

  .input-group .input-wrapper input {
    padding: 6px 40px;
  }
}
