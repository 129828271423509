@import '../../styles/constants.scss';

.icons-showcase {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;

  &__header {
    display: flex;
    justify-content: space-between;

    &__buttons {
      * + * {
        margin-left: 8px;
      }
    }
  }

  &--dark {
    background: black;
  }
  &__icon {
    background-size: contain;
    background-repeat: no-repeat;
    cursor: copy;
    width: 18px;
    height: 18px;
    background-position: 50% 50%;
  }
}
