@import '../../styles/constants.scss';

.checkbox {
  user-select: none;
  flex-direction: column;

  &.no-label {
    width: auto;
  }

  & .check {
    width: $checkboxWidth;
    min-width: $checkboxWidth;
    height: $checkboxWidth;
    border-radius: 2px;
    border: $checkboxBorderWidth solid $hoveredMenu;
    background: transparent;
    cursor: pointer;

    & .image-icon {
      display: none;
    }

    svg {
      display: none;
    }
  }

  &--checked {
    .check:not(.image-icon) {
      border: $checkboxBorderWidth solid $colorButton;
      background-color: $colorButton;
      & svg {
        display: block;

        path {
          fill: $white;
        }
      }
    }
    & .check .image-icon {
      display: block;
      width: $checkboxWidth;
      height: $checkboxWidth;
      background-color: $hoveredMenu;
    }
  }

  &--white {
    border: 1px solid $colorSecondaryText;
    border-radius: $borderRadius;
    padding: 8px 12px;
    box-sizing: border-box;
    background-color: $white;
    &.input-wrapper .label-wrapper {
      font-size: $fontSize11;
      text-transform: uppercase;
      font-weight: $fontWeight700;
    }

    &.checkbox--checked {
      border-color: $colorButton;
    }

    .checkbox__label {
      align-items: center;
    }
  }

  &--reversed & {
    &__label {
      flex-direction: row-reverse;
    }
  }

  &--disabled {
    opacity: 0.7;
    cursor: pointer;

    .check {
      border: $checkboxBorderWidth solid $hoveredMenu;
      background-color: transparent;
    }
    &.checkbox--checked .check {
      background-color: $cardDisabled;
      border: $checkboxBorderWidth solid $cardDisabled;
    }
  }

  &--blue {
    &.checkbox--checked .check {
      border: $checkboxBorderWidth solid $colorButton;
      background-color: $colorButton;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    z-index: 0;
    pointer-events: none;
  }

  &--dark {
    &--checked {
      .check:not(.image-icon) {
        background-color: $white;
        border: $checkboxBorderWidth solid $white;
        svg path {
          fill: $hoveredMenu;
        }
      }
    }

    .check {
      border: $checkboxBorderWidth solid $white;
    }
  }

  &__label {
    display: inline-flex;
    align-items: flex-start;
    z-index: 1;
    cursor: pointer;
    width: 100%;
    gap: $checkboxContentSpacing;
  }

  .label-content-wrapper {
    display: flex;
    flex-direction: column;
    align-self: center;
    flex: 1;
    // Max label content width is all available space minus checkboxWidth & border sizes
    max-width: calc(100% - (#{$checkboxWidth} + 2 * #{$checkboxBorderWidth}));
    @include textEllipsis;
  }

  .label-wrapper,
  .hint-wrapper {
    text-transform: none;
    font-size: $fontSize17;
    font-weight: normal;
    padding-left: unset;
    white-space: normal;
  }

  .label-wrapper {
    line-height: 20px;
    width: 100%;
  }

  .hint-wrapper {
    color: $hoveredMenu;
    font-size: $fontSize10;
    margin-top: 0.25rem;
    margin-right: auto;
  }

  &.checkbox-first {
    .checkbox__label {
      flex-direction: row-reverse;
    }

    .label-content-wrapper {
      white-space: pre-wrap;
    }

    .label-wrapper {
      color: $textColor;
      font-size: $fontSize17;
      width: auto;
    }
  }
  &--centered {
    .checkbox__label {
      justify-content: center;
    }
    .label-content-wrapper {
      flex: initial;
    }
  }
  &--center-icon {
    .label-content-wrapper{
      padding-left: 0;
    }
  }
  &__icon {
    margin: 0 8px;
  }
}
