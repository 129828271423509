@import '../../styles/constants';

.tooltip {
  background: $sectionBackground;
  border-radius: $borderRadius;
  box-shadow: $sectionBoxShadow;
  font-size: $fontSize15;
  color: $inputColor;
  position: fixed;
  padding: 20px;
  z-index: $zIndexOverOverlay;

  &--has-title {
    padding: 0;
  }

  &__title {
    padding: 8px 16px;
    font-size: $fontSize15;
    font-weight: $fontBold;
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
    background-color: $colorLightBackground;
  }

  &__content {
    padding: 8px 16px;
    font-size: $fontSize13;
    font-weight: $fontBold;
    line-height: 18.2px;
    letter-spacing: 0.003em;
  }

  .tooltip-title {
    font-weight: $fontBold;
    margin-bottom: 4px;
  }

  .tooltip-item {
    margin-top: 10px;
    word-break: break-word;
  }

  &__text {
    font-size: $fontSize13;
    text-transform: none;
    font-weight: bold;
  }

  .tooltip-more {
    margin-top: 10px;
    font-style: italic;
  }

  &.padding-0 {
    padding: 0;
  }
}

.static-tooltip {
  &__wrapper {
    position: relative;
    cursor: pointer;
  }

  &__content {
    position: absolute;
    z-index: $zIndexOverOverlay;
    background-color: $white;
    overflow: auto;
    border-radius: $borderRadius;
    box-shadow: $sectionBoxShadow;
    padding: 12px 16px;

    &--clickable &__children {
      cursor: pointer;
    }
  }
}
