@import '../../styles/constants';

.calculated-metrics {
  &__info {
    margin-left: 8px;
    cursor: pointer;
    z-index: 1;
  }
  .filters {
    .filters--container {
      overflow: unset;
    }
  }
}
