@import '../../../styles/constants.scss';

.import-users-download {
  padding: 15px 20px;
  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__description {
    border-bottom: 1px solid $colorFlowBorders;
    border-top: 1px solid $colorFlowBorders;
    margin: 0;

    .flow__link {
      margin: 0 4px;
      display: inline;
      cursor: pointer;
    }
    .first-description {
      margin-bottom: 0;
    }
  }
  .form__section__body {
    padding: 0 0 10px;
    overflow: hidden;
    margin-top: 0;
  }
  .upload-modal {
    padding: 15px 20px;
  }
  .failed-file {
    margin: 10px 20px 10px 20px;
  }
  .modal__content {
    max-width: 500px;
    .modal__heading {
      font-size: $fontSize21;
    }
  }
}

.import-users-table {
  display: flex;
  color: $textColor;
  margin: 0 25px;
  padding-bottom: 10px;
  min-height: 50vh;
}

.scrollable-columns {
  display: flex;
  flex-direction: column;
  overflow-x: scroll;

  .row {
    > div:nth-child(3) {
      border-left: none;
      border-radius: 0;
    }
  }
  .row-section--second {
    border-right: 1px solid $colorSecondaryText;
    border-radius: 0;
  }
}
.row-section {
  display: flex;
  min-width: 120px;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid $colorSecondaryText;
  border-right: none;

  &--first {
    border-radius: 4px 0 0 4px;
  }

  &--first,
  &--second {
    position: sticky;
    left: 0;
    z-index: 1;
    min-width: 150px;
    background: $white;
  }

  &--second {
    left: 151px;
  }

  &--last {
    border-right: 1px solid $colorSecondaryText;
    border-radius: 0 4px 4px 0;
  }
  &--wider {
    min-width: 200px;
    max-width: fit-content;
  }

  &--error-required {
    border-color: $colorRedVibrant;
    .select::after {
      background: $colorRedVibrant;
    }
  }
  &--error-format {
    border-color: $colorWarning;
    .select::after {
      background: $colorWarning;
    }
  }
  .input-wrapper .input-text {
    background-color: unset;
  }
}
.row {
  display: flex;
  margin-bottom: 10px;
  height: 42px;
}

.row-header {
  font-weight: $fontBold;
  margin-bottom: 0;

  .row-section {
    border-color: $sectionBackground;
  }
}

.scrollable-columns {
  .row-section--last {
    min-width: fit-content;
  }
  .select {
    background-color: unset;
    border: none;
  }
  .select__options {
  }
  .select .dropdown--placeholder {
    color: $textColor;
    margin-left: 0;
  }
  .dropdown-preview-selected {
    color: $textColor;

    &-list {
      right: calc(100% - 200px);
      z-index: 1;
      .checkbox--checked .check .image-icon {
        background-color: $sectionBackground;
      }
      &__item {
        padding: 10px;
      }
    }

    div {
      overflow: scroll;
    }
  }
}

.csv-file-name {
  padding: 15px 25px;
  border-bottom: 1px solid $colorFlowBorders;
  color: $textColor;
  font-size: 17px;
  margin: 0;
}

.import-users {
  background-color: $sectionBackground;
  overflow: hidden;
  border-radius: $borderRadius;
  .tooltip {
    display: flex;
    align-items: center;
    padding: 10px;
    > svg {
      margin-right: 5px;
    }
  }
}
