@import '../../styles/constants.scss';

.shortcut-card {
  cursor: pointer;

  position: relative;
  box-sizing: border-box;

  width: 100%;
  height: 266px;
  padding: 70px 56px;

  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;

  background-color: $colorButton;
  color: $white;
  border-radius: 8px;

  &--disabled {
    cursor: unset;
    background-color: $colorPrimaryDisabled;

    &__tooltip {
      background: $sectionBackground;
      border-radius: $borderRadius;
      box-shadow: $sectionBoxShadow;
      font-size: $fontSize15;
      color: $inputColor;
      position: fixed;
      padding: 15px;
      z-index: $zIndexOverOverlay;
      pointer-events: none;
    }
  }

  &__icon-wrapper {
    height: 72px;

    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;

    .button {
      width: 72px;
      height: 72px;

      span,
      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  &__icon {
    width: 60px;
    height: 60px;

    &--avatar {
      path {
        fill: $white;
      }
    }

    &--embarq {
      width: 51px;
      height: 39px;
    }

    &--logout {
      width: 54px;
      height: 54px;
    }
  }

  &__description {
    width: 167px;
    height: 38px;

    text-align: center;
    font-size: $fontSize28;
    line-height: 135%;
  }

  &--confirm-logout {
    background-color: $white;
    color: $textColor;
    border: 1px solid $colorButton;
  }
}
