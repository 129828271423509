@import '../../styles/constants.scss';

.checkbox-select-all {
  display: inline-flex;
  gap: 5px;
  align-items: center;

  &--disabled {
    @include disabled(not-allowed, 0.5);
  }

  &__icon {
    width: 13.5px;
    display: inline-flex;
    align-items: center;

    svg {
      width: 13.5px;
      height: 13.5px;
    }
  }

  &__label {
    text-transform: uppercase;
    font-size: $fontSize11;
    font-weight: $fontWeight700;
    user-select: none;
  }

  &--big & {
    &__icon {
      width: 16px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &--bordered {
    padding: 5px 12px;
    border: 1px solid $white;
    border-radius: $borderRadius;

    &.checkbox-select-all--blue {
      border-color: $colorButton;
    }
  }

  &--blue &__icon {
    svg path {
      fill: $colorButton;
    }
  }

  &--blue#{&}--none & {
    &__icon {
      svg path {
        fill: $colorSecondaryText;
      }
    }
  }

  &--blue#{&}--checked &,
  &--blue#{&}--indeterminate & {
    &__icon {
      svg {
        path {
          fill: $colorButton;
        }
      }
    }
  }

  &--clickable {
    &:hover {
      cursor: pointer;
    }
  }
}
