@import '../../styles/constants.scss';

.permission-groups {
  position: relative;
  .filters-toggle {
    top: 0;
    left: calc(100% + 13px);
    transform: translateY(-10%);
  }
  &__inputs {
    display: flex;
  }

  padding-bottom: 10px;

  .filters {
    .filters--container {
      overflow: unset;
    }
  }
}
