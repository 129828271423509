@import '../../styles/constants.scss';

.color-picker {
  .react-colorful {
    width: auto;

    &__interactive {
      cursor: pointer;
    }

    &__saturation-pointer {
      width: 20px;
      height: 20px;

      background-color: unset;
      border-width: 3px;
    }

    &__hue-pointer {
      width: 20px;
      border-radius: 4px;
      border-width: 3px;
      background-color: unset;

      .react-colorful .pointer-fill {
        border-radius: 4px;
      }
    }
  }

  & + .color-picker-input {
    margin-top: 10px;
  }
}

.color-picker-input {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}
.color-picker-modal {
  position: absolute;
  border-radius: $borderRadius;
  background-color: $sectionBackground;
  width: 396px;
  box-sizing: border-box;
  box-shadow: $flowBoxShadow;
  left: 50px;
  visibility: hidden;
  z-index: 3;

  &__header {
    padding: 10px 16px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid $colorFlowBorders;
    h3 {
      font-weight: normal;
      font-size: $fontSize17;
      margin: 0;
    }

    &__close {
      width: 14px;
      height: 14px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &:hover {
        svg path {
          fill: darken($colorButton, 10);
        }
      }
      svg {
        width: 14px;
        height: 14px;
        path {
          fill: $colorButton;
        }
      }
    }
  }

  &__content {
    padding: 0 16px;
  }
  &__footer {
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
