@import '../../styles/constants.scss';

.companies {
  &__contact {
    margin-top: 0;
  }

  .add-more {
    align-items: flex-start;
    margin-top: 20px;
  }

  .table__actions {
    flex-wrap: wrap;
    flex-direction: column;

    button {
      margin: 0 0 10px 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__add--reporting {
    &__field {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
  .filters {
    overflow-x: hidden;
    .general-label {
      color: $inputColor;
      font-weight: bold;
      margin-top: 15px;
      margin-bottom: 8px;
    }
    .align-row {
      .align-column {
        width: 50%;
      }
    }
    .input-wrapper {
      margin-top: unset;
    }
    .select__search--bottom {
      .select__search {
        padding-left: 25px;
      }
    }
    .filters--container {
      overflow: unset;
    }
  }
}

.add-edit-companies {
  .button.align-right {
    margin-left: auto;
  }

  .button.fixed-height {
    height: 44px;
  }

  .sub-section {
    cursor: pointer;
    height: 40px;
    margin-left: 16px;
    &.opened {
      img {
        transform: rotate(180deg);
      }
    }
  }

  .form__section__body + .align-row {
    margin-top: 20px;
  }

  .general-label &:not(.label-error) {
    color: $inputColor;
  }

  .general-label--error {
    color: $red;
  }

  .information-block {
    margin-top: 10px;
  }
}

.users {
  &__assign-modal {
    .modal__heading {
      color: $textColor;
    }
    .modal__content {
      width: 520px;
    }
  }
}

.alert-filters {
  .entity-selector__filters {
    border-bottom: none;
  }
  .entity-selector__extra-actions {
    display: block;
  }
}

.alert-thresholds-panel {
  &__default {
    opacity: 0.5;
  }
  .font-bold {
    color: $black;
  }

  .client-threshold {
    width: fit-content;
    .input-text {
      height: 32px;
      width: 96px;
      border-radius: 4px;
      text-align: right;
      padding-right: 5px;
      font-size: $fontSize13;
    }
  }
}

.client-view {
  &__kpi {
    margin-top: 8px;
    display: flex;
    align-items: center;
    .account-icon {
      margin-right: 8px;
    }
    & + & {
      margin-top: 16px;
    }
  }
}

.add-edit-companies {
  &__form {
    .form__section__body__half-width-section {
      & > * + * {
        margin-top: 20px;
      }
    }
  }
}

.client-restore-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  background-color: $white;
  border: 1px solid $red;
  border-radius: 5px;
  min-height: 100px;
  height: fit-content;
  margin-bottom: 10px;

  .line-divider {
    margin: 0;
  }

  &__content {
    color: $black;
  }

  &__actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
  }
}

.edit-subsections {
  .table__icons {
    svg {
      width: 18px;
      height: 18px;
    }

    padding-right: 10px;
  }
}

.client-link-control,
.client-copy-control {
  position: relative;
  align-self: center;

  &__link {
    height: 20px;
  }

  &__popover {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 400px;
    top: calc(100% + 10px);
    z-index: $zIndexOverlay;
    box-shadow: $sectionBoxShadow;

    &__heading {
      text-transform: uppercase;
      font-size: $fontSize11;
      font-weight: $fontBold;
      line-height: 15.95px;
      letter-spacing: 0.07em;
      background-color: $colorLightBackground;
      padding: 8px;
      border-top-left-radius: $borderRadius;
      border-top-right-radius: $borderRadius;
      border-bottom: 1px solid $colorFlowBorders;
    }

    &__title {
      text-transform: uppercase;
      font-size: $fontSize11;
      font-weight: $fontBold;
      line-height: 15.95px;
      letter-spacing: 0.07em;
      background-color: $sectionBackgroundDarker;
      padding: 8px;
      border-bottom: 1px solid $colorFlowBorders;
    }

    &__section {
      font-size: $fontSize11;
      font-weight: $fontBold;
      line-height: 15.95px;
      letter-spacing: 0.07em;
      background-color: white;
      padding: 10px 16px;
      border-bottom: 1px solid $colorFlowBorders;
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 8px;
      border-bottom-left-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
      background-color: $white;
      border-bottom: 1px solid $colorFlowBorders;
    }
  }

  &__dropdown {
    &.dropdown-list__options {
      width: 400px;
      top: unset;
    }

    .checkbox__label {
      flex-direction: row-reverse;
      align-items: center;
    }

    .dropdown-list__search--bottom {
      height: 34px;
    }

    .dropdown-list__toggles {
      display: flex;
      align-items: center;
    }
  }
}

.client-copy-control {
  .radio.blue .label-wrapper {
    color: $inputColor;
    font-size: 0.9375rem;
    letter-spacing: 0.01em;
    margin-bottom: 0;
    padding: 0;
    text-transform: none;
  }
}
