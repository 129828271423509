@import '../../styles/constants.scss';

.switch-container {
  align-items: center;
  height: 20px;
  gap: 8px;

  &__label {
    color: $textColor;
    font-size: 21px;
    letter-spacing: $letterSpacing03;

    &.right {
      margin: 0 0 0 16px;
    }
  }

  &__switch {
    height: 0;
    margin-left: auto;
    visibility: hidden;
    width: 0;
  }

  &__switch-toggle {
    align-items: center;
    background: #dedede;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    height: 15px;
    position: relative;
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
    width: 30px;
  }

  &__on-off {
    color: $inputColor;
    font-size: $fontSize11;
    font-weight: 700;
    position: absolute;
    right: 44px;
  }

  .switch-button {
    transition: 0.2s;
    border-radius: 50%;
    background: $grey;
    content: '';
    display: block;
    height: 15px;
    left: 0;
    position: absolute;
    top: 0;
    width: 15px;

    &--checked {
      right: 0;
      left: calc(100% - 15px);
      background: $colorButton;
    }
  }

  &--disabled {
    @include disabled();
  }
}
