@import '../../../../styles/constants.scss';

.associated-alerts {
  &__tooltip {
    max-height: 432px;
    width: 432px;

    padding: 8px;
  }

  &__recommended-icon {
    .icon-container--disabled {
      svg,
      path {
        fill: $colorButtonGrey;
        opacity: 1;
      }
    }
  }

  &__info-icon {
    svg {
      cursor: pointer;

      path {
        fill: $white;
      }
    }
  }
}
