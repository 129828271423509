@import '../../styles/constants.scss';

.notification-container {
  position: absolute;
  right: 10px;
  top: 100px;
  max-width: 50%;
  margin-left: auto;
  z-index: $zIndexHighest;

  .align-row {
    justify-content: space-between;
  }

  .notification {
    background: $white;
    border: 1px solid $colorSuccess;
    border-radius: 5px;
    font-size: $fontSize;
    margin: 0 0 10px 0;
    padding: 5px;
    width: 100%;
    box-sizing: border-box;

    .message {
      color: $colorSuccess;
      line-height: 20px;
      padding: 10px 5px;
    }

    &.error {
      border: 1px solid $red;
      .message {
        color: $red;
      }
    }
    &.warning {
      border: 1px solid $colorWarning;
      .message {
        color: $textColor;
      }
      .close {
        color: $colorWarning;
      }
      .warning-icon {
        margin: auto 5px auto auto;
        padding: 5px 10px 5px 5px;
        display: flex;
        border-right: 1px solid $colorWarning;
      }
    }
    &.alert {
      border: 1px solid $colorAlert;
      .message {
        color: $colorAlert;
      }
    }
    .close {
      cursor: pointer;
      color: #2a2a2a;
      font-size: 25px;
      height: 40px;
      line-height: 2px;
      width: 40px;
      &:hover {
        font-size: $fontSize28;
      }
    }
  }
}
