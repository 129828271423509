@import './constants.scss';

$authenticationWidth: 536px;

.authentication-container {
  display: flex;
  background: $lightTextColor;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  justify-content: center;

  &__wrapper {
    margin: 0 auto;
    width: $authenticationWidth;
  }

  &__form {
    background: $inputColor;
    border-radius: $borderRadius;
    padding: 40px 40px 30px 40px;
    .wrapper {
      padding-top: 20px;
    }

    .align-row {
      margin-top: 20px;
      justify-content: space-between;
      align-items: center;

      & > *:only-child {
        margin-left: auto;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
  }

  &__title {
    color: $white;
    font-size: $fontSize21;
    letter-spacing: 0.5px;
    line-height: 40px;
  }

  &__description {
    color: $white;
    font-size: $fontSize17;
    letter-spacing: $letterSpacing05;
    margin: 30px 0 24px 0;
  }

  &__forget {
    color: $lightTextColor;
    font-size: $inputFontSize;
    letter-spacing: $letterSpacing05;

    a {
      color: $colorButton;
      margin-left: 4px;
      text-decoration: none;
    }
  }

  &__two-inputs {
    &.align-row {
      align-items: flex-start;
      margin: 0;
    }
    .wrapper {
      width: 48%;
    }
    .wrapper:nth-child(2) {
      margin-left: auto;
    }
  }

  &__links {
    align-items: center;
    font-size: $inputFontSize;
    font-weight: bold;
    letter-spacing: $letterSpacing05;
    margin: 30px 0;

    .vertical-line {
      color: $white;
      font-size: 21px;
      margin: 0 9px 0 7px;
    }
    span,
    a {
      color: $colorButton;
      font-size: $fontSize17;
      text-decoration: none;
      white-space: nowrap;
    }
    .fake-link {
      text-transform: none;
      &:hover,
      &:focus {
        color: $white;
      }
    }
  }

  &__cta {
    justify-content: space-between;
  }
  &__qr {
    svg {
      display: block;
      margin: 0 auto;
    }
  }

  &__submit-error {
    background: $red;
    border-radius: 0 0 4px 4px;
    color: $white;
    font-size: $inputFontSize;
    letter-spacing: $letterSpacing02;
    padding: 30px 62px;
    margin: 20px -40px -30px;
    text-align: center;
    width: $authenticationWidth;
    box-sizing: border-box;
  }

  .label-wrapper {
    color: $white;
  }

  &__resend-code a {
    text-decoration: none;
    color: $colorButton;
    font-size: $fontSize17;
    &:hover,
    &:focus {
      color: darken($colorButton, $amount: 5);
    }
  }
  .three-q-logo {
    cursor: pointer;
    height: 30px;
    max-height: 40px;
  }
}

.terms-of-use-title {
  font-family: $fontFamily;
  color: $white;
  font-size: $fontSize20;
  margin: auto 0;
  letter-spacing: $letterSpacing05;
}
