@import './constants.scss';

.content-height {
  height: 100%;
}

.img-container {
  position: relative;
}

.img-left-75 {
  position: absolute;
  top: 100px;
  left: 75px;
}

.img-left-0 {
  position: absolute;
  top: 100px;
  left: 0;
}

.img-rigth-bottom {
  width: 50%;
  height: 100%;
}

.title {
  line-height: 100%;
  color: $textColor;
  margin: 5px 0;

  &__font {
    font-family: $fontFamilySriracha;

    &-90 {
      font-size: 5.625rem;
      margin-bottom: 20px;
    }

    &-100 {
      font-size: 6.25rem;
    }
  }
}

.info-text {
  font-family: $fontFamily;
  font-size: $fontSize28;
  margin-bottom: 15px;

  a {
    color: $colorButton;
    margin-left: 5px;
    font-weight: $fontBold;
    text-decoration: none;
  }
}
