@import '../../../../styles/constants.scss';

.teams-alert-notifications {
  min-width: 600px;

  &__actions {
    display: flex;
    justify-content: space-between;
    gap: 16px;

    &__main,
    &__secondary {
      display: flex;
      gap: 16px;
    }

    &__toggle-edit {
      outline-color: $colorButton;
      outline-offset: -1px;
    }
  }

  .perform-qa__button {
    padding: 8px 12px;
  }

  &__alerts {
    .input-group + .input-group {
      margin-top: 5px;
    }

    .select:not(.select--disabled) .dropdown--placeholder {
      color: $textColor;
    }

    .input-group__element label:not(.drop-down) {
      padding-left: 0;
    }
  }

  &__bulk {
    &__actions {
      display: flex;
      gap: 16px;
      justify-content: flex-end;
    }
  }

  &__modal {
    .modal {
      &__content {
        width: 575px;
      }
    }
  }
}
