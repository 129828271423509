@import './constants';

$tableHeader: $grey;
$columnHeight: 48px;

.table-wrapper {
  overflow-y: hidden;
  max-height: calc(100vh - 230px);

  &--paginated {
    position: relative;
    max-height: calc(100vh - 290px);

    .table {
      max-height: calc(100vh - 300px);
    }
  }

  .tfoot {
    overflow: visible;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.table {
  background: $sectionBackground;
  border: 1px solid $colorBorder;
  border-radius: $borderRadius;
  border-spacing: 0;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: auto;
  max-height: calc(100vh - 243px);
  min-width: fit-content;

  &--sticky {
    width: 100% !important;
    overflow-y: auto;

    .tr,
    .thead,
    .tbody {
      overflow: visible;
      min-width: fit-content;
      max-width: 100%;
    }

    .tr .td:first-child,
    .tr .th:first-child {
      position: sticky;
      left: 0;
      background-color: inherit;
      border-right: 1px solid $colorFlowBorders;
    }

    .tr .th:first-child {
      background: $tableHeader;
      z-index: 1;
    }
  }

  .thead {
    background: $tableHeader;
    position: sticky;
    top: 0;
    z-index: $zIndexDefault;
    text-transform: uppercase;

    &__compact {
      > .tr > .th[role='columnheader'] {
        height: 40px;
      }

      .table__checkbox {
        height: auto;
      }
    }
  }

  .tbody {
    overflow: visible;
    border-radius: 0 0 4px 4px;

    > .tr:nth-child(even):not(.highlight-row--error) {
      background: $hoveredMenuItem;
    }

    > .tr:nth-child(odd):not(.highlight-row--error) {
      background: $sectionBackground;
    }

    .highlight-row {
      &--error {
        background: #fbefef;
      }
    }
  }

  &--loading .tbody {
    opacity: 0.7;
    pointer-events: none;
    cursor: default;
    min-height: 200px;
  }

  &__checkbox {
    margin: 0 10px;
    min-width: 30px;
  }

  &__select {
    box-sizing: border-box;
    flex: 50 0 auto;
    min-width: 0;
    width: 50px;
  }

  .tr {
    display: flex;

    &:last-child {
      border-radius: 0 0 4px 4px;

      td {
        border-bottom: 0;
      }
    }
  }

  .th {
    flex: 1;
    color: $white;
    font-weight: $fontBold;
    font-size: $fontSize11;
    height: $columnHeight;
    user-select: none;
    position: relative;
    padding: 0;
    text-align: center;
    box-sizing: border-box;

    &:first-child {
      border-radius: 4px 0 0 0;
      padding-left: 16px;
    }

    &:last-child {
      border-radius: 0 4px 0 0;
      padding-right: 16px;
    }

    h4 {
      position: relative;
    }

    &--dnd {
      padding: 0px 6px 0px 30px !important;
    }
  }

  .th.align-right &__sort {
    left: unset;
    right: calc(100% + 10px);
  }

  .td {
    flex: 1;
    border-bottom: 1px solid $colorBorder;
    box-sizing: border-box;
    color: $inputColor;
    font-size: $fontSize15;
    height: $columnHeight;
    line-height: 16px;
    padding: 0;
    word-break: break-word;
    white-space: pre-wrap;

    &:first-child {
      border-radius: 0 0 0 4px;
      padding-left: 16px;
    }

    &:last-child {
      border-radius: 0 0 4px 0;
      border-right: 0;
      padding-right: 16px;
    }
  }

  .th,
  .td {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 6px;

    &.align-left {
      justify-content: flex-start;
    }

    &.align-right {
      justify-content: flex-end;
    }

    &.align-center {
      justify-content: center;
    }

    &.table-select {
      margin-left: 10px;
    }
  }

  &-select {
    .input-wrapper {
      flex: unset;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  &__sort-icon {
    font-size: 0.875rem;
    position: absolute;
    top: 55%;
    left: calc(100% + 10px);
    transform: translateY(-50%);
    &--desc {
      top: 50%;
    }
  }

  &__no-data {
    padding: 10px;
    opacity: 0.8;
  }

  &__edit {
    width: 18px;
    height: 18px;
    cursor: pointer;
    background: url('../assets/icon_edit.svg');
    &--disabled {
      background: url('../assets/icon_edit_disabled.svg');
      pointer-events: none;
    }
  }

  &__view {
    width: 18px;
    height: 18px;
    cursor: pointer;
    margin: 0 auto;
    background: url('../assets/icon_eye_blue.svg') no-repeat 100%;
  }

  &__link {
    width: 20px;
    height: 18px;
    cursor: pointer;
    margin: 0 auto;
    background: url('../assets/icon_link_blue.svg') no-repeat 100%;

    &--expandable {
      transform: translateX(-27px);
    }
  }

  &__duplicate {
    width: 19px;
    height: 22px;
    cursor: pointer;
    background: url('../assets/icon_duplicate.svg') 100% 100%;

    &--disabled {
      background: none;
    }

    &--grey {
      background: url('../assets/icon_duplicate_grey.svg') 100% 100%;
    }
  }

  &__open {
    width: 22px;
    height: 22px;
    cursor: pointer;
    background: url('../assets/icon_launch_blue.svg');
  }

  &__star {
    width: 22px;
    height: 22px;
    cursor: pointer;
    background: url('../assets/icon_star_blue.svg');

    &--empty {
      background: url('../assets/icon_star_empty.svg');
    }
  }

  &__remove {
    margin-right: 20px;
    cursor: pointer;
  }

  &__delete {
    margin-left: auto;
    margin-right: 20px;
    min-width: 16px;
    cursor: pointer;
  }

  &__search {
    margin-bottom: 30px;
  }

  &__lock {
    width: 16px;
    height: 21px;
    cursor: default;
    background: url('../assets/icon_lock_yellow.svg');

    &--expandable {
      transform: translateX(-27px);
    }
  }

  .three-q-loader {
    position: absolute;
    top: 50px;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: $zIndexOverOverlay;
  }

  .custom-column {
    & + .td .table__actions {
      margin-right: 15px;
      margin-left: auto;
    }
  }

  &__icons {
    display: flex;
    flex-direction: row;

    svg {
      width: 20px;
      height: 20px;

      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
}

.pagination {
  align-items: center;
  background: $sectionBackground;
  border: 1px solid #f1f1f1;
  border-radius: 0 0 4px 4px;
  box-sizing: border-box;
  height: 48px;
  padding: 6px 18px 6px 0;
  width: 100%;
  z-index: $zIndexFixedElements;
  user-select: none;

  &__search {
    margin-right: auto;
    margin-left: 16px;
    width: 25%;

    &.wrapper {
      width: 25%;

      input[type='text'] {
        background-position: calc(100% - 20px) calc(1em + 1px),
          calc(100% - 15px) calc(1em + 1px), calc(100% - 2.5em) 0.5em;
        min-height: 36px;
        padding: 8px 26px 5px 40px;
      }
    }
  }

  span:first-child {
    font-size: 0.9375rem;
    margin-left: auto;
  }

  .drop-down {
    height: 36px;
    margin: 0 38px 0 8px;
    max-width: 80px;
    .select {
      background-position: calc(100% - 20px) calc(1em + 1px),
        calc(100% - 15px) calc(1em + 1px), calc(100% - 2.5em) 0.5em;
      min-height: 36px;
      padding: 8px 26px 5px 15px;
    }
  }
  &__count {
    font-size: 0.9375rem;
  }
  &__buttons {
    img {
      margin-left: 31px;
      cursor: pointer;
    }
  }
  &__button {
    cursor: pointer;
    margin-left: 31px;
    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
    &--blue {
      path {
        fill: $colorButton;
      }
    }
  }
}

.filter-icon {
  display: flex;
  align-items: center;
}
