@import '../../../styles/constants.scss';

$tableHeader: $grey;
$columnHeight: 48px;
$dndHandleWidth: 30px;

.table {
  background: $sectionBackground;
  border: 1px solid $colorBorder;
  border-radius: $borderRadius;
  border-spacing: 0;
  width: 100%;
  position: relative;
  overflow: auto;
  max-height: calc(100vh - 250px);

  &--400 {
    max-height: calc(100vh - 400px);
  }

  &--clickable & {
    &__row {
      cursor: pointer;
    }
    &__row:hover {
      .table__cell {
        background-color: rgba($colorButton, 0.1);
      }
    }
  }

  &__dnd__handle {
    left: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    width: $dndHandleWidth;
    height: 100%;
  }

  &--dragndrop & {
    &__row {
      padding-left: $dndHandleWidth;
    }
  }

  &--sticky {
    width: 100% !important;
    overflow-y: auto;

    tr,
    thead,
    tbody {
      overflow: visible;
      min-width: fit-content;
      max-width: 100%;
    }

    tr td:first-child,
    tr th:first-child {
      position: sticky;
      left: 0;
      background-color: inherit;
      border-right: 1px solid $colorFlowBorders;
    }

    tr th:first-child {
      background: $tableHeader;
      z-index: 1;
    }
  }

  &__head {
    background: $tableHeader;
    z-index: $zIndexDefault;
    border-radius: 4px 4px 0 0;
    position: sticky;
    top: 0;

    text-transform: uppercase;

    &__compact {
      > .table__row > .table__header[role='columnheader'] {
        height: 40px;
      }

      .table__checkbox {
        height: auto;
      }
    }
  }

  &__sort-icon {
    position: absolute;
    right: 0;
  }

  &__body {
    overflow: visible;
    border-radius: 0 0 4px 4px;

    > .table__row:nth-child(even):not(.highlight-row--error) {
      background: $hoveredMenuItem;
    }

    > .table__row:nth-child(odd):not(.highlight-row--error) {
      background: $sectionBackground;
    }

    .highlight-row {
      &--error {
        background: #fbefef;
      }
    }
  }

  &__select {
    .checkbox-select-all__icon {
      width: 20px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &.checkbox-select-all--none {
      svg path {
        fill: $colorCheckboxUnselected;
      }
    }
  }

  &--loading {
    min-height: $columnHeight * 4;
  }

  &--loading .table__body {
    opacity: 0.7;
    pointer-events: none;
    cursor: default;
  }

  &--loading .table__head {
    pointer-events: none;
    cursor: default;
  }

  &__loader.three-q-loader {
    $loaderHeight: $columnHeight * 3;
    height: 0;
    position: sticky;
    top: calc(50% - $columnHeight);

    svg {
      width: $loaderHeight;
      height: $loaderHeight;
    }
  }

  &__checkbox {
    margin: 0 10px;
    min-width: 30px;
  }

  &__select {
    box-sizing: border-box;
    flex: 50 0 auto;
    min-width: 0;
    width: 50px;
  }

  &__row {
    display: flex;

    border-bottom: 1px solid $colorBorder;

    &__container {
      position: relative;
      transition: 0.3s all;

      &--hovered {
        box-shadow: 0 0 10px $colorPrimaryDisabled;
      }
    }

    &--no-data {
      min-height: $columnHeight;
      padding-left: 16px;
      align-items: center;
    }
  }

  &__header {
    flex: 1;
    color: $white;
    font-weight: $fontBold;
    font-size: $fontSize11;
    height: $columnHeight;
    user-select: none;
    position: relative;
    padding: 0;
    text-align: left;
    box-sizing: border-box;

    .table__select {
      svg path {
        fill: $white;
      }
    }

    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      border-radius: 0 4px 0 0;
      padding-right: 16px;
    }

    &--sortable {
      cursor: pointer;

      .table__header__content {
        padding-right: 16px;
      }
    }

    &__content {
      position: relative;
    }

    &--dnd {
      padding: 0 6px 0 30px !important;
    }
  }

  &__cell {
    box-sizing: border-box;
    color: $inputColor;
    font-size: $fontSize15;
    min-height: $columnHeight;
    height: auto;
    line-height: 16px;
    padding: 0;
    word-break: break-word;
    white-space: pre-wrap;

    &:first-child {
      border-radius: 0 0 0 4px;
      padding-left: 16px;
    }

    &:last-child {
      border-radius: 0 0 4px 0;
      border-right: 0;
      padding-right: 16px;
    }
  }

  &__header,
  &__cell {
    display: inline-flex;
    align-items: center;
    text-align: left;
    padding: 0 6px;
    flex: 1 1;

    &.table-select {
      margin-left: 10px;
    }
  }

  &-select {
    .input-wrapper {
      flex: unset;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  &__no-data {
    padding: 10px;
    [role='cell'] {
      color: $textDefaultColor;
      border: 0;
      font-weight: bold;
      font-size: 1rem;
    }
  }

  &__edit {
    width: 18px;
    height: 18px;
    cursor: pointer;
    background: url('../../../assets/icon_edit.svg');
    &--disabled {
      background: url('../../../assets/icon_edit_disabled.svg');
      pointer-events: none;
    }
  }

  &__view {
    width: 18px;
    height: 18px;
    cursor: pointer;
    margin: 0 auto;
    background: url('../../../assets/icon_eye_blue.svg') no-repeat 100%;
  }

  &__link {
    width: 20px;
    height: 18px;
    cursor: pointer;
    margin: 0 auto;
    background: url('../../../assets/icon_link_blue.svg') no-repeat 100%;

    &--expandable {
      transform: translateX(-27px);
    }
  }

  &__duplicate {
    width: 19px;
    height: 22px;
    cursor: pointer;
    background: url('../../../assets/icon_duplicate.svg') 100% 100%;

    &--disabled {
      background: none;
    }

    &--grey {
      background: url('../../../assets/icon_duplicate_grey.svg') 100% 100%;
    }
  }

  &__open {
    width: 22px;
    height: 22px;
    cursor: pointer;
    background: url('../../../assets/icon_launch_blue.svg');
  }

  &__star {
    width: 22px;
    height: 22px;
    cursor: pointer;
    background: url('../../../assets/icon_star_blue.svg');

    &--empty {
      background: url('../../../assets/icon_star_empty.svg');
    }
  }

  &__remove {
    margin-right: 20px;
    cursor: pointer;
  }

  &__delete {
    margin-left: auto;
    margin-right: 20px;
    min-width: 16px;
    cursor: pointer;
  }

  &__search {
    margin-bottom: 30px;
  }

  &__lock {
    width: 16px;
    height: 21px;
    cursor: default;
    background: url('../../../assets/icon_lock_yellow.svg');

    &--expandable {
      transform: translateX(-27px);
    }
  }

  .custom-column {
    & + .td .table__actions {
      margin-right: 15px;
      margin-left: auto;
    }
  }

  &__icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    svg {
      width: 20px;
      height: 20px;

      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }

  .tfoot {
    position: sticky;
  }

  &--compact {
    .table__head {
      > .table__row > .table__header {
        height: 40px;
      }

      .table__checkbox {
        height: auto;
      }
    }
  }
}
