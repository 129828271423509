@import '../../styles/constants.scss';

.radio {
  cursor: pointer;
  position: relative;
  user-select: none;

  &__description {
    color: $hoveredMenu;
    font-size: $fontSize14;
    margin-top: 0.25rem;
    font-weight: normal;
  }

  .hint {
    margin: -9px 0 12px 28px;
  }

  label {
    cursor: inherit;
  }

  .label-wrapper {
    color: $white;
    font-size: $inputFontSize;
    letter-spacing: $letterSpacing05;
    text-transform: none;
    margin-bottom: 20px;
    width: 100%;
    font-weight: normal;

    &__label {
      margin-left: 9px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  #radio:checked::before,
  input[type='radio'] {
    clip: rect(0, 0, 0, 0);
    clip: rect(0 0 0 0);
    position: absolute;
  }

  #radio:checked,
  input[type='radio'] + .label-wrapper::before {
    content: url('../../assets/icon_radio_unselected.svg');
    min-width: 20px;
    height: 20px;
  }

  input[type='radio']:checked + .label-wrapper::before {
    content: url('../../assets/icon_radio_selected_blue.svg');
    min-width: 20px;
  }

  &.blue {
    .label-wrapper {
      color: $inputColor;
      font-size: $fontSize15;
      letter-spacing: 0.01em;
      margin-bottom: 15px;
      padding: 0;
      text-transform: none;

      span {
        align-items: center;
        display: flex;
        line-height: 1;
      }
    }
    #radio:checked,
    input[type='radio'] + .label-wrapper::before {
      content: url('../../assets/icon_radio_unselected_grey.svg');
    }
    input[type='radio']:checked + .label-wrapper::before {
      content: url('../../assets/icon_radio_selected_blue.svg');
    }
  }

  &--disabled {
    pointer-events: none;
    cursor: default;
    @include disabled();
    #radio:checked,
    input[type='radio'] + .label-wrapper::before {
      content: url('../../assets/icon_radio_unselected_grey.svg');
    }
  }

  &--tooltip-enabled {
    pointer-events: auto;
  }
}
