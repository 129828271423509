@import '../../styles/constants.scss';

.input-group {
  position: relative;
  display: grid;

  .no-input-padding {
    padding-top: 0;
    padding-bottom: 0;
    .prefix {
      left: unset;
    }

    .wrapper__prefix input {
      padding-left: 0;
    }

    .input-wrapper--has-prefix input {
      padding-left: 8px;
    }
  }

  + .input-group {
    margin-top: $paddingGeneric * 0.5;
  }

  &--error {
    margin-bottom: $paddingGeneric;
  }

  &__label {
    margin-bottom: 0;
  }

  &__elements {
    grid-column: 1/-1;
    display: grid;

    > :last-child {
      border-top-right-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
    }
  }

  &__element {
    border: 1px solid $hoveredMenu;
    flex: 1 1 100%;
    display: flex;
    background-color: $white;
    min-height: 40px;
    border-top-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;

    &.error-border {
      border: 1px solid $red;
    }

    & .checkbox {
      height: 40px;
    }

    + .input-group__element {
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .dropdown-preview-selected {
      min-height: 40px;
      padding: 6px 26px 6px 15px;
      background-position: calc(100% - 21px) 1em, calc(100% - 16px) 1em,
        calc(100% - -3.5em) -0.5em;
    }

    // Style orverrides

    & label:not(.drop-down) {
      margin: auto;
      padding: 0 8px;
    }

    .checkbox label:not(.drop-down) {
      padding: 0;
    }

    & > div {
      padding: 8px;
    }

    & > .input-group-arrows {
      padding: 0;
    }

    .wrapper {
      flex: 1 1 auto;
    }

    .input-wrapper {
      display: block;
      & > .select {
        height: 100%;
      }
    }

    .checkbox {
      align-content: center;
    }
  }

  &__element.error-right-border {
    border-right: 1px solid $red;
  }

  &__hint {
    @include general-label;
    margin-top: 0.25rem;
    margin-left: auto;
    margin-bottom: 0;
  }

  &__error {
    @include errorMessage();
    position: absolute;
    bottom: -22px;
    right: 0;

    &:empty {
      display: none;
    }
  }

  // Style overrides

  .input-wrapper {
    input {
      padding: 6px 15px;
      min-height: 40px;
      background: $white;
      height: 40px;
      font-size: $fontSize15;
    }

    .select {
      min-height: 40px;
      padding: 6px 26px 6px 15px;
      background-position: calc(100% - 21px) 1em, calc(100% - 16px) 1em,
        calc(100% - -3.5em) -0.5em;
    }
  }

  .confirm-input {
    padding: 0;
    &__field {
      border: none;
      outline: none;

      color: $textColor;
    }

    &--error {
      .confirm-input__field {
        border: none;
        color: $red;
      }
    }
  }

  .select {
    border: none;
    background-color: $white;

    .checkbox {
      height: unset;
    }
  }
  &--completed {
    .input-group__element {
      border-color: $colorButton;
    }
  }
}

.input-group-arrows {
  max-width: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__arrow {
    cursor: pointer;

    &:hover,
    &:focus {
      transform: scale(1.1);
    }

    &--up {
      transform: rotate(180deg);

      &:hover {
        transform: rotate(180deg) scale(1.1);
      }
    }

    &--disabled {
      path {
        fill: $colorDisabled;
      }
    }

    &--disabled:hover {
      transform: none;
    }

    &--disabled#{&}--up:hover {
      transform: rotate(180deg);
    }
  }
}
