.three-q-loader {
  height: 100%;
  width: 100%;
  position: absolute;

  &--inline {
    position: relative;
    display: block;
  }
}
