@import '../../styles/constants.scss';

.textarea {
  position: relative;

  .input-wrapper {
    align-items: flex-start;
    height: 100%;
    &--has-suffix {
      textarea {
        padding-right: 40px;
      }
    }

    &--white {
      .wrapper__prefix {
        display: flex;

        textarea {
          background-color: $white;
          border: 1px solid $colorButton;

          &:placeholder-shown {
            border: 1px solid $colorSecondaryText;
          }
        }
      }

      .wrapper__bottom {
        .error {
          font-size: $fontSize10;
          letter-spacing: 0;
        }
      }
    }

    &--empty {
      .wrapper__prefix {
        textarea {
          border-color: $colorSecondaryText;
        }
      }
    }

    &--has-warning {
      textarea {
        padding-right: 70px;
      }
    }
    &--label-top {
      flex-direction: column;
      align-items: flex-start;

      .label-wrapper {
        width: 100%;
        font-weight: $fontBold;
        font-size: $fontSize11;
        line-height: 1rem;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: $inputColor;
      }

      .wrapper__prefix {
        width: 100%;
      }

      & + .count {
        color: $inputColor;
        width: 100%;
      }
    }

    .wrapper__prefix {
      height: 100%;
    }
  }

  .label-wrapper {
    padding-top: 11px;
  }

  .suffix {
    position: absolute;
    right: 10px;
  }

  textarea {
    background: $inputBackground;
    border-radius: $borderRadius;
    font-size: $inputFontSize;
    color: $inputColor;
    font-family: inherit;
    padding: 10px 14px;
    width: 100%;
    height: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    box-sizing: border-box;
    resize: none;
    border: 0;
    &::placeholder {
      color: $inputDisabledColor;
      font-size: $inputFontSize;
    }

    &[disabled] {
      background: $inputDisabledBackground;
      color: $inputDisabledColor;
    }

    &.error-placeholder::-moz-placeholder {
      color: $red;
    }

    &.error-placeholder:-ms-input-placeholder {
      color: $red;
    }

    &.error-placeholder::-ms-input-placeholder {
      color: $red;
    }

    &.error-placeholder::placeholder {
      color: $red;
    }

    &.error-placeholder:-moz-placeholder {
      color: $red;
    }

    &.error-placeholder::-webkit-input-placeholder {
      color: $red;
    }
  }

  .textarea-close__icon {
    position: relative;
    border: 1px solid transparent;
    background-color: transparent;
    display: inline-block;
    vertical-align: middle;
    outline: 0;
    padding-left: 4px;
    padding-right: 0;
    cursor: pointer;

    & img {
      width: 16px;
      height: 16px;
    }
  }
}


