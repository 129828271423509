@import '../../../../../../../styles/constants.scss';

.campaign-exclusions-create-actions {
  width: 100%;
  display: flex;
  box-sizing: border-box;

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  &__action-buttons {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    box-sizing: border-box;
  }

  &__save {
    margin: auto 0 auto 16px;
    font-size: $fontSize11;
    color: $textColor;
    height: 36px;

    .button__value {
      padding: 10px;
    }

    &__label {
      margin-left: 10px;
    }
  }

  &__cancel {
    margin: auto 0 auto 16px;
    font-size: $fontSize11;
    color: $textColor;
    height: 36px;
  }

  &__delete {
    margin: auto 0 auto 16px;
    font-size: $fontSize11;
    color: $textColor;
    height: 36px;
    &__label {
      margin-left: 10px;
    }
  }
}
