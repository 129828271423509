@import '../../styles/constants.scss';

.dropdown-list {
  &__options {
    display: none;
    width: 100%;
    position: absolute;
    top: calc(100% + 1px);
    background-color: $sectionBackground;
    box-shadow: $flowBoxShadow;
    max-height: $dropdownHeight;
    overflow: auto;
    overflow-anchor: none;
    cursor: pointer;

    &--standalone {
      display: block;
      overflow-y: auto;
      visibility: visible;
      position: relative;
      top: unset;
      box-shadow: none;
      border-bottom: 1px solid $colorFlowBorders;
    }

    &--sorted {
      // Divider between selected and non-selected items
      div[role='option'].selected + div[role='option']:not(.selected) {
        border-top: 1px solid $colorFlowBorders;
      }
    }

    &--open {
      display: block;
      overflow-y: auto;
      visibility: visible;
      z-index: $zIndexOverlay;
    }
    &--up {
      top: unset;
      bottom: calc(100% + 5px);
      visibility: visible;
    }

    div[role='option'] {
      padding: 8px 16px;
      line-height: 1;
      font-size: $fontSize15;

      &.sorted-to-top {
        border-bottom: 1px solid $colorFlowBorders;
      }
      &.highlighted {
        background: $inputHover;
      }
      &.unselectable {
        opacity: 0.5;
      }
      &.section-divider {
        background: #eff6f8;
        border: 1px solid #dbdbdb;
        border-left: 0;
        border-right: 0;
      }
      &.hidden {
        padding: 0;
        &.unselect {
          display: none;
        }
      }
      &:hover {
        background: $inputHover;
      }
      &.hasLabel {
        padding-top: 30px;
        position: relative;
      }
      .checkbox .label-wrapper {
        font-size: $fontSize15;
        width: 100%;
      }
    }

    &--multi-select {
      .checkbox-select-all {
        width: 100%;
        box-sizing: border-box;
        padding: 8px 16px;
        gap: 11px;

        &__icon {
          width: 20px;
          height: 20px;

          svg {
            width: 20px;
            height: 20px;
          }
        }

        &__label {
          text-transform: none;
          font-size: $fontSize15;
          font-weight: normal;
        }
      }
      div[role='option'] {
        padding: 0;
      }
    }
  }

  &__no-results {
    padding: 8px 16px;
    opacity: 0.9;
    cursor: default;
  }

  &__section-label {
    position: absolute;
    top: 6px;
    font-size: $fontSize11;
    opacity: 0.7;
  }

  &__description {
    font-size: $fontSize14;
    color: $hoveredMenu;
    margin-top: 6px;
    white-space: pre-wrap;
  }

  &--opened .select__options {
    z-index: $zIndexOverOverlay;
  }

  &--disabled {
    color: $inputDisabledColor;

    &::after {
      filter: opacity(0.35);
    }
  }

  .checkbox__label {
    flex-direction: row-reverse;
    .label-wrapper {
      // allow longer labels fit in two rows
      width: calc(100% - 19px);
      text-transform: capitalize;
      font-weight: normal;
      font-size: $fontSize15;
    }
  }

  .dropdown--bold {
    font-weight: bold;
  }

  .dropdown--pointer {
    pointer-events: none;
  }

  .dropdown--placeholder {
    color: $inputPlaceholderColor;

    &--error {
      color: $red;
    }
  }

  .dropdown--icon {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  input[type='checkbox'] {
    margin: 0 15px 0 0;
  }

  &__toggles {
    border-bottom: 1px solid $colorFlowBorders;
    &[role='option'] {
      padding: 0;
    }
  }

  &__tiles {
    display: flex;
    flex-wrap: wrap;
  }

  &__tile {
    background: $grey;
    margin: 5px 5px 5px 0;
    padding: 10px 15px;
    border-radius: 18px;
    color: $white;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1rem;
    display: flex;
    img {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  &__search {
    margin: 0;
    border: 0;
    padding: 0 20px 0 0;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    background: inherit;
    line-height: inherit;
    color: $inputColor;
    font-size: $inputFontSize;
    width: 100%;
    &:focus {
      outline: none;
    }

    &--bottom {
      position: relative;
      height: 44px;
      display: flex;
      align-items: center;

      input {
        padding-left: 46px;
        font-size: $inputFontSize;
        font-family: inherit;
        text-indent: 25px;
        &::placeholder {
          color: $inputPlaceholderColor;
          font-size: inherit;
        }
      }

      .search-icon {
        position: absolute;
        left: 15px;
      }
    }
  }

  &__separator {
    height: 1px;
    margin: 0;
    width: 100%;
    background: $colorFlowBorders;

    &--with-deselect {
      margin: 0;
      width: auto;
    }
  }
}
