@import '../../styles/constants.scss';

.teams {
  .static_info {
    font-size: $fontSize17;
    margin: 4px 0 0;
  }

  &__global {
    margin-top: 37px;

    .checkbox {
      &__label {
        flex-direction: row-reverse;
      }
    }

    .label-wrapper {
      padding-left: 11px;
    }
  }

  .align-row + .align-row {
    margin-top: 20px;
  }

  .users {
    .heading-secondary {
      width: 100%;
    }

    .align-row.align-right {
      margin-bottom: 30px;
    }
  }

  // .form__section:last-child {
  .form__section:last-of-type {
    border-bottom: none;
  }

  &__unlink-modal {
    .modal__content {
      max-width: 520px;
    }
  }

  &__bordered {
    border-bottom: 1px solid $colorFlowBorders;
  }

  &__hint {
    color: $colorSecondaryText;
    font-size: 0.625rem;
  }

  .filters {
    .filters--container {
      overflow: unset;
    }
  }
}
