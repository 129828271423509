.alert-start-time {
  display: flex;
  flex-direction: row;
  gap: 23px;

  .input-group {
    &__elements {
      height: 48px;
    }
    &__element > div {
      padding: 12px 16px;
    }

    .input-wrapper {
      .select {
        padding: 16px 12px;
      }
      input {
        padding: 0;
        min-height: 24px;
        height: 24px;
      }
    }

    .select__options {
      input {
        padding: 6px 15px;
      }
    }
  }

  & > .input-wrapper {
    &:first-of-type {
      flex-grow: 0;
    }

    .date {
      top: 0;
    }
  }

  &-info {
    margin-top: 10px;
  }
}
