@import '../../styles/constants.scss';

.entity-selector {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 100%;

  &__filters,
  &__actions {
    padding: 12px;
    border-bottom: 1px solid $colorFlowBorders;
  }

  &__filters {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 30px;
  }

  &__actions {
    display: grid;
    grid-template-columns: min-content 1fr;
    gap: 8px;
  }

  &__extra-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;
  }

  .table {
    width: 100% !important;
    border-radius: 0;
    border: none;

    .thead > .tr > .th[role='columnheader'] {
      height: 40px;
    }

    .table__checkbox {
      height: auto;
    }

    .tr {
      .td {
        flex-wrap: wrap;
        overflow: hidden;
      }
    }
  }
}

.cluster-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .entity-field {
    width: 49%;
  }
}

.filter-active {
  background-color: $colorButton;
}

.filter-disabled {
  opacity: 0.7;
  cursor: initial;
}
