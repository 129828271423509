@import '../../styles/constants.scss';

@mixin alignedContent {
  width: calc(50% / 6);
}

@mixin labelContent {
  width: 50%;
  align-items: center;
  height: $rowHeight;
  padding-left: 16px;
}

$rowHeight: 32px;

.permissions {
  width: 100%;
  border-spacing: 0;

  &--view {
    opacity: 0.5;
  }

  box-shadow: $sectionBoxShadow;

  th:first-child {
    border-top-left-radius: $borderRadius;
  }
  th:last-child {
    border-top-right-radius: $borderRadius;
  }

  &__heading {
    background: $grey;
    border-radius: 4px 4px 0 0;
    height: $rowHeight;

    &__header {
      font-size: $fontSize11;
      color: $white;
      text-transform: uppercase;
    }
  }

  &__category {
    background: $colorAltBackground;

    &__label {
      color: $inputColor;
      font-weight: $fontBold;
      text-transform: uppercase;
      font-size: $fontSize11;
      @include labelContent();
    }
    &__check {
      text-align: center;
      vertical-align: middle;
      &__icon {
        display: none;
        cursor: pointer;
        margin: 0 auto;
      }
    }
    &:hover & {
      &__check__icon {
        display: block;
      }
    }
  }
}

.permission {
  width: 100%;
  background-color: $sectionBackground;

  &__label {
    @include labelContent();
  }

  &__item {
    text-align: center;
    @include alignedContent();
    &--disabled &__icon {
      cursor: not-allowed;
    }

    &__icon {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }

    &--view &__icon {
      cursor: default;
    }
  }

  &:nth-child(2n) {
    background: $hoveredMenuItem;
  }
}

.permission-filter {
  &__types {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    &__item {
      flex: 0 0 1;
      margin-left: 16px;
      user-select: none;
      display: flex;
      flex-wrap: nowrap;
      color: $inputColor;
      cursor: pointer;
      font-size: $fontSize14;
      vertical-align: middle;
      &:hover {
        color: $colorButton;
      }
    }

    &__icon {
      display: inline-block;
      margin-left: 4px;
      $iconSize: 18px;
      width: $iconSize;
      height: $iconSize;
    }
  }
}

.permissions-legend {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-around;

  &__item {
    display: inline-flex;
    align-items: center;
    img {
      display: block;
      margin-right: 6px;
    }
    & + & {
      margin-left: 8px;
    }
  }
}
