@import './constants.scss';

.unauthorized-page {
  .img-left-180 {
    position: absolute;
    top: 100px;
    left: 180px;
  }

  .title {
    line-height: 100%;
    color: $textColor;
    margin: 5px 0;

    &__font {
      font-family: $fontFamilySriracha;

      &-90 {
        font-size: 5.625rem;
        margin-bottom: 20px;
      }

      &-100 {
        font-size: 6.25rem;
      }
    }
  }

  .info-text {
    font-family: $fontFamily;
    font-size: $fontSize28;
    margin-bottom: 15px;
    line-height: 75%;

    a {
      color: $colorButton;
      margin-left: 5px;
      font-weight: $fontBold;
      text-decoration: none;
    }
    &.font-size-21 {
      font-size: $fontSize21;
    }
  }
}
