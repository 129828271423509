.config {
  width: 60%;

  button {
    margin-top: 20px;
  }

  &__item {
    h4 {
      margin-bottom: 0;
      margin-top: 30px;
    }
  }

  .input-wrapper {
    margin-bottom: 22px;
  }
}

.client-actions {
  margin-bottom: 50px;

  &__buttons {
    display: flex;
    flex-direction: row;
    .button {
      margin-right: 10px;
    }
  }
}
