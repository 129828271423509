@import '../../styles/constants';

$padding: 10px;

.template {
  position: relative;
  width: 100%;
  &__text {
    display: inline-block;
    border: 1px solid $colorBorder;
    padding: $padding;
    white-space: pre-wrap;
    background: $inputBackground;
    min-height: 24px;
    width: 98.5%;

    &--error[contenteditable][placeholder]:empty:before {
      color: $red;
    }

    &--error {
      border-color: $red;
    }

    &.show {
      color: transparent;
    }
  }

  &__hidden {
    display: flex;
    position: absolute;
    top: 1px;
    z-index: -1;
    height: calc(100% - 2px);

    span {
      visibility: hidden;
      padding-left: $padding;
    }

    &.show {
      z-index: $zIndexFixedElements;

      span {
        visibility: visible;
        padding: 10px;
        word-break: break-all;
      }
    }
  }

  .suggestions {
    input {
      height: 42px;
      padding: $padding;
      outline: 0;
      border: 1px solid $colorBorder;
      border-top: 0;
      border-bottom: 0;
      appearance: none;
      outline: none;
    }
  }

  &__validity {
    margin-top: 10px;
    font-size: 0.875rem;

    span {
      color: $colorSuccess;

      &.invalid {
        color: $red;
      }
    }
  }

  &__label {
    width: 100%;
    font-weight: $fontWeight700;
    font-size: $fontSize11;
    line-height: 1rem;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: $textColor;
  }
}
