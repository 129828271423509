@import './fonts.scss';
@import './constants.scss';
@import './table.scss';
@import './form.scss';
@import './404.scss';
@import './margin.scss';
@import './padding.scss';
@import './cursor.scss';
@import './svg.scss';

body,
html {
  margin: 0;
  font-size: 100%;
  font-family: $fontFamily;
  font-weight: normal;
  color: $textDefaultColor;
  background: $colorBackground;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: $fontFamily;
}

main {
  box-sizing: border-box;
  height: calc(100vh - #{$headerHeight});
  overflow-x: auto;
  overflow-y: scroll;
  padding: 35px $paddingRightBody $stickyFooterHeight + 10 56px;
  width: 100%;
  position: relative;
  background-color: $lightTextColor;
}

.heading {
  @include headingStyles();

  &__buttons {
    display: flex;
    margin-left: auto;
    gap: 8px;

    .button {
      margin-top: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.app-container {
  display: flex;
  overflow: hidden;
}

.heading-secondary {
  @include headingStyles();
  font-size: 1.75rem;
  margin-bottom: 30px;

  .button {
    margin-left: auto;
  }

  &.no-margin-bottom {
    margin-bottom: 0;
  }
}

.standard-container {
  background: $sectionBackground;
  border-radius: $borderRadius;
  box-shadow: $sectionBoxShadow;
  padding: 20px 30px;
  position: relative;
}

a,
.fake-link {
  @include linkStyles();

  &--black {
    color: $inputColor;

    &:hover {
      color: $black;
    }
  }
}

input,
.select {
  font-family: $fontFamily;
  user-select: none;
}

.error {
  color: $red;
  font-size: 1rem;
  margin-top: 10px;

  // General error shown on top of the Create/Update forms
  &--general {
    margin: 0 0 10px;
  }
}

// general flex classes
.align-row {
  display: flex;
  flex-direction: row;

  &.wrap {
    flex-wrap: wrap;
  }

  &.wrap.twin {
    justify-content: space-between;
    width: 100%;

    & > * {
      min-width: 47%;

      &:nth-child(n + 3) {
        margin-top: 30px;
      }
    }
  }

  &.center {
    align-items: center;
  }

  .button + .button,
  .drop-down + .button {
    margin-left: $paddingGeneric * 0.5;

    &.align-right {
      margin-left: auto;
    }
  }

  .wrap .button + .button {
    margin-left: 0;
  }

  &.align-right {
    width: 100%;
    justify-content: flex-end;
  }

  &.align-left {
    width: 100%;
    justify-content: flex-start;
  }

  &.grow {
    flex-grow: 1;
  }
}

.align-column {
  display: flex;
  flex-direction: column;

  &.center {
    align-items: center;
    justify-content: center;
  }

  &.reverse {
    flex-direction: column-reverse;
  }

  &.half {
    flex-basis: 47%;
  }
}

.extra-bold {
  text-shadow: 0.3px 0.3px 0.5px black;
}

.align-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.align-baseline {
  align-items: baseline;
  display: flex;
}

.align-items-center {
  align-items: center;
}

.gap {
  &-4 {
    gap: 4px;
  }

  &-8 {
    gap: 8px;
  }

  &-10 {
    gap: 10px;
  }

  &-16 {
    gap: 16px;
  }

  &-40 {
    gap: 40px;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.font-bold {
  font-weight: $fontBold;
}

// general top label for elements in forms
.general-label {
  color: $alertResultsColor;
  font-weight: $fontBold;
  font-size: $fontSize11;
  letter-spacing: 0.07em;
  line-height: 1rem;
  margin-bottom: 8px;
  text-transform: uppercase;
  display: block;

  &--no-bottom-margin {
    margin-bottom: 0;
  }

  &--no-top-margin {
    margin-top: 0;
  }

  & + .form__row {
    margin-top: 0;
  }

  &--error {
    color: $red;
  }

  &--hint {
    color: $colorDisabled !important; // !important is needed to override other stylings like .flow.general-label
  }

  &--capitalized {
    text-transform: capitalize;
    font-size: $fontSize21;
    font-weight: 400;
  }

  &__description {
    font-size: $fontSize14;
    text-transform: none;
    font-weight: normal;
    color: #7a7c7c;
  }
}

.general-title {
  margin-top: 0;
  font-weight: 300;
}

.general-name {
  font-size: $fontSize17;
  letter-spacing: 0.2px;
  color: $inputColor;
  margin-bottom: 16px;

  &--no-bottom-margin {
    margin-bottom: 0;
  }
}

.general-label-blue {
  color: $colorButton;
  font-size: 0.9375rem;
  font-weight: 700;
  letter-spacing: $letterSpacing03;
  overflow-wrap: normal;
  text-decoration: none;
}

.general-description {
  color: $colorSecondaryText;
  font-size: $fontSize14;
  line-height: 20px;
}

.general-small-description {
  color: $inputColor;
  font-weight: $fontBold;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.07em;
  opacity: 0.5;
  text-align: right;
  text-transform: uppercase;

  &--generic-opaque-grey {
    text-align: left;
    text-transform: none;
    color: $colorSecondaryText;
    opacity: 1;
  }
}

.border-right {
  border-right: 1px solid $grey;
}

.border-right-light {
  border-right: 1px solid $colorFlowBorders;
}

.border-left {
  border-left: 1px solid $colorFlowBorders;
}

.border-left-black {
  border-left: 1px solid $black;
}

.border-bottom-black {
  border-bottom: 1px solid $black;
}

.color-red-important {
  color: $red !important;
  & > * {
    color: $red !important;
  }
}

.max-height {
  max-height: $maxHeight;
  overflow-y: auto;
}

.reporting {
  padding: 0;
  height: calc(100vh - #{$headerHeight});
  // I dont like to use !important but we need because we have Iframe ;(
  overflow: hidden !important;

  &-iframe {
    width: inherit;
    height: inherit;
    border: none;
  }

  &--empty {
    padding: 35px $paddingRightBody 100px 56px;
  }

  &__page {
    display: flex;
    flex-direction: column;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  #reporting {
    overflow: auto;
  }
  section {
    height: inherit;
    width: inherit;
  }
}

.qa-forms-fill-out {
  &::-webkit-scrollbar {
    display: none;
  }
}

.text-space {
  &--before {
    &::before {
      content: '\00a0';
    }
  }

  &--after {
    &::after {
      content: '\00a0';
    }
  }

  &--both {
    @extend .text-space--before;
    @extend .text-space--after;
  }
}

.capitalize {
  text-transform: capitalize;
}

.disabled {
  @include disabled('not-allowed');
}

.enumeration {
  & + & {
    &:before {
      content: ', ';
    }
  }
}

.static-info {
  font-size: $fontSize17;
}

.grey-disabled {
  &:disabled {
    background-color: $colorDisabled;
    border-color: $colorDisabled;
    color: $white;
  }
}

.light-grey-disabled {
  &:disabled {
    background-color: $cardDisabled;
    border-color: $cardDisabled;
    color: $white;
    opacity: unset !important;
  }
}

.cursor {
  &--default {
    cursor: default;
  }

  &--pointer {
    cursor: pointer;
  }
  &--disabled {
    cursor: not-allowed;
  }
}

.input-hidden {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.display {
  &-block {
    display: block;
  }

  &-inline-block {
    display: inline-block;
  }

  &-flex {
    display: flex;
  }

  &-flex-column {
    display: flex;
    flex-direction: column;
  }

  &-none {
    display: none;
  }
}

.view-only {
  opacity: 0.6;
  cursor: default;
}

.missing-item {
  font-size: $fontSize17;
  font-style: italic;
  color: $colorSecondaryText;

  &--small {
    font-size: $fontSize14;
  }
}

// Helper class to render teal border and checkmark in right bottom corner. Sill WIP. Does not work on img tag
.selectable-item {
  position: relative;

  &--selected {
    $border-width: 4px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: $zIndexFixedElements;
      @include selected-border;
    }
  }
}

h3.generic-heading {
  font-size: $fontSize21;
  font-weight: 300;
  color: $textColor;
  line-height: 130%;
  letter-spacing: 0.003em;
  margin: 0 0 15px 0;

  &--light {
    font-weight: normal;
  }

  &--no-margin {
    margin: 0;
  }

  &--margin-top {
    margin: 15px 0;
  }
}

.secondary-text {
  font-size: $fontSize14;
  color: $colorSecondaryText;
  line-height: 140%;
}

.text-muted {
  color: $colorButtonGrey;
}

.width {
  &-100 {
    width: 100%;
  }
  &-50 {
    width: 50%;
  }
}

.height {
  &-100 {
    height: 100%;
  }
  &-50 {
    height: 50%;
  }
}

.max-width-500 {
  max-width: 500px;
}

.bold {
  font-weight: $fontBold;
}

.qr-code {
  svg path {
    fill: $black;
  }

  svg rect {
    fill: $white;
  }
}

.svg {
  &--red {
    fill: map-get(map-get($map: $buttonColorMap, $key: attention), $key: base);

    path {
      fill: map-get(
        map-get($map: $buttonColorMap, $key: attention),
        $key: base
      );
    }
  }

  &--disabled {
    fill: $inputDisabledBackgroundDark;

    path {
      fill: $inputDisabledBackgroundDark;
    }
  }

  &--blue {
    fill: $colorButton;

    path {
      fill: $colorButton;
    }
  }

  &--white {
    fill: $white;

    path {
      fill: $white;
    }
  }

  &--black {
    fill: $black;

    path {
      fill: $black;
    }
  }

  &--liliac {
    fill: $colorLiliac;

    path {
      fill: $colorLiliac;
    }
  }
}

.text-ellipsis {
  @include textEllipsis();
}

.nowrap {
  white-space: nowrap;
}

.animations-disabled {
  animation-duration: 0s !important;
  transition: none !important;
}

.color-text {
  color: $textColor;
}

.italic-text {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.font-size {
  &-11 {
    font-size: $fontSize11;
  }
  &-15 {
    font-size: $fontSize15;
  }
}

.check-embedded-url {
  height: 5px;
}

.flip {
  &--horizontal {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}

.half-width {
  width: 50%;
  flex-basis: 50%;
  flex-grow: 0;
}

.placeholder-text {
  color: $colorSecondaryText;
}

.hoverable {
  border-bottom: 1px dashed;

  &:hover {
    border-bottom-style: solid;
  }
}

.rotation-transition {
  position: relative;
  overflow: hidden;
  width: 30px;
  height: 15px;

  &__before {
    opacity: 0;
    position: absolute;
    animation: opacity-ease-out;
    animation-duration: 500ms;
  }

  &__after {
    opacity: 1;
    position: absolute;
    animation: opacity-ease-in;
    animation-duration: 500ms;
  }
}

.spinning-animation {
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(-360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(-360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.pass-eye + keeper-lock {
  left: 388px !important;
}

.list {
  display: flex;
  flex-direction: column;

  &__button {
    position: relative;
    margin: 10px 0;
    border-radius: $borderRadius;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: $colorButton;

    &__svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 12px;
      height: 12px;
      background: url('../assets/icon_plus.svg') no-repeat 50%;
    }
  }
}

.round-solid-background {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $black;
  color: $white;
  font-size: $fontSize11;
  font-weight: $fontBold;
  border-radius: 100%;
  width: 18px;
  height: 18px;
}

.info--icon-tooltip {
  font-size: $fontSize13;
  font-weight: 600;
  padding: 12px 16px;
  text-transform: none;
  max-width: 500px;
  text-wrap: wrap;
  text-align: left;
}

.blurry {
  filter: blur(6px);
}
