@import '../../../../styles/constants.scss';

.alerts-table {
  .table-wrapper {
    overflow: visible;
  }

  .table-wrapper--paginated .table {
    overflow: visible;
  }
}

.alert-edit-section {
  .dropdown--placeholder {
    color: unset;
  }
}

.alert-value-cell {
  position: relative;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &__icon {
    display: none;
    margin: auto 8px;
  }

  &:hover {
    border: 1px solid var(--text-forms-disabled-text, $inputDisabledColor);
    border-radius: 4px;

    .alert-value-cell__icon {
      display: block;
    }
  }

  &__options {
    position: absolute;
    top: calc(100% + 5px);
    border-radius: $borderRadius;
    background-color: $sectionBackground;
    box-shadow: $flowBoxShadow;
    cursor: pointer;
    width: 150px;
    z-index: $zIndexOverOverlay;

    &--up {
      top: unset;
      bottom: calc(100% + 5px);
    }
  }

  &.dropdown-open {
    border: 1px solid var(--text-forms-disabled-text, $inputDisabledColor);
    border-radius: 4px;

    &__icon {
      display: block;
    }
  }

  &.disabled {
    &:hover {
      border: none;

      &__icon {
        display: none;
      }
    }
  }
}

.triangle-down {
  transform: rotate(180deg);
}
