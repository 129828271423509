@import '../../styles/constants';

.round-rectangle-button {
  border: 1px solid;
  color: $colorButton;
  display: flex;
  height: 32px;
  cursor: pointer;
  border-radius: $borderRadius;

  &__content {
    margin: auto;
  }

  &__blue {
    background-color: $colorButton;
  }

  &__white {
    background-color: $white;
    padding: 5px;
  }

  &__secondary {
    background-color: #5dc88a;
    color: #5dc88a;
  }
}
