@import '../../styles/constants.scss';

.search-input {
  display: inline-flex;
  border: 1px solid $colorButton;
  align-items: center;

  &--disabled {
    @include disabled(not-allowed, 0.5);
  }

  &__icon {
    width: 24px;
    height: 24px;
    padding: 5px;
    border-right: 1px solid $colorButton;
    background: url('../../assets/icon_search.svg') no-repeat 50%;
    background-size: 24px 24px;
  }

  &__input {
    padding: 8px;
    border: none;
    width: 200px;

    &:focus-visible {
      outline: none;
    }
  }
}
