@import '../../../styles/constants';

$variablesMarginRight: 3%;

.variables {
  .recurrence {
    width: 100%;

    &__error {
      justify-content: flex-end;
      margin-right: 5px;
      display: flex;
      font-size: $fontSize14;
    }

    .drop-down {
      max-width: unset;
      &:last-of-type {
        margin-left: 5px;
      }
    }

    &__type {
      min-width: 45%;
      margin: 0 $variablesMarginRight 0 0;
      background: $colorBorder;
      padding: 13px 0 13px 15px;
      border-radius: $borderRadius;
    }
    &__amount {
      width: 50px;
    }
  }

  .form__half {
    margin-right: $variablesMarginRight;
    margin-top: 20px;
  }
  .form__row {
    .recurrence__amount {
      margin-right: 5px;
    }
    &--radio-row {
      margin-bottom: 13px;
      margin-top: 8px;
    }
  }
  &__start-time {
    display: flex;
    align-items: center;

    .label-wrapper {
      font-weight: bold;
    }
    &__reference-time {
      margin-right: 8px;
    }
  }

  &__radio {
    display: flex;
    align-items: center;
    cursor: pointer;

    &__first {
      width: 27%;
      min-width: 27%;
      margin-right: calc(3% - 20px);
    }

    &__label {
      width: 20%;
      padding-left: 0;
    }

    &:last-child {
      flex-grow: 1;
    }

    .input-wrapper .input-text {
      max-width: 50px;
      margin-right: 5px;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      width: 100%;

      .wrapper__bottom {
        position: absolute;
      }
    }
  }

  &__disabled {
    @include disabled();
  }

  &__info {
    font-style: italic;
    font-size: $fontSize14;
    margin-top: 10px;
  }

  &__target-hint {
    color: $colorSecondaryText;
  }
  &__target-section {
    padding-top: 15px;
    display: flex;
    flex-direction: row;
  }
  &__target-time-box {
    width: 176px;
    height: 48px;
    border: 1px solid #7a7c7c;
    box-sizing: border-box;
    border-radius: 4px 0 0 4px;
    text-align: center;
    padding-top: 13px;
    &--right {
      border-radius: 0 4px 4px 0;
      border-left: 0;
    }
  }

  &__label {
    @include smallLabel;
  }
}
