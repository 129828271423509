@import '../../../../../styles/constants.scss';

.kpi-section {
  &__company-filter {
    min-width: 0;

    .select {
      padding: 6px 26px 6px 15px;
      background-color: $white;
      min-height: 32px;
      height: 32px;
      font-size: $fontSize15;
      border-color: $colorSecondaryText;

      &__options {
        .select__search--bottom {
          height: 32px;
        }

        div[role='option'] {
          height: 34px;
          padding: 8px;
          box-sizing: border-box;

          .checkbox-select-all__label,
          .label-wrapper {
            font-size: $fontSize14;
          }
        }

        .select__no-results {
          padding: 8px;
        }
      }
    }
  }
}
