@import '../../styles/constants';

.upload-modal {
  outline: none;

  &__empty-box {
    position: relative;
    display: flex;
    background-color: $white;
    border: 1px solid $white;
    height: 150px;
    width: 100%;
    border-radius: 4px;

    &__overlay-rectangle {
      position: absolute;
      display: flex;
      background-color: $colorButton;
      opacity: 0.1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: $zIndexHighest;
      border-radius: 4px;
    }
  }

  &__dropzone {
    background: $colorCardBackground;
    border: 1px solid $colorButton;
    border-radius: 4px;
    cursor: pointer;
    height: 150px;
    position: relative;
    width: 100%;
    color: $white;
    font-size: $fontSize17;
    text-align: center;
    letter-spacing: $letterSpacing02;
    z-index: 1;

    &--dark {
      height: 72px;
      background: repeating-linear-gradient(
        -45deg,
        #00464d,
        #00383e 2px,
        #00383e 20px
      );
    }

    &.displayed-under {
      border: 1px solid $white;
    }

    &.disabled {
      @include disabled();
      opacity: 0.6;
    }

    span {
      margin-bottom: 7px;
    }

    &__message {
      max-width: 270px;
      word-wrap: break-word;
      overflow: hidden;
      color: $colorButton;
      font-size: $fontSize11;
      text-transform: uppercase;
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: 0.07em;
      &--dark {
        .upload-modal__dropzone__message {
          color: $white;
        }
      }
    }
  }

  &__buttons {
    margin-top: 30px;

    .button {
      margin-right: 16px;
    }
  }

  &__file-limit {
    align-items: center;
    color: $inputPlaceholderColor;
    font-size: 0.875rem;
    letter-spacing: $letterSpacing02;
    margin-top: 16px;

    .button {
      margin-left: auto;
    }
  }

  &__hidden-input {
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: $zIndexOverOverlay;
  }
}

#file-upload-video {
  display: none;
}
