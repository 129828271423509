.homepage {
  display: flex;
  flex-direction: row;
  width: 100%;

  &__shortcuts {
    width: 924px;
    max-width: calc(100% - 516px);
    padding: 20px 24px;

    // Variables
    --grid-layout-gap: 16px;
    --grid-column-count: 2;
    --grid-item--min-width: 200px;

    // Calculate the minimum width of the shortcut cards so that there will be at most 2 columns
    --gap-count: calc(var(--grid-column-count) - 1);
    --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
    --grid-item--max-width: calc(
      (100% - var(--total-gap-width)) / var(--grid-column-count)
    );

    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
    );
    grid-gap: var(--grid-layout-gap);
    row-gap: 20px;

    background-color: #ffffff;
    border-radius: 4px;
    filter: drop-shadow(0px 2px 4px rgba(21, 35, 36, 0.15));
  }

  &__favorites {
    display: flex;
    flex-direction: column;
    gap: 15px;

    padding-left: 24px;
  }
}
