@import '../../../../styles/constants.scss';

.campaign-exclusions-section {
  background: $sectionBackground;
  border-radius: $borderRadius;
  box-shadow: $sectionBoxShadow;
  padding: 20px 0;

  &:first-of-type {
    margin-bottom: 20px;
  }

  > * {
    padding: 0 30px;
    margin-bottom: 16px;
  }

  .line-divider {
    margin-bottom: 16px;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  &__title {
    font-size: $fontSize21;
    font-weight: 400;
  }

  .label-error {
    color: $red;
  }

  .input-group__element > div {
    padding: 0;
  }

  .input-group__element .checkbox {
    height: unset;
  }

  .input-group__element label:not(.drop-down) {
    padding: 0;
  }
}

.campaign-exclusions-rule-title {
  display: flex;
  margin-bottom: 16px;

  > svg {
    height: 30px;
    width: 30px;
  }
}

.campaign-exclusions-rule-subtitle {
  font-size: $fontSize18;
  font-weight: 300;
  margin-left: 10px;
  line-height: 35px;
}

.campaign-exclusions-basic-button {
  width: 75px;
  background-color: white;
  color: black;
}

.campaign-exclusions-basic-button.left-button {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.campaign-exclusions-basic-button.right-button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.button-selected {
  background-color: #0aaebf;
  color: white;
}

.property-type-label-big {
  width: 400px;
}

.property-type-label-small {
  width: 200px;
}

.rule-input-group .disabled {
  opacity: 0.5;
}

.duplicate-information-block {
  width: 50%;
}

.name-information-block {
  width: fit-content;
}

.disabled-exclusion-action {
  @include disabled();
  opacity: 0.2;
}
