@import '../../styles/constants.scss';

.wrapper {
  width: 100%;
  .suffix {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .warning {
    position: absolute;
    right: 45px;
    top: 10px;
  }

  &__bottom {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .error {
      margin-top: 0;
      text-align: right;
      color: $red;
      font-size: 0.875rem;
      max-width: 100%;
      & + .count {
        margin-left: auto;
        color: $red;
      }
      &--bottom {
        position: absolute;
        top: 100%;
      }
    }
  }
}

.prefix {
  color: $inputColor;
  font-size: $inputFontSize;
  left: 12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.error-text {
  color: $red;
}

.input-wrapper {
  display: block;

  .wrapper__prefix {
    position: relative;
    flex-grow: 1;
  }

  & .label-wrapper {
    @include checkBoxLabel;
  }

  input[type='text'],
  input[type='url'],
  input[type='password'],
  input[type='email'],
  input[type='number'],
  input[type='phone'],
  input[type='budget'] {
    appearance: none;
    background: $inputBackground;
    border: 1px solid transparent;
    border-radius: $borderRadius;
    box-sizing: border-box;
    color: $inputColor;
    max-height: 48px;
    font-size: $fontSize17;
    line-height: 140%;
    font-family: inherit;
    height: 100%;
    margin: 0;
    max-width: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: none;
    padding: $inputPadding;
    width: 100%;
    -webkit-user-select: inherit;

    &.error-placeholder::-moz-placeholder {
      color: $red;
    }

    &.error-placeholder:-ms-input-placeholder {
      color: $red;
    }

    &.error-placeholder::-ms-input-placeholder {
      color: $red;
    }

    &.error-placeholder::placeholder {
      color: $red;
    }

    &.error-placeholder:-moz-placeholder {
      color: $red;
    }

    &.error-placeholder::-webkit-input-placeholder {
      color: $red;
    }
  }

  input[type='number'] {
    border: 0;
    border-bottom: 1px solid $colorBorder;
    box-shadow: none;
    -moz-appearance: textfield; // Remove spinner from Firefox number inputs

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::placeholder {
      color: $inputColor;
    }
  }

  &--has-suffix {
    input[type='text'],
    input[type='url'],
    input[type='password'],
    input[type='email'],
    input[type='number'] {
      padding-right: 40px;
    }
  }
  &--has-warning {
    input[type='text'],
    input[type='url'],
    input[type='password'],
    input[type='email'],
    input[type='number'] {
      padding-right: 70px;
    }
  }

  &--disabled {
    input[type='text'],
    input[type='url'],
    input[type='password'],
    input[type='email'],
    input[type='number'],
    input[type='budget'],
    textarea {
      pointer-events: none;
      background: $inputDisabledBackground;
      color: $inputDisabledColor;
      &::placeholder {
        color: $inputDisabledColor;
      }
    }

    .prefix {
      color: $inputDisabledColor;
    }
  }

  &--dark {
    input[type='text'],
    input[type='url'],
    input[type='password'],
    input[type='email'],
    input[type='number'],
    input[type='budget'] {
      padding: 9px 12px;
      color: $white;
      background: $colorCustomGrey;
    }
  }

  &--white {
    input[type='text'],
    input[type='url'],
    input[type='password'],
    input[type='email'],
    input[type='number'],
    input[type='budget'] {
      background-color: $white;
      border: 1px solid $colorButton;
      padding: 10px 8px;
      font-size: $fontSize15;

      &:placeholder-shown {
        border: 1px solid $colorSecondaryText;
        color: $inputPlaceholderColor;
      }

      &::placeholder {
        color: $inputPlaceholderColor;
      }
    }
  }

  &--viewOnly {
    input[type='text'],
    input[type='url'],
    input[type='password'],
    input[type='email'],
    input[type='number'],
    textarea {
      pointer-events: none;
      color: $inputDisabledColor;
      background: $white;
      &::placeholder {
        color: $inputDisabledColor;
      }
    }

    .prefix {
      color: $inputDisabledColor;
    }
  }

  &--search {
    input[type='text'],
    input[type='url'],
    textarea {
      padding-left: 50px;
      &::placeholder {
        opacity: 0.5;
      }
    }
  }

  &--is-bordered {
    input[type='text'],
    input[type='url'],
    input[type='password'],
    input[type='email'],
    input[type='budget'],
    input[type='number'] {
      border-color: var(--text-forms-disabled-text, $inputDisabledColor);
    }
  }

  &--is-filled {
    input[type='text'],
    input[type='url'],
    input[type='password'],
    input[type='email'],
    input[type='budget'],
    input[type='number'] {
      border-color: $colorButton;
    }
  }

  &--has-changes {
    input[type='text'],
    input[type='url'],
    input[type='password'],
    input[type='email'],
    input[type='budget'],
    input[type='number'] {
      border-color: $colorLiliac;
    }
  }
  .pass-eye {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  .search {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
  }

  .empty-out {
    position: absolute;
    right: 24px;
    top: 17px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  &--has-prefix .wrapper__prefix input {
    padding-left: 30px;
  }

  &--label-top {
    flex-direction: column;
    align-items: flex-start;

    .label-wrapper {
      width: 100%;
      font-weight: $fontBold;
      font-size: $fontSize11;
      line-height: $fontSize20;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      color: $inputColor;
    }

    .wrapper__prefix {
      width: 100%;
    }

    & + .count {
      width: 100%;
      color: $inputColor;
    }
  }

  .date {
    position: absolute;
    right: 8px;
    top: 12px;
    user-select: none;
    -webkit-user-drag: none;
  }
  &--uppercase {
    .label-wrapper {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.6875rem;
    }
  }
}

.text-align-right {
  .input-wrapper .input-text {
    text-align: right;
    padding-right: 25px;
  }
}

input::placeholder,
textarea::placeholder {
  color: $inputPlaceholderColor;
  font-size: inherit;
}

textarea {
  resize: none;
}

.input-small {
  .input-wrapper {
    input {
      padding: 7px 8px;
      font-size: $fontSize15;
    }
  }
}
.input-wrapper--error {
  .input-text {
    color: $red;
  }
}
