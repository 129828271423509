@import '../../styles/constants';

.overlay {
  display: none;
  mix-blend-mode: multiply;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zIndexOverlay;

  &--expanded {
    display: block;
  }
}
