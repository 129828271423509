@import '../../../styles/constants.scss';

.clients-accounts-view {
  background-color: $sectionBackground;
  border-radius: $borderRadius;
  box-shadow: $sectionBoxShadow;
  padding: 0 32px 16px;
  display: flex;
  flex-wrap: wrap;

  &__column {
    flex-basis: 33%;

    h4 {
      font-size: $fontSize11;
      text-transform: uppercase;
      margin: 23px 0 8px 0;
    }

    &__item {
      font-size: $fontSize17;
    }
  }
}
