@import '../../styles/constants.scss';

.section {
  background: $sectionBackground;
  border-radius: $borderRadius;
  box-shadow: $sectionBoxShadow;
  position: relative;

  & + & {
    margin-top: 20px;
  }

  &__header {
    padding: $paddingSection;
    padding-right: 77px;
    border-bottom: 1px solid $colorFlowBorders;
    position: relative;

    &__arrow {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:empty {
      display: none;
    }
  }

  &__body {
    padding: $paddingSection;

    & + & {
      border-top: 1px solid $colorFlowBorders;
    }

    &:empty {
      display: none;
    }
  }

  .half-width {
    flex-basis: 47%;
  }
}
