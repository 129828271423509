@import '../../styles/constants';

.bulk-edit {
  .form {
    &__section {
      border-bottom: none;
      &--edit {
        padding-bottom: 0;
      }
    }
  }
  &__actions {
    display: flex;
    &__option {
      display: flex;
      align-items: center;
      gap: 8px;
      height: 32px;

      svg {
        min-width: 18px;
        min-height: 18px;
      }
    }
    &__left {
      display: flex;
      flex-direction: column;
      padding-right: $paddingGeneric;
      flex-basis: 50%;
      width: 25%;
    }
    &__right {
      display: flex;
      flex-direction: column;
      padding-left: $paddingGeneric;
      flex-basis: 50%;
      width: 50%;
    }

    .select__options {
      div[role='option'].unselectable {
        opacity: 1;
        color: $inputDisabledColor;
      }
    }
    .tooltip {
      max-width: 360px;
      white-space: normal;
    }
  }
  .select-granularity {
    .align-right {
      align-items: center;
      .check {
        margin: auto;
      }
    }
  }

  .table {
    min-height: 0;
  }
}
