@import '../../styles/constants';

.information-block {
  width: 100%;
  display: flex;
  border: 1px solid;
  border-radius: $borderRadius;
  box-sizing: border-box;

  & + & {
    margin-top: 8px;
  }

  .general-description {
    padding: 7px 10px;
  }

  .image-box {
    margin: 5px 0;
    padding: 0 5px;
    display: flex;
    border-right: 1px solid;

    .image {
      margin: auto;
    }
  }

  &.information-block-error {
    border-color: $red;

    .image-box {
      border-color: $red;
    }
  }

  &.information-block-warning {
    border-color: $colorWarning;

    .image-box {
      border-color: $colorWarning;
    }
  }
  &.information-block-info {
    margin-top: 10px;
    border-color: $publishingStatus;

    .image-box {
      border-color: $publishingStatus;
    }
  }
  &.information-block-simulation {
    .general-description {
      color: $textColor;
    }

    border-color: $colorLiliac;

    .image-box {
      border-color: $colorLiliac;
    }
  }
}
