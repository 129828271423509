@import '../../styles/constants.scss';

.users {
  .wrapper {
    width: 100%;
  }

  &__active {
    color: $colorSuccess;
  }

  &__inactive {
    color: $red;
  }

  &__actions {
    width: 240px;
  }

  .table__actions {
    justify-content: space-between;
  }

  &__reactivate .modal__content {
    width: $modalWidth;
  }

  &__super-admin {
    position: absolute;
    bottom: 16px;
    left: 0;
    .checkbox__label {
      flex-direction: row-reverse;
    }

    .check {
      margin-right: 10px;
    }
  }

  &__permission-trigger {
    color: $colorButton;
    margin-left: auto;
    cursor: pointer;
    &:hover,
    &:focus {
      color: darken($colorButton, 10%);
    }

    &--disabled {
      color: $inputColor;
      @include disabled();
    }
  }
}

.assign-users {
  .form__section__header {
    width: 100%;
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
  }

  &__half {
    width: 47%;
    margin-right: 6%;

    &:last-child {
      margin-right: 0;
    }
  }

  &__global {
    padding: 13px 15px;
    border-radius: $borderRadius;
    background: $white;
  }
}

.resend-invite {
  justify-content: left;
  position: relative;
  width: 100%;
  img {
    margin-left: 16px;
    cursor: pointer;
    height: 7px;
    right: 25px;
    transform: rotate(-90deg);
    width: 11px;
  }
}

.modal-upload-image {
  .modal__content {
    width: 516px;
  }

  .modal__children {
    padding: 15px 24px;
  }
}

.modal-emulate-user {
  .modal__content {
    width: $modalWidth;

    .modal__heading {
      font-size: $fontSize21;
    }

    .modal__children {
      color: #4d4d4d;

      &--content-separator {
        padding: 0 30px;
      }
    }
  }
}

.user-management {
  .heading__buttons {
    .tooltip {
      padding: 5px 10px;
    }
  }
  .filters {
    .filters--container {
      overflow: unset;
    }
  }
}

.import-users-button {
  &--content {
    display: flex;
    align-items: center;
    color: $textColor;
    > svg {
      margin-right: 5px;
    }
  }
  max-width: 140px;
}

.user-restore-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  background-color: $white;
  border: 1px solid $red;
  border-radius: 5px;
  min-height: 100px;
  height: fit-content;
  margin-bottom: 10px;

  .line-divider {
    margin: 0;
  }

  &__content {
    color: $black;
  }

  &__actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
  }
}
