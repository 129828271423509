@import '../../styles/constants';

.tag-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  height: 28px;
  background: $textColor;
  border-radius: $borderRadiusTag;
  margin-right: 8px;
  margin-bottom: 10px;
  &--disabled {
    background: $colorDisabled;
  }
  &--error {
    background: $sectionBackground;
    border: 1px solid $colorRedVibrant;
  }
  &--light {
    background: none;
    border: 1px solid #bccbcc;
    .tag-close-icon path {
      fill: $colorButton;
    }
  }
  &--small {
    padding: 0;
  }
  .tag-label {
    margin: 5px 10px;
    font-weight: $fontBold;
    font-size: $fontSize15;
    font-family: $fontFamily;
    line-height: 120%;
    letter-spacing: $letterSpacing01;
    color: $sectionBackground;
    &--error {
      color: $textColor;
    }
    &--light {
      color: $textColor;
    }
  }
  .tag-close-icon {
    margin-right: 10px;
    cursor: pointer;
  }
}
